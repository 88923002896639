import Vue from 'vue'
import VueRouter from 'vue-router'
import Welcome from '@/views/Welcome/Welcome'
import Main from '@/views/Main/Main'
import Send from '@/views/Send/Send'
import Receive from '@/views/Receive/Receive'
import Buy from '@/views/Buy/Buy'
import Assets from '@/views/Assets/Assets'
import AddAsset from '@/views/AddAsset/AddAsset'
import CreateAsset from '@/views/CreateAsset/CreateAsset'
import CreateHardwareWalletConnector from '@/views/CreateHardwareWalletConnector/CreateHardwareWalletConnector'
import Store from '@/views/Store/Store'
import CreateROCT from '@/views/CreateROCT/CreateROCT'
import Marketplace from '@/views/Marketplace/Marketplace'
import WelcomeBox from '@/views/WelcomeBox/WelcomeBox'
import Swap from '@/views/Swap/Swap'
import History from '@/views/History/History'
import ShareBox from '../views/ShareBox/ShareBox'
import Ticket from '@/views/Ticket/Ticket'
import CreateTicket from '@/views/CreateTicket/CreateTicket'
import Claim from '@/views/Claim/Claim'
import StaticTicket from '@/views/StaticTicket/StaticTicket'
import CurrencyDeFi from '@/views/CurrencyDeFi/CurrencyDeFi'
import AssetsRocarbon from '@/views/AssetsRocarbon/AssetsRocarbon'
// import Gen from '@/views/Gen/Gen'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome
  },
  {
    path: '/welcome-box',
    name: 'WelcomeBox',
    component: WelcomeBox
  },
  {
    path: '/ticket/:hash',
    name: 'Ticket',
    component: Ticket
  },
  {
    path: '/claim/:hash',
    name: 'Claim',
    component: Claim
  },
  {
    path: '/static-ticket/:ticket',
    name: 'StaticTicket',
    component: StaticTicket
  },
  /*  {
    path: '/gen',
    name: 'Gen',
    component: Gen
  }, */
  {
    path: '/main',
    name: 'Main',
    component: Main
  },
  {
    path: '/send',
    name: 'Send',
    component: Send
  },
  {
    path: '/send/:currency',
    name: 'Send',
    component: Send,
    props: true
  },
  {
    path: '/currency-defi/:currency',
    name: 'CurrencyDeFI',
    component: CurrencyDeFi,
    props: true
  },
  {
    path: '/swap/:currency',
    name: 'Swap',
    component: Swap,
    props: true
  },
  {
    path: '/history/:queryAddress/:queryContract',
    name: 'History',
    component: History,
    props: true
  },
  {
    path: '/receive',
    name: 'Receive',
    component: Receive
  },
  {
    path: '/receive/:currency',
    name: 'Receive',
    component: Receive,
    props: true
  },
  {
    path: '/share-box/:hash/:address',
    name: 'ShareBox',
    component: ShareBox
  },
  {
    path: '/buy',
    name: 'Buy',
    component: Buy
  },
  {
    path: '/assets',
    name: 'Assets',
    component: Assets
  },
  {
    path: '/assets-rocarbon',
    name: 'AssetsRocarbon',
    component: AssetsRocarbon
  },
  {
    path: '/add-asset',
    name: 'AddAsset',
    component: AddAsset
  },
  {
    path: '/create-asset',
    name: 'CreateAsset',
    component: CreateAsset
  },
  {
    path: '/create-hardware-wallet-connector',
    name: 'CreateHardwareWalletConnector',
    component: CreateHardwareWalletConnector
  },
  {
    path: '/store',
    name: 'Store',
    component: Store
  },
  {
    path: '/create-rocarbon',
    name: 'CreateROCT',
    component: CreateROCT
  },
  {
    path: '/create-ticket',
    name: 'CreateTicket',
    component: CreateTicket
  },
  { path: '/marketplace/:id', redirect: '/sale/:id' },
  {
    path: '/sale/:id',
    name: 'Marketplace',
    component: Marketplace,
    meta: {
      offer: false,
      marketplace: true
    }
  },
  { path: '/marketplace/:id/offer/:offer', redirect: '/sale/:id/offer/:offer' },
  {
    path: '/sale/:id/offer/:offer',
    name: 'MarketplaceOffer',
    component: Marketplace,
    meta: {
      offer: true,
      marketplace: true
    }
  },
  { path: '/marketplace/:id/offer/:offer/complete', redirect: '/sale/:id/offer/:offer/complete' },
  {
    path: '/sale/:id/offer/:offer/complete',
    name: 'MarketplaceOfferComplete',
    component: Marketplace,
    meta: {
      offer: false,
      marketplace: true,
      complete: true
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
