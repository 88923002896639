<template>
  <div class="Buy">
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main && ready" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px" v-if="success">
            <v-row>
              <v-col align-self="center" align="left" class="py-0">
                <span class="title">BUY SUCCESS</span>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid class="fill-height" style="max-width: 700px" v-if="!success">
            <v-row>
              <v-col class="subtitle-1 text-uppercase font-weight-bold">
                Buy tokens
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select dense v-model="origin" :items="Object.values(currencies).filter(item => tokens.includes(item.type))" item-value="type" item-text="name"
                          label="Select"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="subtitle-1 text-uppercase font-weight-bold">
                Quick buy
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="left" class="py-0">
                <v-btn @click="buy(1)" class="my-1" block outlined dark rounded>
                  1 {{ getSymbol(origin) }}
                </v-btn>
                <v-btn @click="buy(10)" class="my-1" block outlined dark rounded>
                  10 {{ getSymbol(origin) }}
                </v-btn>
                <v-btn @click="buy(100)" class="my-1" block outlined dark rounded>
                  100 {{ getSymbol(origin) }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="center" cols="8">
                <!-- <v-text-field block rounded outlined dark hide-details solo placeholder="1000" prefix="$" :suffix="getSymbol(origin)" v-model="amount"></v-text-field> -->
                <v-text-field
                  :rules="[value => (Number(value) <= 1000001 && Number(value) >= 1) || '1 - 1,000,000']"
                  type="number" step="1" min="1" max="1000000"
                  placeholder="5" v-model="amount" label="Amount" prefix=""
                  :suffix="getSymbol(origin)"></v-text-field>
              </v-col>
              <v-col align-self="center" align="center" cols="4">
                <v-btn :disabled="Number(amount) >= 1000001 || Number(amount) <= 1" @click="buy(amount)" class="pa-6" block outlined dark rounded>Buy</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-5 overline text-uppercase">
                <div class="font-weight-bold">
                  <v-alert
                    shaped
                    color="#272727"
                    type="info"
                    dismissible
                  >1 {{ getSymbol(origin) }} Loyalty Token is equivalent to $1 {{ currencies[origin].convertType }} and can be redeemed for goods and services exclusively within our network of partners and services.</v-alert>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
  </div>
</template>

<script>
import './_Buy.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'
import { createInstantPayment } from 'id4good.auth'

export default {
  name: 'Buy',
  components: { Bar },
  data () {
    return {
      main: false,
      origin: 't4g',
      amount: 5,
      loading: false,
      success: false,
      callback: ''
    }
  },
  mixins: [currency],
  created () {
    if (this.$route.query.status === 'success') {
      this.success = true
    }
  },
  mounted () {
    this.callback = window.location.href
    setTimeout(() => {
      this.show()
    }, 300)
  },
  methods: {
    show () {
      this.main = true
    },
    async buy (amount) {
      const payment = await createInstantPayment(this.address, 'Wallet4Good', amount, this.callback, this.currencies[this.origin].country)
      window.location.href = 'https://pay4good.com/?hash=' + payment.hash
    }
  }
}
</script>
