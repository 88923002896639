import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = () => ({
  initialized: false,
  connected: false,
  error: null,
  // user is ens or address
  balance: 0,
  balanceEthereum: 0,
  fee: 0,
  wallet: '',
  walletEthereum: '',
  address: '',
  network: ''
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
