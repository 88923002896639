import { Connector } from '@/classes/Connector'

export class Ethers extends Connector {
  signer
  // eslint-disable-next-line no-useless-constructor
  constructor (signer) {
    super(signer)
    this.signer = signer
  }

  async getAddress () {
    this.signer.getAddress()
  }

  async signTransaction (unsignedTx) {
    return await this.signer.signTransaction(unsignedTx)
  }
}
