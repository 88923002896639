<template>
    <div class="StaticTicket">
      <v-container fluid class="fill-height">
        <v-row dense>
          <v-col align-self="start" :align="$device.mobile ? 'center' : 'left'" :class="!$device.mobile ? 'pl-15' : ''">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : $store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="250px"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center">
            <div v-if="!loading" class="black--text font-weight-bold overline">{{ $t('Scan QR with ID4Good') }}</div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center">
            <v-progress-circular color="black" indeterminate width="10" size="150" v-if="loading">{{ $t('Checking') }}...</v-progress-circular>
            <v-img v-if="!loading" :src="qr" contain width="250px" height="250px" class="qr"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="end" :align="$device.mobile ? 'center' : 'right'" :class="!$device.mobile ? 'pr-15' : ''">
            <v-img :src="require('@/assets/logobew.svg')" contain width="100px"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_StaticTicket.scss'
import { generateQR } from 'id4good.auth'
export default {
  name: 'StaticTicket',
  data () {
    return {
      qr: null,
      loading: false,
      fail: false,
      success: false,
      hash: false,
      linkMobile: false,
      form: false,
      ticket: false
    }
  },
  mounted () {
    console.log('generate...')
    this.hash = this.$route.params.hash
    this.ticket = this.$route.params.ticket
    this.loading = true
    this.load()
  },
  methods: {
    continueClaim () {
    },
    async load () {
      // this.qr = await generateQR(this.ticket)
      this.qr = await generateQR('asset:0:' + this.ticket)
      this.loading = false
    }
  }
}
</script>
