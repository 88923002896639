<template>
  <div class="Assets">
    <v-dialog v-model="qr" fullscreen>
      <v-card style="background-color: #000000ee">
        <v-card-text class="" style="height: 100vh">
          <v-container fluid class="my-auto fill-height" v-if="qrAsset">
            <v-row>
              <v-col align-self="start" align="center">
                <div class="white--text title" style="font-size: 2rem !important;">{{ qrAsset.name }}</div>
                  <!-- <div v-if="(qrAsset.kind < 3 || qrAsset.kind == 10) && qrAsset.country !== null" class="black--text font-weight-bold"
                        style="font-size: 2rem !important;">
                    {{ getFlag(qrAsset.country) }}
                  </div> -->
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind < 3 || qrAsset.kind == 10" >
                  Box4Good
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 3">
                  Unique
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 4">
                  Series
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 5">
                  Ticket
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 6">
                  Reward
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 7">
                  RoCarbon
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 8">
                  Wallet
                </div>
               <!-- <v-icon size="48"
                >{{ assetTypes[qrAsset.kind].icon }}
                </v-icon> -->
              </v-col>
              </v-row>
            <v-row>
              <v-col align-self="center" align="center">
                <v-img contain :src="qrCode" max-width="300" style="border-radius: 15px"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="end" align="center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              class="my-5"
                              v-bind="attrs"
                              v-on="on"
                              dark
                              outlined
                              dense
                              :value="frontAddress(qrAsset.address, qrAsset.token)"
                              readonly
                              hide-details
                              append-icon="mdi-content-copy"
                              @click:append="copyOwnerAsset(frontAddress(qrAsset.address, qrAsset.token))"
                            />
                  </template>
                  <span>{{ frontAddress(qrAsset.address, qrAsset.token) }}</span>
                </v-tooltip>
                <v-btn class="my-5" dark block outlined @click="qr = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main && !finish && ready" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row dense>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>Sync
                </v-progress-circular>
                <v-container fluid v-if="!loading && selectKind === null" class="pa-0">
                  <v-row>
                    <v-col v-for="(asset, index) in assetTypes" cols="6" v-bind:key="index">
                      <v-card
                        elevation="24"
                        outlined
                        shaped
                        color="transparent" @click="setKind(index.toString())">
                        <div><br></div>
                        <v-icon x-large>{{ asset.icon }}</v-icon>
                        <v-card-title><div align="center" style="width: 100%">{{ asset.name }}</div></v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container fluid v-if="!loading && selectKind" class="pa-0">
                  <v-row>
                    <v-col>
                      <v-btn block @click="setKind(null)">back</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>

                    <v-col>
                      <v-card
                        elevation="24"
                        outlined
                        shaped
                        color="transparent">
                        <div><br></div>
                        <v-icon x-large>{{ assetTypes[selectKind].icon }}</v-icon>
                        <v-card-title><div align="center" style="width: 100%">{{ assetTypes[selectKind].name }}</div></v-card-title>
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn disabled @click="showAssetFiles(asset)" class="mx-1"
                                   :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                   icon v-bind="attrs" v-on="on">
                              <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-cog-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Settings</span>
                        </v-tooltip>
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn disabled @click="showAssetFiles(asset)" class="mx-1"
                                   :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                   icon v-bind="attrs" v-on="on">
                              <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-minus-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove Items</span>
                        </v-tooltip>
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn disabled @click="showAssetFiles(asset)" class="mx-1"
                                   :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                   icon v-bind="attrs" v-on="on">
                              <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-history</v-icon>
                            </v-btn>
                          </template>
                          <span>Activities</span>
                        </v-tooltip>
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="assetTypes[selectKind].disabled" @click="changePath(assetTypes[selectKind].path)" class="mx-1"
                                   :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                   icon v-bind="attrs" v-on="on">
                              <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Add Items</span>
                        </v-tooltip>
                        <v-text-field class="my-1" v-model="search" label="Search" block outlined rounded dense @change="updateMaxPage" />
                        <v-progress-linear v-if="syncing" color="white" dark indeterminate height="15"
                                           class="overline rounded">Sync
                        </v-progress-linear>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row class="pl-1 pr-15"
                    v-for="(asset, index) in pending.filter(obj => search.length < 3 || obj.name.includes(search))"
                    v-bind:key="index">
                    <v-col align-self="center" align="center">
                      <v-icon :size="$vuetify.breakpoint.xsOnly && $vuetify.breakpoint.width >= 400 ? 24 : 48"
                              >mdi-progress-upload
                      </v-icon>
                    </v-col>
                    <v-col align-self="center" align="left">
                      <span class="subtitle-1 font-weight-bold text-no-wrap">
                        {{ asset.name }}
                      </span>
                      <div class="subtitle-2"></div>
                    </v-col>
                    <v-col align-self="center" :align="$vuetify.breakpoint.width < 400 ? 'center' : 'right'"
                           :cols="$vuetify.breakpoint.width < 400 ? 12 : 6" class="px-0 mt-0 pt-0 overline">
                      In Progress
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(asset, index) in (assets.filter(obj => search.length < 3 || obj.name.toLowerCase().includes(search.toLowerCase()) || obj.hash.toLowerCase().includes(search.toLowerCase()))).slice((page - 1) * 10, page * 10)"
                    v-bind:key="index" class="rounded-xl shadow-currency left-box my-3 mx-1 my-0" align="center" justify="center"
                  >
                   <v-col align-self="center" align="center" cols="12">
                     <div style="position: absolute" v-if="$vuetify.breakpoint.width >= 700">
                       <img class="shadow-currency" v-if="previewIcon(asset) && asset.kind != 8 && asset.kind >= 3 && asset.kind != 10" style="margin-left: 5px; border-radius: 5px;" :src="asset.previewImageUrl" :height="asset.metadata ? 90 : 80" />
                     </div>
                     <div style="position: absolute" v-if="$vuetify.breakpoint.width < 700">
                       <img class="shadow-currency" v-if="previewIcon(asset) && asset.kind != 8 && asset.kind >= 3 && asset.kind != 10" style="margin-left: 5px; border-radius: 5px;" :src="asset.previewImageUrl" :height="asset.metadata ? 22 : 20" />
                     </div>
                     <div>
                      <span class="subtitle-1 font-weight-bold text-no-wrap" v-if="asset.name ? asset.name.length < 32 : false">
                        {{ asset.name }} <v-icon @click="editAssetName(asset)" medium>mdi-square-edit-outline</v-icon>
                    </span>
                      <span class="subtitle-1 font-weight-bold text-no-wrap" v-if="asset.name ? asset.name.length >= 32 : false">
                        {{ asset.name.substring(0, 32) }}... <v-icon @click="editAssetName(asset)" medium>mdi-square-edit-outline</v-icon>
                    </span>
                     </div>
                     <div>
                       <v-tooltip bottom v-if="asset.kind == 8 && asset.metadata.hardwarewalletaddress != undefined">
                         <template v-slot:activator="{ on, attrs }">
                          <span class="subtitle-1 font-weight text-no-wrap"
                          v-bind="attrs"
                          v-on="on"
                          >
                            {{ shortAddress(asset.metadata.hardwarewalletaddress, asset.metadata.selecttoken) }}
                            <v-icon @click="copyOwnerAsset(frontAddress(asset.metadata.hardwarewalletaddress, asset.metadata.selecttoken))" medium>mdi-content-copy</v-icon>
                          </span>
                         </template>
                         <span>{{ frontAddress(asset.metadata.hardwarewalletaddress, asset.metadata.selecttoken) }}</span>
                       </v-tooltip>
                       <v-tooltip bottom v-if="asset.kind != 8">
                         <template v-slot:activator="{ on, attrs }">
                          <span class="subtitle-1 font-weight text-no-wrap"
                                v-bind="attrs"
                                v-on="on"
                          >
                            {{ shortAddress(asset.address, asset.token) }}
                            <v-icon @click="copyOwnerAsset(frontAddress(asset.address, asset.token))" medium>mdi-content-copy</v-icon>
                          </span>
                         </template>
                         <span>{{ frontAddress(asset.address, asset.token) }}</span>
                       </v-tooltip>
                     </div>
                     <span v-if="asset.kind == 8 && asset.metadata.hardwarewalletaddress != undefined" style="font-size: 1rem;" class="font-weight">
                       <div v-if="asset.quantity == 1">
                       Single
                       </div>
                       <div v-if="(asset.quantity == asset.assetBalance) && asset.quantity > 1">
                       {{ asset.assetBalance }} Copies
                       </div>
                       <div v-if="(asset.quantity > 1) && (asset.quantity != asset.assetBalance)">
                       {{ asset.assetBalance }} of {{ asset.quantity }}
                       </div>
                      </span>
                     <div style="height: 14px" v-if="asset.metadata && asset.kind != 8">
                       <span style="font-size: 0.8rem;">Creator: {{ asset.metadata.creator }}</span>
                     </div>
                      <!--
                      <div style="height: 12px">
                      <span style="font-size: 0.8rem;">Estimate value: ~ XXXX T4G-USD</span>
                      </div>
                      -->
                     <div>
                     <hr class="mt-4" style="opacity: 0.1"/>
                     </div>
                   </v-col>
                    <v-row dense class="my-0">
                      <v-col align-self="center" align="center">
                        <v-container fluid>
                          <v-row dense>
                            <v-col v-if="asset.kind != 8" align-self="center" align="center" :cols="asset.balance > 0 ? 4 : 12" class="my-0">
                              <v-icon size="48"
                                      @click="showQRAsset(asset)">{{ assetTypes[asset.kind].icon }}
                              </v-icon>
                              <div style="font-size: 1rem;" class="font-weight-bold" v-if="asset.kind < 3 || asset.kind == 10" >
                                Box4Good
                              </div>
                              <div style="font-size: 1rem;" class="font-weight-bold" v-if="asset.kind == 3">
                                Unique
                              </div>
                              <div style="font-size: 1rem;" class="font-weight-bold" v-if="asset.kind == 4">
                                Series
                              </div>
                              <div style="font-size: 1rem;" class="font-weight-bold" v-if="asset.kind == 5">
                                Ticket
                              </div>
                              <div style="font-size: 1rem;" class="font-weight-bold" v-if="asset.kind == 6">
                                Reward
                              </div>
                              <div style="font-size: 1rem;" class="font-weight" v-if="asset.quantity == 1">
                                Single
                              </div>
                              <div style="font-size: 1rem;" class="font-weight" v-if="(asset.quantity == asset.assetBalance) && asset.quantity > 1">
                                {{ asset.assetBalance }} Copies
                              </div>
                              <div style="font-size: 1rem;" class="font-weight" v-if="(asset.quantity > 1) && (asset.quantity != asset.assetBalance)">
                                {{ asset.assetBalance }} of {{ asset.quantity }}
                              </div>
                            </v-col>
                            <v-col v-if="asset.kind == 8" align-self="center" align="center" :cols="asset.balance > 0 ? 4 : 12" class="my-0">
                              <v-img v-if="currencies[getType(asset.metadata.selecttoken)] != undefined" @click="showQRAsset(asset)" :contain="true" width="48"
                                     :src="require('../../assets/' + currencies[getType(asset.metadata.selecttoken)].icon)" align="left"></v-img>
                              <span v-if="$vuetify.breakpoint.width < 450" style="font-size: 0.65rem; font-weight: bold">{{ currencies[getType(asset.metadata.selecttoken)].name }}</span>
                              <span v-if="$vuetify.breakpoint.width >= 450 && $vuetify.breakpoint.width < 500" style="font-size: 0.8rem; font-weight: bold">{{ currencies[getType(asset.metadata.selecttoken)].name }}</span>
                              <span v-if="$vuetify.breakpoint.width >= 500 && $vuetify.breakpoint.width < 600" style="font-size: 0.9rem; font-weight: bold">{{ currencies[getType(asset.metadata.selecttoken)].name }}</span>
                              <span v-if="$vuetify.breakpoint.width >= 600" style="font-size: 1rem; font-weight: bold">{{ currencies[getType(asset.metadata.selecttoken)].name }}</span>
                              <div>
                              <span v-if="currencies[getType(asset.metadata.selecttoken)].type === 'roct'" style="font-size: 0.9rem;">
                                      1 {{ currencies[getType(asset.metadata.selecttoken)].symbol }} = {{
                                  (currencies[getType(asset.metadata.selecttoken)].convert) | numFormat('0,0.[00]')
                                }} {{ currencies[getType(asset.metadata.selecttoken)].convertType }}</span>
                              <span v-if="currencies[getType(asset.metadata.selecttoken)].type !== 'roct' && currencies[getType(asset.metadata.selecttoken)].convert >= 0.01 && currencies[getType(asset.metadata.selecttoken)].convert < 100" style="font-size: 0.9rem;">
                                      1 {{ currencies[getType(asset.metadata.selecttoken)].symbol }} = ${{
                                  (currencies[getType(asset.metadata.selecttoken)].convert) | numFormat('0,0.[00]')
                                }}</span>
                              <span v-if="currencies[getType(asset.metadata.selecttoken)].type !== 'roct' && currencies[getType(asset.metadata.selecttoken)].convert < 0.01" style="font-size: 0.9rem;">
                                      $1 = {{
                                  (1/currencies[getType(asset.metadata.selecttoken)].convert) | numFormat('0,0')
                                }} {{ currencies[getType(asset.metadata.selecttoken)].symbol }}</span>
                              <span v-if="currencies[getType(asset.metadata.selecttoken)].type !== 'roct' && currencies[getType(asset.metadata.selecttoken)].convert >= 100" style="font-size: 0.9rem;">
                                      1 {{ currencies[getType(asset.metadata.selecttoken)].symbol }} = ${{
                                  (currencies[getType(asset.metadata.selecttoken)].convert) | numFormat('0,0')
                                }}</span>
                              </div>
                            </v-col>
                            <v-col v-if="asset.balance > 0 && asset.kind != 8" align-self="center" align="center" cols="4" class="my-0">
                            <span style="font-size: 1.5rem;" class="font-weight-bold" v-if="asset.kind < 3 || asset.kind == 8 || asset.kind == 10" >
                            <div>
                            {{
                                asset.balance | numFormat('0,0.[00]')
                              }} {{ getSymbol(asset.token) }}
                            </div>
                            </span>
                              <span style="font-size: 1.5rem;" class="font-weight-bold" v-if="asset.kind == 3">
                            {{
                                  asset.balance | numFormat('0,0.[00]')
                                }} {{ getSymbol(asset.token) }}
                            </span>
                              <span style="font-size: 1.5rem;" class="font-weight-bold" v-if="asset.kind == 4">
                       <div>
                         {{
                           asset.balance | numFormat('0,0.[00]')
                         }} {{ getSymbol(asset.token) }}
                       </div>
                      </span>
                              <span style="font-size: 1.5rem;" class="font-weight-bold" v-if="asset.kind == 5">
                       <div>
                         {{
                           asset.balance | numFormat('0,0.[00]')
                         }} {{ getSymbol(asset.token) }}
                       </div>
                      </span>
                              <span style="font-size: 1.5rem;" class="font-weight-bold" v-if="asset.kind == 6">
                       <div>
                         {{
                           asset.balance | numFormat('0,0.[00]')
                         }} {{ getSymbol(asset.token) }}
                       </div>
                      </span>
                              <span style="font-size: 1.5rem;" class="font-weight-bold" v-if="asset.kind == 7">
                       <div>
                         {{
                           asset.balance | numFormat('0,0')
                         }} {{ getSymbol(asset.token) }}
                       </div>
                                </span>
                            </v-col>
                            <v-col v-if="asset.balance > 0 && asset.kind == 8" align-self="center" align="center" cols="4" class="my-0">
                              <span style="font-size: 1.5rem;" class="font-weight-bold">
                       <div>
                              <span v-if="$vuetify.breakpoint.width < 400" style="font-size: 1.8rem;">${{
                              (parseFloat(asset.balance).toFixed(7) * currencies[getType(asset.metadata.selecttoken)].convert) | numFormat('0,0.[00]')
                                }}
                              </span>
                              <span v-if="$vuetify.breakpoint.width >= 400" style="font-size: 2.2rem;">${{
                              (parseFloat(asset.balance).toFixed(7) * currencies[getType(asset.metadata.selecttoken)].convert) | numFormat('0,0.[00]')
                                }}
                              </span>
                       </div>
                                <div></div>
                  <div class="subtitle-2">
                    <span v-if="$vuetify.breakpoint.width >= 400 && asset.balance >= 1" style="font-size: 1rem;"> {{
                        parseFloat(asset.balance).toFixed(7) | numFormat('0,0.[00]')
                      }} <span style="font-size: 0.9rem">{{ currencies[getType(asset.metadata.selecttoken)].symbol }}</span>
                          </span>
                    <span v-if="$vuetify.breakpoint.width < 400 && asset.balance >= 1" style="font-size: 0.8rem;"> {{
                        parseFloat(asset.balance).toFixed(7) | numFormat('0,0.[00]')
                      }} <span style="font-size: 0.8rem">{{ currencies[getType(asset.metadata.selecttoken)].symbol }}</span>
                          </span>
                    <span v-if="$vuetify.breakpoint.width >= 400 && asset.balance < 1" style="font-size: 1rem;"> {{
                        parseFloat(asset.balance).toFixed(7) | numFormat('0,0.[0000000]')
                      }} <span style="font-size: 0.9rem">{{ currencies[getType(asset.metadata.selecttoken)].symbol }}</span>
                          </span>
                    <span v-if="$vuetify.breakpoint.width < 400 && asset.balance < 1" style="font-size: 0.8rem;"> {{
                        parseFloat(asset.balance).toFixed(7) | numFormat('0,0.[0000000]')
                      }} <span style="font-size: 0.8rem">{{ currencies[getType(asset.metadata.selecttoken)].symbol }}</span>
                          </span>
                  </div>
                      </span>
                            </v-col>
                          </v-row>
                          <v-row dense>
                            <v-col dense align-self="center" align="center" class="my-0">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn  @click="sendToken(asset)" class="mx-1"
                                          :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                          icon v-bind="attrs" v-on="on">
                                    <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-arrow-down-bold-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Withdraw</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="receiveToken(asset)" class="mx-1"
                                         :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                         icon v-bind="attrs" v-on="on">
                                    <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-arrow-up-bold-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Add</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="transferAsset(asset)" class="mx-1"
                                         :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                         icon v-bind="attrs" v-on="on" :disabled="asset.sellStatus || asset.publish">
                                    <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-arrow-right-bold-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Transfer Asset</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="asset.kind >= 3 && asset.kind < 10">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="showAsset(asset)" class="mx-1"
                                         :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                         icon v-bind="attrs" v-on="on">
                                    <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-card-account-details-star-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Details</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="asset.kind >= 3 && asset.kind < 10 && asset.kind != 5 && asset.kind != 8">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="showAssetFiles(asset)" class="mx-1"
                                         :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                         icon v-bind="attrs" v-on="on">
                                    <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-file-settings-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Files</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="asset.kind != 8">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="sellAsset(asset)" class="mx-1"
                                         :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                         icon v-bind="attrs"
                                         v-on="on" :disabled="asset.sellStatus || asset.publish" :color="asset.sellStatus ? 'red' : ''">
                                    <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-currency-usd</v-icon>
                                  </v-btn>
                                </template>
                                <span>DeFi</span>
                              </v-tooltip>
                              <v-tooltip bottom v-if="asset.kind < 3">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn @click="settingAsset(asset)" class="mx-1"
                                         :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                         icon v-bind="attrs"
                                         v-on="on">
                                    <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-cog-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Settings</span>
                              </v-tooltip>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn :to="'/history/' + encodeAddress(asset.address) + '/all'" class="mx-1"
                                         :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                         icon v-bind="attrs"
                                         v-on="on">
                                    <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-history</v-icon>
                                  </v-btn>
                                </template>
                                <span>History</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-pagination
                        v-model="page"
                        :length="maxPage"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn block @click="setKind(null)">back</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="my-5"></v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="finish && !sellFinish" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>
                  Sync
                </v-progress-circular>
                <v-container fluid v-if="!loading">
                  <v-row class="title font-weight-bold white--text">
                    <v-col>{{ transactionResult.confirmations > 0 ? 'SUCCESS' : 'FAIL' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-text-field label="Transaction"
                                  :value="transaction.hash"
                                  readonly
                                  hide-details
                                  append-icon="mdi-content-copy"
                                  @click:append="copyOwnerAsset(transaction.hash)"
                    />
                  </v-row>
                  <v-row>
                    <v-text-field label="Block"
                                  :value="transactionResult.blockHash"
                                  readonly
                                  hide-details
                                  append-icon="mdi-content-copy"
                                  @click:append="copyOwnerAsset(transactionResult.blockHash)"
                    />
                  </v-row>
                  <v-row>
                    <v-text-field label="From"
                                  v-if="asset.kind != 8"
                                  :value="frontAddress(address, asset.token)"
                                  readonly
                                  hide-details
                                  append-icon="mdi-content-copy"
                                  @click:append="copyOwnerAsset(frontAddress(address, asset.token))"
                    />
                    <v-text-field label="From"
                                  v-if="asset.kind == 8"
                                  :value="frontAddress(address, asset.metadata.selecttoken)"
                                  readonly
                                  hide-details
                                  append-icon="mdi-content-copy"
                                  @click:append="copyOwnerAsset(frontAddress(address, asset.metadata.selecttoken))"
                    />
                  </v-row>
                  <v-row>
                    <v-text-field label="To"
                                  v-if="asset.kind != 8"
                                  :value="frontAddress(destination, asset.token)"
                                  readonly
                                  hide-details
                                  append-icon="mdi-content-copy"
                                  @click:append="copyOwnerAsset(frontAddress(destination, asset.token))"
                    />
                    <v-text-field label="To"
                                  v-if="asset.kind == 8"
                                  :value="frontAddress(destination, asset.metadata.selecttoken)"
                                  readonly
                                  hide-details
                                  append-icon="mdi-content-copy"
                                  @click:append="copyOwnerAsset(frontAddress(destination, asset.metadata.selecttoken))"
                    />
                  </v-row>
                  <v-row>
                    <v-text-field label="Asset Name"
                                  :value="asset.name"
                                  readonly
                                  hide-details
                                  append-icon="mdi-content-copy"
                                  @click:append="copyOwnerAsset(asset.name)"
                    />
                  </v-row>
                    <v-row>
                    <v-text-field label="Asset"
                                  :value="asset.hash"
                                  readonly
                                  hide-details
                                  append-icon="mdi-content-copy"
                                  @click:append="copyOwnerAsset(asset.hash)"
                    />
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Tx Fee:</v-col>
                    <v-col align="left">{{ (Number(amount)).toFixed(8) }} {{ getSymbol('t4g') }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" @click="back" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="sellFinish" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>
                  Sync
                </v-progress-circular>
                <v-container fluid v-if="!loading">
                  <v-col class="my-3 overline text-uppercase">
                    <div class="font-weight-bold">Share the link, so you can start receiving offers</div>
                  </v-col>
                  <v-row>
                    <v-col align-self="center" align="center" class="">
                      <!-- <v-img v-show="false" :src="image" contain width="250" height="250" class="qrcode"/> -->
                      <v-btn outlined dark block class="my-5" v-if="shareSupport" @click="share">Share Link</v-btn>
                      <v-text-field v-model="sell.url" readonly append-icon="mdi-content-copy"
                                    @click:append="copySell"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" @click="back" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
    <v-dialog dark max-width="100%" v-model="editDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-text-field style="max-width: 700px" v-model="asset.name" label="name"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="saveAsset">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="transferDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field style="max-width: 700px" v-model="destination" label="To" append-icon="mdi-content-paste"
                              @click:append="paste"></v-text-field>
                <v-text-field style="max-width: 700px" v-model="quantity" label="quantity" type="number" step="1" min="1" :max="asset.balance" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold title">Fee</div>
                <div class="subtitle-1">
                  {{ currentFee }}
                  A4G
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="transferAssetComplete">Confirm Transfer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="receiveDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  :rules="[value => Number(value) <= getBalance(asset.token) || 'Insufficient Balance']"
                  placeholder="0"
                  type="number"
                  min="0.0000001"
                  max="9999999"
                  v-model="amount"
                  label="Amount"
                  :suffix="getSymbol(asset.token)"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold title">Main Wallet Balance {{ getSymbol(asset.token) }}</div>
                <div class="subtitle-1">
                  {{ parseFloat(getBalance(asset.token)).toFixed(7) | numFormat('0,0.00[0000]') }}
                  {{ getSymbol(asset.token) }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="right" class="py-0">
                <div class="font-weight">Summary</div>
                <div><span
                  class="font-weight">Amount: </span>{{ parseFloat(amount).toFixed(7) | numFormat('0,0.[0000000]') }}
                  {{ asset.token ? getSymbol(asset.token) : '' }} | {{ parseFloat(amount).toFixed(7) * getConvert(asset.token) | numFormat('0,0.[0000000]') }}
                  T4G-USD
                </div>
                <div><span class="font-weight">Estimated network fee: </span> {{ parseFloat(this.currentFee).toFixed(7) | numFormat('0,0.[0000000]') }}
                  {{
                    ['a4g', 't4g', 't4gcad', 't4gclp', 't4gbrl'].includes(asset.token) ? getSymbol('a4g') : ['eth'].includes(asset.token) ? getSymbol('eth') : ['btc'].includes(asset.token) ? getSymbol('btc') : ''
                  }}  | {{ parseFloat(this.currentFee).toFixed(7) * getConvert(asset.token) | numFormat('0,0.[0000000]') }}
                  T4G-USD
                </div>

                <div class="font-weight-bold"><span
                >Estimated amount after fee: </span>{{ Math.abs(parseFloat(Number(amount) - Number(this.currentFee)).toFixed(7)) | numFormat('0,0.[000000000]') }}
                  {{ asset.token ? getSymbol(asset.token) : '' }} | {{ Math.abs(parseFloat(Number(amount) - Number(this.currentFee)).toFixed(7)) * getConvert(asset.token) | numFormat('0,0.[0000000]') }}
                  T4G-USD
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="parseFloat(getBalance(asset.token)) < Number(amount) || Number(amount) <= 0" outlined @click="receiveTokenComplete">Confirm Adding</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="sendDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field :rules="[value => Number(value) <= asset.balance || 'Insufficient Balance']" placeholder="0"
                              v-model="amount" label="Amount"
                              :suffix="getSymbol(asset.token)"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold title">Balance</div>
                <div class="subtitle-1">
                  {{ parseFloat(asset.balance).toFixed(7) | numFormat('0,0.00[0000]') }}
                  {{ getSymbol(asset.token) }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold subtitle-1">Fee</div>
                <div class="subtitle-2">
                  {{ currentFee }}
                  A4G
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="sendTokenComplete">Confirm Withdraw</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="sellDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-tabs
          dark
          v-model="sellTab"
        >
          <v-tab href="#stab0">
            Sell
          </v-tab>
          <v-tab href="#stab1">
            Dept of Good
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="sellTab" dark>
          <v-tab-item value="stab0">
            <v-card-text align="center">
              <v-container>
                <v-row>
                  <v-col>
                    <v-select v-model="sellType" :items="['Direct']" label="Sell Type" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card v-if="sellType === 'Direct'">
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field :rules="[value => Number(value) > 0]" placeholder="0" v-model="price"
                                          label="Price"
                                          :suffix="getSymbol('t4g')"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="asset.kind == 4">
                          <v-col>
                            <v-text-field type="number" :rules="[value => Number(value) > 0 && value <= asset.assetBalance]"
                                          placeholder="0" v-model="amount" label="Quantity"
                                          suffix="Asset"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field :rules="[value => Number(value) >= 1]" v-model="sellDuration" :min="1" :max="24 * 30"
                                          type="number" label="Duration" suffix="Hours"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-textarea rows="3" v-model="sellDescription" label="Additional Description"></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="font-weight-bold subtitle-1">Fee</div>
                            <div class="subtitle-2">
                              {{ currentFee }}
                              A4G + 10% over sell price
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-card v-if="sellType === 'Bid'">
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field :rules="[value => Number(value) > 0]" placeholder="0" v-model="price"
                                          label="Start Price"
                                          :suffix="getSymbol('t4g')"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="asset.kind == 4">
                          <v-col>
                            <v-text-field type="number" :rules="[value => Number(value) > 0 && value <= asset.assetBalance]"
                                          placeholder="0" v-model="amount" label="Quantity"
                                          suffix="Asset"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field :rules="[value => Number(value) >= 1]" v-model="sellDuration" :min="1" :max="24 * 30"
                                          type="number" label="Duration" suffix="Hours"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-textarea rows="3" v-model="sellDescription" label="Additional Description"></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="font-weight-bold subtitle-1">Fee</div>
                            <div class="subtitle-2">
                              {{ currentFee }}
                              A4G + 10% over sell price
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="stab1">
            <v-card-text align="center" class="overline">
              The publication of the asset in DeptOfGood allows you to access a larger showcase for your content, you must bear in mind that while it is in DeptOfGood you will not be able to sell directly or transfer your asset.
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="sellTab === 'stab0'" outlined @click="sellAssetComplete"
                 :disabled="!(Number(amount) > 0 && amount <= asset.assetBalance && price > 0)">Sell
          </v-btn>
          <v-btn v-if="sellTab === 'stab1'" outlined @click="publishAssetComplete">Publish</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="settingDialog" v-if="settingDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-tabs
          dark
          v-model="settingTab"
          @change="settingAsset(asset)"
          :vertical="$vuetify.breakpoint.width < 600"
        >
          <v-tab href="#stab0">
            <v-icon small dark>mdi-credit-card-outline</v-icon><span class="ml-1" v-if="$vuetify.breakpoint.width > 960">Payment</span>
          </v-tab>
          <v-tab href="#stab2">
            <v-icon small dark>mdi-piggy-bank-outline</v-icon><span class="ml-1" v-if="$vuetify.breakpoint.width > 960">Tips</span>
          </v-tab>
          <v-tab href="#stab1">
            <v-icon small dark>mdi-monitor</v-icon><span class="ml-1" v-if="$vuetify.breakpoint.width > 960">Display</span>
          </v-tab>
          <v-tab @click="$router.push({name: 'ShareBox', params: { hash: asset.hash, address: asset.address }})">
            <v-icon small dark>mdi-share-outline</v-icon><span class="ml-1" v-if="$vuetify.breakpoint.width > 960">Share</span>
          </v-tab>
          <v-spacer></v-spacer>
          <v-btn :to="'/history/' + encodeAddress(asset.address) + '/all'" class="mx-2">
            <v-icon small dark v-if="$vuetify.breakpoint.width <= 860">mdi-history</v-icon><span v-if="$vuetify.breakpoint.width > 860">Transactions History</span>
          </v-btn>
          <v-btn :disabled="!settingOnline" color="green" @click="connectToBox(asset)">
            <v-icon small dark v-if="$vuetify.breakpoint.width <= 860">mdi-connection</v-icon><span v-if="$vuetify.breakpoint.width > 860">Connect to Box4Good</span>
          </v-btn>
        <v-tabs-items v-model="settingTab" dark>
          <v-tab-item value="stab0">
            <v-card-text align="center">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field label="Site" v-model="asset.site"></v-text-field>
                    <v-select :items="countries" v-model="asset.country" item-value="code" item-text="name"
                              label="Country"></v-select>
                    <v-text-field v-if="asset.kind == 5" label="Ticket URL" v-model="asset.ticketURL"></v-text-field>
                    <div class="overline">
                      Changing the country implies a change in the currency used by the Box4Good.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="stab2">
            <v-card-text align="center">
              <v-container>
                <v-row>
                  <v-col>
                    <div class="overline" v-if="!asset.tips">
                      There is no associated Tips Asset, one must be minted.
                    </div>
                    <v-text-field label="Hash" v-model="findTipsAsset(asset.tips).hash" readonly></v-text-field>
                    <v-text-field label="Address" v-model="asset.tips" readonly></v-text-field>
                    <v-btn v-if="!asset.tips" @click="mintTipsAsset(asset)" dark class="mt-2" color="green">Mint Tips Asset</v-btn>
                    <v-btn v-if="asset.tips" @click="mintTipsAsset(asset)" dark class="mt-2" color="green">Mint New Tips Asset</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="stab1">
            <v-card-text align="center">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field :rules="[value => Number(value) >= 60]" type="number" min="60" placeholder="60"
                                  v-model="settingInterval" label="Interval"
                                  suffix="Seconds"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-for="(asset, index) in supportAssets.filter(item => item.files ? item.files.length > 0 : false)" v-bind:key="index">
                  <v-col align-self="center" align="center">
                    <v-img :src="asset.preview" class="rounded-xl" max-width="150" max-height="150" height="150"
                           width="150" contain/>
                    <div align="center" class="font-weight-bold">{{ asset.name }}
                      (...{{ asset.hash.substring(asset.hash.length - 10) }})
                    </div>
                  </v-col>
                  <v-col align-self="center" align="left">
                <span>
                  <v-checkbox dense :label="file.tag" v-model="file.selected" v-for="(file, idx) in asset.files"
                              v-bind:key="idx"></v-checkbox>
                </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        </v-tabs>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="settingTab === 'stab1'" outlined @click="settingAssetClearComplete">Clear</v-btn>
          <v-btn outlined @click="settingAssetComplete">Save</v-btn>
          <v-btn outlined @click="settingDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="assetDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col align-self="center" align="center">
                <v-img v-if="asset.kind != 8" :src="asset.preview" class="rounded-xl" contain max-width="250" max-height="250" height="250"
                       width="250"/>
                <v-img v-if="asset.kind == 8" :src="qrCode" class="rounded-xl" contain max-width="250" max-height="250" height="250"
                       width="250"/>
                <div align="center" v-if="asset.kind == 3">
                  DAF
                </div>
                <div align="center" v-if="asset.kind == 4">
                  {{ asset.assetBalance }} of {{ asset.quantity }} Series
                </div>
              </v-col>
              <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
                <v-text-field label="Name" v-model="asset.name" readonly></v-text-field>
                <v-text-field v-if="asset.kind == 8 && asset.metadata.hardwarewalletaddress != undefined" label="Hardware Wallet" :value="asset.metadata.hardwarewalletaddress" readonly hide-details
                              append-icon="mdi-content-copy" @click:append="copyOwnerAsset(asset.metadata.hardwarewalletaddress)"/>
                <v-text-field v-if="asset.kind != 8" label="Token" :value="getSymbol(asset.token)" readonly></v-text-field>
                <v-text-field v-if="asset.kind == 8 && asset.metadata.selecttoken != undefined" label="Token" :value="asset.metadata.selecttoken" readonly></v-text-field>
                <v-text-field label="Balance" :value="asset.assetBalance" readonly></v-text-field>
                <v-text-field label="Total Quantity" :value="asset.quantity" readonly></v-text-field>
                <v-text-field v-if="asset.kind != 8" label="Creator" v-model="asset.creator" readonly></v-text-field>
                <v-text-field v-if="asset.kind != 5 && asset.kind != 6 && asset.kind != 8" label="Royalty" v-model="asset.royalty" suffix="%" readonly></v-text-field>
                <v-text-field label="Original Owner ID4Good" :value="asset.originalOwnerEncoded" readonly hide-details
                              append-icon="mdi-content-copy" @click:append="copyOwnerAsset(asset.originalOwnerEncoded)"/>
                <v-text-field v-if="asset.kind == 5" label="Ticket Validator" :value="'https://' + hostname + '/#/ticket/' + asset.verificator" readonly></v-text-field>
                <v-textarea v-if="asset.kind != 8" label="Description" v-model="asset.description" rows="3" readonly></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="assetDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="assetViewDialog" transition="dialog-bottom-transition" fullscreen>
      <v-btn x-small @click="assetViewDialog = false" fixed dark outlined fab bottom right class="ma-2">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card>
        <v-container fluid class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <v-img v-if="assetViewDialog" :src="assetViewUrl" contain height="100%" max-height="100%" width="auto"
                     max-width="auto"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="assetFilesDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col align-self="center" align="center">
                <v-img :src="asset.preview" class="rounded-xl" contain max-width="250" max-height="250" height="250"
                       width="250"/>
                <div align="center" v-if="asset.kind == 3">
                  DAF
                </div>
                <div align="center" v-if="asset.kind == 4">
                  {{ asset.assetBalance }} of {{ asset.quantity }} Series
                </div>
              </v-col>
              <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
                <v-container>
                  <v-row v-for="(file, index) in asset.files" v-bind:key="index">
                    <v-col align-self="center" align="center">
                      <v-text-field hide-details label="File Name" v-model="file.name" readonly></v-text-field>
                    </v-col>
                    <v-col align-self="center" align="center">
                      <v-text-field hide-details label="Tag" v-model="file.tag" readonly></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" align-self="center" align="center">
                      <v-btn class="mr-5" v-if="file.type.includes('image')" outlined @click="viewAssetFile(file)">
                        View
                      </v-btn>
                      <v-btn outlined @click="downloadAssetFile(file)">Download</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="assetFilesDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import './_Assets.scss'
import currency from '@/mixins/currency'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import Bar from '@/components/Bar/Bar'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import QRCodeStyling from 'qr-code-styling'
import TransportWebUSB from '@ledgerhq/hw-transport-webusb'
import AppBtc from '@ledgerhq/hw-app-btc'
import AppEth from '@ledgerhq/hw-app-eth'
import { Ethers } from '@/classes/Ethers'
import { Ledger } from '@/classes/Ledger'
// import { create } from 'ipfs-http-client'

const {
  utils,
  Wallet,
  Contract
} = require('ethers')

const abi = [
  // Read-Only Functions
  'function balanceOf(address owner) view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function symbol() view returns (string)',

  // Authenticated Functions
  'function transfer(address to, uint amount) returns (boolean)',

  // Events
  'event Transfer(address indexed from, address indexed to, uint amount)'
]

export default {
  name: 'Assets',
  components: { Bar },
  data () {
    return {
      qr: false,
      qrCurrency: false,
      qrAsset: false,
      qrCode: null,
      path: '',
      selectKind: null,
      assetTypes: {
        1: {
          name: 'Box4Good',
          icon: 'mdi-checkbox-intermediate',
          disabled: true
        },
        2: {
          name: 'Wearable',
          icon: 'mdi-nfc-variant',
          disabled: true
        },
        3: {
          name: 'Unique(NFT)',
          icon: 'mdi-cube-outline',
          path: '/create-asset',
          disabled: false
        },
        4: {
          name: 'Series(SFT)',
          icon: 'mdi-view-grid-plus-outline',
          path: '/create-asset',
          disabled: false
        },
        5: {
          name: 'Ticket',
          icon: 'mdi-ticket-confirmation-outline',
          path: '/create-ticket',
          disabled: false
        },
        6: {
          name: 'Rewards',
          icon: 'mdi-ticket',
          disabled: true
        },
        7: {
          name: 'RoCarbon',
          icon: 'mdi-molecule-co2',
          disabled: true
        },
        8: {
          name: 'Wallets',
          icon: 'mdi-safe',
          path: '/create-hardware-wallet-connector',
          disabled: false
        },
        10: {
          name: 'Tips',
          icon: 'mdi-piggy-bank-outline',
          disabled: true
        }
      },
      countries: [
        {
          code: 'US',
          name: 'United States'
        },
        {
          code: 'CA',
          name: 'Canada'
        },
        {
          code: 'BR',
          name: 'Brazil'
        },
        {
          code: 'SV',
          name: 'El Salvador'
        },
        {
          code: 'CL',
          name: 'Chile'
        }
      ],
      createItems: [
        {
          path: '/create-asset',
          name: 'DAF (Asset/NFT)'
        },
        {
          path: '/create-hardware-wallet-connector',
          name: 'Hardware Wallet',
          disabled: false
        },
        {
          path: '/create-app-wallet',
          name: 'App Wallet',
          disabled: true
        },
        {
          path: '/create-ticket',
          name: 'DTicket',
          disabled: false
        },
        {
          path: '/create-address-book',
          name: 'Address Book',
          disabled: true
        },
        {
          path: '/create-name',
          name: 'DName',
          disabled: true
        }
      ],
      page: 1,
      maxPage: 1,
      hostname: window.location.hostname,
      finish: false,
      sellFinish: false,
      destination: '',
      transport: null,
      main: false,
      loading: false,
      loadingDialog: false,
      snackbar: false,
      pending: [],
      asset: {
        name: '',
        hash: ''
      },
      editDialog: false,
      transferDialog: false,
      receiveDialog: false,
      sellDialog: false,
      settingDialog: false,
      settingOnline: false,
      settingInterval: 60,
      settingDisplay: {},
      settingTab: 'stab0',
      sendDialog: false,
      assetDialog: false,
      assetViewUrl: null,
      assetViewDialog: false,
      sell: null,
      image: null,
      assetFilesDialog: false,
      sellType: 'Direct',
      sellTab: 'stab0',
      sellDuration: 1,
      sellDescription: '',
      transaction: { hash: '' },
      transactionResult: { blockHash: '' },
      text: 'Paste Success',
      assets: [],
      supportAssets: [],
      amount: 1,
      quantity: 1,
      price: 0,
      search: '',
      dev: false,
      syncing: false,
      count: 0
    }
  },
  watch: {
    search: {
      deep: true,
      handler: function () {
        this.updateMaxPage()
      }
    },
    amount: {
      deep: true,
      handler: function () {
        this.estimateFee(this.asset.token)
      }
    },
    destination: {
      deep: true,
      handler: function () {
        this.estimateFee(this.asset.token)
      }
    }
  },
  mixins: [currency],
  async mounted () {
    this.loading = true
    setTimeout(() => {
      this.show()
    }, 300)
    try {
      this.assets = await this.cacheGet('assets')
      this.maxPage = Math.ceil(this.assets.length / 10)
    } catch (e) {
      this.assets = []
      this.maxPage = 1
    }
    this.getAssets(this.walletAssets, this.address)
    this.loading = false
  },
  methods: {
    updateMaxPage () {
      const assets = (this.assets.filter(obj => this.search.length < 3 || obj.name.toLowerCase().includes(this.search.toLowerCase()) || (obj.hash.toLowerCase().includes(this.search.toLowerCase()))))
      this.maxPage = Math.ceil(assets.length / 10)
      this.page = 1
    },
    show () {
      this.main = true
    },
    changePath (items) {
      this.$router.push({ path: items })
    },
    getFlag (country) {
      return getUnicodeFlagIcon(country)
    },
    showQR (currency) {
      this.qrCurrency = currency
      this.generateQRAsset(this.frontAddress(currency.address), currency.token)
      this.qr = true
    },
    showQRAsset (asset) {
      this.qrAsset = asset
      if (asset.kind === '8') {
        this.qrAsset.token = asset.metadata.selecttoken
        this.qrAsset.address = this.frontAddress(asset.metadata.hardwarewalletaddress, asset.metadata.selecttoken)
      } else {
        this.qrAsset = asset
        this.qrAsset.address = this.frontAddress(asset.address, asset.token)
        /* console.log('Else:' + asset.kind)
        console.log(this.qrAsset.address)
        console.log(asset.metadata.hardwarewalletaddress)
        console.log(asset.metadata.selecttoken)
        console.log(this.qr)
        console.log(this.qrAsset.address) */
      }
      this.generateQRAsset(this.qrAsset.address)
      this.qr = true
      /*
      console.log(asset.kind)
      console.log(this.qrAsset.address)
      console.log(asset.metadata.hardwarewalletaddress)
      console.log('token:' + asset.metadata.selecttoken)
      console.log(this.qr)
      console.log(this.qrAsset.token) */
    },
    showAddress (asset) {
      if (asset.kind === '8') {
        this.text = this.frontAddress(asset.metadata.hardwarewalletaddress, asset.metadata.selecttoken)
      } else {
        this.text = this.frontAddress(asset.address, asset.token)
      }
      navigator.clipboard.writeText(this.text)
      this.snackbar = true
    },
    async back () {
      this.finish = false
      this.sellFinish = false
      this.loading = true
      this.destination = null
      this.quantity = 1
      this.amount = 1
      this.price = 0
      this.getAssets(this.walletAssets, this.address)
      this.loading = false
    },
    shareSupport () {
      return navigator.share
    },
    share () {
      if (navigator.share) {
        navigator.share({
          title: 'Wallet4Good',
          url: this.sell.url
        }).then(() => {
          // console.log('Thanks for sharing!')
        })
          .catch(console.error)
      } else {
        // fallback
      }
    },
    copy () {
      this.text = 'Copy Success'
      navigator.clipboard.writeText(this.url)
      this.snackbar = true
    },
    copySell () {
      this.text = 'Copy Success'
      navigator.clipboard.writeText(this.sell.url)
      this.snackbar = true
    },
    copyOwnerAsset (item) {
      this.text = 'Copy Success'
      navigator.clipboard.writeText(item)
      this.snackbar = true
    },
    paste () {
      navigator.clipboard.readText().then((address) => {
        this.destination = address
        this.text = 'Paste Success'
        this.snackbar = true
      })
    },
    editAssetName (asset) {
      this.asset = asset
      this.editDialog = true
    },
    async viewAssetFile (file) {
      this.loadingDialog = true
      const uri = this.$store.state.localBox !== false ? file.file_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : file.file_url
      const data = await this.$axios.get(uri)
      const parts = data.data
      const result = []
      parts.forEach((item) => {
        const decrypted = CryptoJS.AES.decrypt(item, this.asset.privateKey).toString(CryptoJS.enc.Utf8)
        result.push(new Uint8Array(JSON.parse(decrypted)).buffer)
      })
      const blob = new File(result, file.name, {
        type: file.type,
        lastModified: file.lastModified
      })
      this.assetViewUrl = window.URL.createObjectURL(blob)
      this.loadingDialog = false
      this.assetViewDialog = true
    },
    async downloadAssetFile (file) {
      this.loadingDialog = true
      const uri = this.$store.state.localBox !== false ? file.file_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : file.file_url
      const data = await this.$axios.get(uri)
      const parts = data.data
      const result = []
      parts.forEach((item) => {
        const decrypted = CryptoJS.AES.decrypt(item, this.asset.privateKey).toString(CryptoJS.enc.Utf8)
        result.push(new Uint8Array(JSON.parse(decrypted)).buffer)
      })
      const blob = new File(result, file.name, {
        type: file.type,
        lastModified: file.lastModified
      })
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = file.name
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
      this.loadingDialog = false
    },
    async showAssetFiles (asset) {
      this.loadingDialog = true
      this.asset = asset
      const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + asset.pathUri)
      const metadataRaw = await this.$axios.get(url)
      const metadata = metadataRaw.data
      const imageUrl = this.$store.state.localBox !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : metadata.image_url
      const preview = await this.$axios.get(imageUrl)
      const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
      this.asset.preview = window.URL.createObjectURL(blob)
      this.asset.originalOwner = metadata.owner
      this.asset.originalOwnerEncoded = this.encodeAddress(this.asset.originalOwner)
      this.asset.creator = metadata.creator
      this.asset.royalty = metadata.royalty
      this.asset.description = metadata.description
      this.asset.files = metadata.attributes
      this.assetFilesDialog = true
      this.loadingDialog = false
    },
    async showAsset (asset) {
      this.loadingDialog = true
      this.asset = asset
      const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + asset.pathUri)
      const metadataRaw = await this.$axios.get(url)
      const metadata = metadataRaw.data
      if (this.asset.kind === '8') {
        this.generateQRAsset(this.frontAddress(this.asset.metadata.hardwarewalletaddress, this.asset.metadata.selecttoken))
      } else {
        const imageUrl = this.$store.state.localBox !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : metadata.image_url
        const preview = await this.$axios.get(imageUrl)
        const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
        this.asset.preview = window.URL.createObjectURL(blob)
      }
      this.asset.originalOwner = metadata.owner
      this.asset.originalOwnerEncoded = this.encodeAddress(this.asset.originalOwner)
      this.asset.creator = metadata.creator
      this.asset.royalty = metadata.royalty
      this.asset.description = metadata.description
      this.assetDialog = true
      this.loadingDialog = false
    },
    async previewIcon (asset) {
      if (asset.pathUri === '/asset/none') {
        return false
      }
      try {
        const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + asset.pathUri)
        const metadataRaw = await this.$axios.get(url)
        const metadata = metadataRaw.data
        const imageUrl = this.$store.state.localBox !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : metadata.image_url
        const preview = await this.$axios.get(imageUrl)
        const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
        asset.previewImageUrl = window.URL.createObjectURL(blob)
        return true
      } catch (e) {
        return false
      }
    },
    connectToBox (asset) {
      this.$axios.get('https://pay4good.com/asset/setConnectHash', {
        params: {
          hash: asset.hash,
          time: 1 * 60 * 60,
          crc: Date.now()
        }
      }).then((result) => {
        this.$axios.post('https://' + asset.dns + ':9696/token', {
          token: result.data.token
        }).then((result) => {
          this.$store.state.localBox = asset.dns
        })
      })
    },
    async settingAsset (asset) {
      this.loadingDialog = true
      this.settingOnline = false
      this.asset = asset
      try {
        this.$axios.get('https://' + asset.dns + ':9696/ping', {
        }).then(() => {
          this.settingOnline = true
          this.text = 'Box4Good Detected'
          this.snackbar = true
        })
      } catch (e) {
      }
      if (this.settingTab === 'stab0') {
      }
      if (this.settingTab === 'stab1') {
        this.supportAssets = this.assets.filter(item => item.kind >= 3 && item.kind < 10)
        for (const item of this.supportAssets) {
          const result = await this.getAssetFiles(item)
          item.preview = result.preview
          item.files = result.files
        }
      }
      this.settingDialog = true
      this.loadingDialog = false
    },
    async getMetadata (pathUri) {
      try {
        // console.log('get Metadata pathUri: ' + pathUri)
        if (pathUri === '/asset/none') {
          return false
        }
        const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + pathUri)
        const metadataRaw = await this.$axios.get(url)
        return metadataRaw.data
      } catch (e) {
        return false
      }
    },
    async getAssetFiles (asset) {
      // console.log('get!')
      // console.log(asset)
      const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + asset.pathUri)
      const metadataRaw = await this.$axios.get(url)
      const metadata = metadataRaw.data
      const imageUrl = this.$store.state.localBox !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : metadata.image_url
      const preview = await this.$axios.get(imageUrl)
      const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
      const result = {
        preview: window.URL.createObjectURL(blob),
        files: metadata.attributes.filter(item => item.type.includes('image'))
      }
      return result
    },
    setKind (kind) {
      this.loading = true
      this.selectKind = kind
      if (kind !== null) {
        this.getAssets(this.walletAssets, this.address)
      } else {
        this.assets = []
      }
      this.loading = false
    },
    async settingAssetComplete () {
      this.loadingDialog = true
      if (this.settingTab === 'stab0') {
        await this.$axios.get('https://pay4good.com/asset/setAssetSite', {
          params: {
            hash: this.asset.hash,
            address: this.asset.address,
            site: this.asset.site,
            ticket: this.asset.ticketURL,
            crc: Date.now()
          }
        })
        await this.$axios.get('https://pay4good.com/asset/setAssetCountry', {
          params: {
            hash: this.asset.hash,
            address: this.asset.address,
            country: this.asset.country,
            crc: Date.now()
          }
        })
        this.loading = true
        // this.getAssets(this.walletAssets, this.address)
        this.loading = false
      }
      if (this.settingTab === 'stab1') {
        const display = {}
        display.interval = this.settingInterval
        display.update = moment().unix()
        display.items = []
        for (const item of this.supportAssets) {
          for (const file of item.files) {
            if (file.selected) {
              display.items.push({
                content: file.file_url,
                type: file.type,
                hash: item.hash
              })
            }
          }
        }
        await this.$axios.get('https://pay4good.com/asset/setDisplay', {
          params: {
            hash: this.asset.hash,
            address: this.address,
            display: display,
            crc: Date.now()
          }
        })
      }
      this.loadingDialog = false
      this.settingDialog = false
    },
    async settingAssetClearComplete () {
      this.loadingDialog = true
      const display = {}
      display.update = moment().unix()
      display.items = false
      await this.$axios.get('https://pay4good.com/asset/setDisplay', {
        params: {
          hash: this.asset.hash,
          address: this.address,
          display: display,
          crc: Date.now()
        }
      })
      this.loadingDialog = false
      this.settingDialog = false
    },
    async sendToken (asset) {
      console.log('send')
      this.amount = asset.balance
      this.asset = asset
      this.asset.signer = new Ethers(this.asset.wallet)
      if (this.asset.kind.toString() === '8') {
        const transport = await TransportWebUSB.create()
        console.log(transport)
        this.destination = this.asset.metadata.hardwarewalletaddress
        if (this.asset.token === 'btc') {
          this.asset.signer = new Ledger(new AppBtc(transport))
          this.asset.signer.setPath(asset.metadata.selectpath ? asset.metadata.selectpath : null)
          console.log(await this.asset.signer.getAddress())
        } else if (this.asset.token === 'eth') {
          this.asset.signer = new Ledger(new AppEth(transport))
          this.asset.signer.setPath(asset.metadata.selectpath ? asset.metadata.selectpath : null)
        } else {
          this.asset.signer = new Ledger(new AppEth(transport))
          this.asset.signer.setPath(asset.metadata.selectpath ? asset.metadata.selectpath : null)
        }
      } else {
        this.destination = this.wallet.getAddress()
      }
      this.sendDialog = true
    },
    async sendTokenComplete () {
      this.loading = true
      this.finish = true
      this.sendDialog = false
      const tx = {}
      if (this.asset.kind.toString() === '8') {
        this.transactionResult = await this.send(this.asset.metadata.hardwarewalletaddress, this.asset.token, this.amount.toString(), 'withdraw', this.asset.signer)
        console.log(this.transactionResult)
        this.loading = false
      } else {
        tx.value = utils.parseEther(this.amount.toString())
        this.transaction = await this.asset.wallet.transfer(this.destination, tx.value)
        this.wallet.provider.once(this.transaction.hash, (transaction) => {
          this.transactionResult = transaction
          this.loading = false
        })
      }
    },
    receiveToken (asset) {
      this.amount = 0
      this.asset = asset
      console.log('receiveToken - asset wallet: ' + this.wallet.address)
      this.asset.signer = new Ethers(this.wallet)
      console.log('receiveToken - asset signer: ' + this.asset.signer)
      if (this.asset.kind.toString() === '8') {
        this.destination = this.asset.metadata.hardwarewalletaddress
      } else {
        this.destination = this.asset.address
      }
      console.log('receiveToken - asset.kind: ' + this.asset.kind.toString())
      console.log('receiveToken - destination address : ' + this.destination)
      this.receiveDialog = true
    },
    async receiveTokenComplete () {
      this.loading = true
      this.finish = true
      this.receiveDialog = false
      const tx = {}
      if (this.asset.kind.toString() === '8') {
        console.log(this.asset)
        this.transactionResult = await this.send(this.asset.metadata.hardwarewalletaddress, this.asset.token, this.amount.toString(), 'send', this.asset.signer)
        console.log(this.transactionResult)
        this.loading = false
      } else {
        tx.value = utils.parseEther(this.amount.toString())
        this.transaction = await this.walletToken.USD.transfer(this.destination, tx.value)
        tx.from = await this.wallet.getAddress()
        tx.to = this.destination
        tx.value = utils.parseEther((this.amount * this.currentFee * 100).toFixed(18).toString())
        await this.wallet.signTransaction(tx)
        await this.wallet.sendTransaction(tx)
        this.wallet.provider.once(this.transaction.hash, (transaction) => {
          this.transactionResult = transaction
          this.loading = false
        })
      }
    },
    sellAsset (asset) {
      this.amount = 1
      this.asset = asset
      this.destination = process.env.VUE_APP_CORE_ADDRESS
      this.sellDialog = true
    },
    async sellAssetComplete () {
      this.loading = true
      this.sellDialog = false
      await this.walletAssets.setApprovalForAll(process.env.VUE_APP_CORE_ADDRESS, true)
      const metadataRaw = await this.$axios.get('https://asset4good.com/' + this.asset.pathUri)
      const metadata = metadataRaw.data
      const sell = await this.$axios.get('https://pay4good.com/asset/createSell', {
        params: {
          address: this.address,
          hash: this.asset.hash,
          type: this.sellType,
          price: this.price,
          amount: this.amount,
          preview: metadata.image_url,
          royalty: metadata.royalty,
          originalOwner: metadata.owner,
          duration: this.sellDuration,
          description: this.sellDescription,
          crc: Date.now()
        }
      })
      this.sell = sell.data
      await this.generateQR()
      this.loading = false
      this.finish = true
      this.sellFinish = true
    },
    async publishAssetComplete () {
      this.loading = true
      this.sellDialog = false
      await this.walletAssets.setApprovalForAll(process.env.VUE_APP_CORE_ADDRESS, true)
      await this.$axios.get('https://deptofgood-bloqs4good.web.app/api/assets/registerAsset', {
        params: {
          identity: this.$store.state.identity,
          hash: this.asset.hash,
          cid: this.asset.pathUri
        }
      })
      await this.$axios.get('https://pay4good.com/asset/setAssetPublish', {
        params: {
          address: this.asset.address,
          hash: this.asset.hash,
          publish: true,
          crc: Date.now()
        }
      })
      this.loading = false
      this.text = 'Publish Success'
      this.snackbar = true
    },
    transferAsset (asset) {
      this.amount = 1
      this.asset = asset
      this.transferDialog = true
    },
    async transferAssetComplete () {
      this.loading = true
      this.finish = true
      this.transferDialog = false
      this.transaction = await this.walletAssets.transferAsset(this.address, this.decodeAddress(this.destination), this.quantity, this.asset.id)
      this.wallet.provider.once(this.transaction.hash, (transaction) => {
        this.transactionResult = transaction
        this.loading = false
      })
    },
    async saveAsset () {
      this.loadingDialog = true
      await this.$axios.get('https://pay4good.com/asset/setAssetName', {
        params: {
          address: this.asset.address,
          hash: this.asset.hash,
          name: this.asset.name,
          crc: Date.now()
        }
      })
      this.editDialog = false
      this.loadingDialog = false
    },
    async generateQR () {
      const qrcode = new QRCodeStyling({
        width: 250,
        height: 250,
        margin: 4,
        data: this.sell.url,
        image: require('@/assets/logo.png'),
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#3D81c2'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })
      await qrcode._canvasDrawingPromise
      this.image = await qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    async generateQRAsset (item) {
      const qrcode = new QRCodeStyling({
        width: 300,
        height: 300,
        margin: 4,
        data: item,
        image: require('@/assets/logo.svg'),
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#3D81c2'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })
      await qrcode._canvasDrawingPromise
      this.qrCode = await qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    async getAssets (wallet, address) {
      this.syncing = true
      const storeAssets = (await wallet.getAssetsByOwner(address))
      const assets = []
      this.count = 0
      // Retraive Pending assets
      this.$axios.get('https://pay4good.com/asset/getPendingAssets', {
        params: {
          owner: this.address,
          valid: new Date(),
          crc: Date.now()
        }
      }).then(result => {
        this.pending = result.data
      })
      for (const asset of storeAssets) {
        if (asset.kind.toString() !== this.selectKind) {
          continue
        }
        this.count++
        this.getAsset(wallet, address, assets, asset)
      }
    },
    async getAsset (wallet, address, assets, asset) {
      const id = (await wallet.getIndexOfByHash(address, asset.assetHash))
      const assetBalance = await wallet.balanceOf(address, id)
      if (assetBalance === 0) {
        this.count--
        // console.log(this.count)
        if (this.count === 0) {
          this.syncing = false
          this.assets = assets
          this.updateMaxPage()
          await this.cacheSet('assets', assets)
        }
        return
      }
      const assetWallet = new Wallet(asset.assetPK, this.wallet.provider)
      const assetData = await this.getMetadata(asset.pathUri)
      let attr = false
      let contract = process.env.VUE_APP_CONTRACT
      let token = 't4g'
      try {
        attr = await this.$axios.get('https://pay4good.com/asset/getAssetAttr', {
          params: {
            hash: asset.assetHash,
            address: await assetWallet.getAddress(),
            version: process.env.VUE_APP_VERSION,
            crc: Date.now()
          }
        })
        if (attr.data.country === 'US') {
          contract = process.env.VUE_APP_CONTRACT
          token = 't4g'
        }
        if (attr.data.country === 'SV') {
          contract = process.env.VUE_APP_CONTRACT
          token = 't4g'
        }
        if (attr.data.country === 'CA') {
          contract = process.env.VUE_APP_CONTRACT_CAD
          token = 't4gcad'
        }
        if (attr.data.country === 'BR') {
          contract = process.env.VUE_APP_CONTRACT_BRL
          token = 't4gbrl'
        }
        if (attr.data.country === 'CL') {
          contract = process.env.VUE_APP_CONTRACT_CLP
          token = 't4gclp'
        }
        if (asset.kind.toString() === '7') {
          contract = process.env.VUE_APP_CONTRACT_ROCT
          token = 'roct'
        }
        if (asset.kind.toString() === '8') {
          if (assetData.selecttoken === 'A4G' || assetData.selecttoken === 'APTA') {
            token = 'a4g'
          }
          if (assetData.selecttoken === 'ETH') {
            token = 'eth'
          }
          if (assetData.selecttoken === 'BTC') {
            token = 'btc'
          }
          if (assetData.selecttoken === 'T4G-USD' || assetData.selecttoken === 'T4G') {
            contract = process.env.VUE_APP_CONTRACT
            token = 't4g'
          }
        }
      } catch (e) {
      }
      // console.log(token)
      let assetWalletToken
      let balance
      if (asset.kind.toString() === '8') {
        // console.log(assetData)
        if (token !== 'a4g') {
          assetWalletToken = new Contract(contract, abi, assetWallet)
          try {
            balance = utils.formatUnits(await assetWalletToken.balanceOf(this.alwaysDecoded(assetData.hardwarewalletaddress)))
          } catch (e) {
            balance = '0'
          }
        } else if (token === 'btc') {
          assetWalletToken = assetWallet
          const result = await this.$axios.get('https://api.blockcypher.com/v1/btc/main/addrs/' + this.alwaysDecoded(assetData.hardwarewalletaddress) + '/balance', {})
          balance = result.data.final_balance / 10 ** 8
        } else if (token === 'eth') {
          const result = await this.$axios.get('https://api.blockcypher.com/v1/etc/main/addrs/' + this.alwaysDecoded(assetData.hardwarewalletaddress) + '/balance', {})
          balance = result.data.final_balance / 10 ** 18
        } else {
          assetWalletToken = assetWallet
          try {
            balance = utils.formatUnits(await wallet.provider.getBalance(this.alwaysDecoded(assetData.hardwarewalletaddress)))
          } catch (e) {
            balance = '0'
          }
        }
        console.log('asset.kind: ', asset.kind.toString())
        console.log('Token: ', token)
        console.log('Address: ', assetData.hardwarewalletaddress)
        console.log('balance: ', balance)
      } else {
        if (token !== 'a4g') {
          assetWalletToken = new Contract(contract, abi, assetWallet)
          balance = utils.formatUnits(await assetWalletToken.balanceOf(assetWallet.getAddress()))
        } else {
          // token = 'a4g'
          // balance = await wallet.provider.getBalance(decodeAddress(assetData.hardwarewalletaddress))
          // assetWalletToken = new Contract(contract, abi, assetWallet)
          // balance = utils.formatUnits(await assetWalletToken.balanceOf(assetWallet.getAddress()))
          assetWalletToken = assetWallet
          balance = await wallet.provider.getBalance(assetWallet.getAddress())
        }
        console.log('asset.kind: ', asset.kind.toString())
        console.log('Token: ', token)
        console.log('Address: ', await assetWallet.getAddress())
        console.log('balance: ', balance)
      }
      let sellStatus = null
      try {
        sellStatus = await this.$axios.get('https://pay4good.com/asset/getSellByHash', {
          params: {
            hash: asset.assetHash,
            owner: this.address,
            crc: Date.now()
          }
        })
      } catch (e) {
        sellStatus = null
      }
      assets.push({
        id: id,
        wallet: assetWalletToken,
        hash: asset.assetHash,
        kind: asset.kind.toString(),
        pathUri: asset.pathUri.toString(),
        assetBalance: assetBalance.toString(),
        balance: balance.toString(),
        quantity: asset.quantity.toString(),
        address: await assetWallet.getAddress(),
        privateKey: asset.assetPK,
        owner: asset.owner,
        token: token,
        contract: contract,
        country: attr ? attr.data.country : null,
        verificator: attr ? attr.data.verificator : null,
        site: attr ? attr.data.site : null,
        name: attr ? (attr.data.name ? attr.data.name : '-') : '-',
        ticketURL: attr ? attr.data.ticketURL ? attr.data.ticketURL : null : null,
        ip: attr ? attr.data.ip : false,
        tips: attr ? attr.data.tips : false,
        publish: attr ? attr.data.publish === 'true' : false,
        dns: asset.assetHash.substring(asset.assetHash.length - 16) + '.box4good.com',
        sellStatus: !!sellStatus.data,
        sell: sellStatus.data,
        metadata: assetData
      })
      this.count--
      // console.log(this.count)
      if (this.count === 0) {
        this.assets = assets
        this.updateMaxPage()
        await this.updateTipsAssets()
        this.syncing = false
        await this.cacheSet('assets', assets)
      }
    },
    findTipsAsset (address) {
      const tip = this.assets.find(item => item.address === address)
      return tip || { hash: '', address: '' }
    },
    mintTipsAsset (asset) {
      this.loadingDialog = true
      this.$axios.get('https://pay4good.com/asset/mintTipsAsset', {
        params: {
          hash: asset.hash,
          address: this.address,
          crc: Date.now()
        }
      }).then(result => {
        this.loadingDialog = false
        this.loading = true
        this.getAssets(this.walletAssets, this.address)
        this.loading = false
      })
    },
    async updateTipsAssets () {
      for (const asset of this.assets) {
        if (asset.kind) {
          if (asset.tips) {
            const assetFound = this.assets.find(item => item.address === asset.tips)
            if (!assetFound) {
              continue
            }
            // console.log(assetFound)
            // console.log('tips!!!')
            const assetWallet = new Wallet(assetFound.privateKey, this.wallet.provider)
            // console.log(asset)
            const assetWalletToken = new Contract(asset.contract, abi, assetWallet)
            const balance = utils.formatUnits(await assetWalletToken.balanceOf(assetWallet.getAddress()))
            assetFound.country = asset.country
            assetFound.token = asset.token
            assetFound.wallet = assetWalletToken
            assetFound.balance = balance.toString()
          }
        }
      }
    }
  }
}
</script>
