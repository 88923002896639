<template>
  <div class="Main">
    <v-dialog v-model="qr" fullscreen>
      <v-card style="background-color: #000000ee">
        <v-card-text class="" style="height: 100vh">
          <v-container fluid class="my-auto fill-height" v-if="qrCurrency">
            <v-row>
              <v-col align-self="start" align="center">
                <div class="white--text title">{{ qrCurrency.name }}</div>
                <v-img :src="require('../../assets/' + qrCurrency.type + '.svg')" width="64" align="left" contain>
                  <span v-if="qrCurrency.country !== null" class="black--text font-weight-bold"
                        style="font-size: 1.2rem !important; line-height: 1rem !important;">
                    {{ getFlag(qrCurrency.country) }}
                  </span>
                </v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="center">
                <v-img contain :src="qrCode" max-width="300" style="border-radius: 15px"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="end" align="center">
                <v-alert
                  v-show="qrCurrency.type === 'btc' && qrCurrency.address.substring(0,1) === '1'"
                  shaped
                  type="info"
                >Bitcoin Legacy Address (2010) starts with a 1 e.g. 16mV... (34 characters)</v-alert>
                <v-alert
                  v-show="qrCurrency.type === 'btc' && qrCurrency.address.substring(0,1) === '3'"
                  shaped
                  type="info"
                >Bitcoin Legacy Wrapped Segwit Address (2017) starts with a 3 e.g. 39vL... (34 characters)</v-alert>
                <v-alert
                  v-show="qrCurrency.type === 'btc' && qrCurrency.address.substring(0,4) === 'bc1q'"
                  shaped
                  type="info"
                >Bitcoin Native Segwit Address (2018) starts with bc1q... (42 characters)</v-alert>
                <v-alert
                  v-show="qrCurrency.type === 'btc' && qrCurrency.address.substring(0,4) === 'bc1p'"
                  shaped
                  type="info"
                >Bitcoin Taproot Address (2021) starts with bc1p... (62 characters)</v-alert>
                <v-text-field class="my-5" dark outlined dense :value="qrCurrency.address" readonly hide-details
                              append-icon="mdi-content-copy" @click:append="copy(qrCurrency.address)"/>
                <v-btn class="my-5" dark block outlined @click="qr = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%" max-height="196px"/>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main && ready" color="transparent" dark :class="{'rounded-xl': true, 'shadow': true, 'center-box': true, 'jinni-box': $store.state.customLogo === 'jinni.svg'}">
        <v-card-text align="center" class="px-1">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row class="mb-3">
              <v-col align-self="center" align="center">
                <div class="float-menu">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon @click="showHidden = !showHidden">
                        <v-icon v-bind="attrs" v-on="on">mdi-eye-settings-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Show Small Balance</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon :to="'/history/' + encodeAddress(address) + '/all'" >
                        <v-icon @click="'/history/' + encodeAddress(address) + '/all'" v-bind="attrs" v-on="on">mdi-history</v-icon>
                      </v-btn>
                    </template>
                    <span>Transactions History</span>
                  </v-tooltip>
                </div>
                <div class="title font-weight-bold">Total Balance
                </div>
                <span class="display-1 font-weight-bold">~{{ getTotal() | numFormat('0,0.00') }} <span style="font-size: 1.2rem">USD</span></span>
                <div style="width: 450px; max-width: 100%;">
                  <v-text-field :value="encodeAddress(address)" readonly hide-details append-icon="mdi-content-copy"
                                @click:append="copy(encodeAddress(address))"
                  ></v-text-field>
                </div>
              </v-col>
              <v-alert
                shaped
                color="#272727"
                type="info"
                dismissible
              >Self-Custody DeFi Wallet: The private keys of your assets are encrypted inside of your ID4Good. Your keys, your coins.</v-alert>
            </v-row>
            <v-row v-for="(currency, index) in currencies" v-bind:key="index" v-show="currency.balance >= currency.minimal || showHidden" color="transparent" dark class="rounded-xl shadow-currency left-box my-3 mx-1">
              <v-col align-self="center" align="center" cols="5">
                <!-- <cryptoicon :symbol="currency.type" size="48"/> -->
                <!-- <v-img :src="require('../../assets/'+'t4g'+'.svg')" /> -->
                <v-img @click="showQR(currency)" :contain="true" width="48"
                       :src="require('../../assets/' + currency.icon)" align="left">
                  <span v-if="currency.country !== null" class="font-weight-bold"
                        style="font-size: 1rem !important; line-height: 1rem !important;">
                    {{ getFlag(currency.country) }}
                  </span>
                  <span v-if="currency.good" class="font-weight-bold"
                        style="font-size: 1.2rem !important; line-height: 100% !important;">
                    g
                  </span>
                </v-img>
                <div>
                  <span v-if="$vuetify.breakpoint.width < 450" style="font-size: 0.65rem; font-weight: bold">{{ currency.name }}</span>
                  <span v-if="$vuetify.breakpoint.width >= 450 && $vuetify.breakpoint.width < 500" style="font-size: 0.8rem; font-weight: bold">{{ currency.name }}</span>
                  <span v-if="$vuetify.breakpoint.width >= 500 && $vuetify.breakpoint.width < 600" style="font-size: 0.9rem; font-weight: bold">{{ currency.name }}</span>
                  <span v-if="$vuetify.breakpoint.width >= 600" style="font-size: 1rem; font-weight: bold">{{ currency.name }}</span>
                </div>
                <div>
                  <span v-if="currency.type === 'roct'" style="font-size: 0.9rem;">
                                      1 {{ currency.symbol }} = {{
                                        (currency.convert) | numFormat('0,0.[00]')
                                      }} {{ currency.convertType }}
                  </span>
                  <span v-if="currency.type !== 'roct' && currency.convert >= 0.01 && currency.convert < 100" style="font-size: 0.9rem;">
                                      1 {{ currency.symbol }} = ${{
                      (currency.convert) | numFormat('0,0.[00]')
                    }}
                  </span>
                  <span v-if="currency.type !== 'roct' && currency.convert < 0.01" style="font-size: 0.9rem;">
                                      $1 = {{
                      (1/currency.convert) | numFormat('0,0')
                    }} {{ currency.symbol }}
                  </span>
                  <span v-if="currency.type !== 'roct' && currency.convert >= 100" style="font-size: 0.9rem;">
                                      1 {{ currency.symbol }} = ${{
                      (currency.convert) | numFormat('0,0')
                    }}
                  </span>
                </div>
               <!-- <span class="subtitle-2 font-weight-bold">{{ currency.name }}</span> -->
              </v-col>
              <v-col class="pr-0" align-self="center" align="center" cols="7">
                <!-- <div class="subtitle-2" style="font-size: 0.5rem;">USD$ ~{{ currency.convert | numFormat('0,0.[00]') }}</div> // -->
                <div class="subtitle-2">
                  <span v-if="$vuetify.breakpoint.width < 400" style="font-size: 1.8rem;">${{
                      (parseFloat(currency.balance).toFixed(7) * currency.convert) | numFormat('0,0.[00]')
                    }}
                  </span>
                  <span v-if="$vuetify.breakpoint.width >= 400" style="font-size: 2.2rem;">${{
                      (parseFloat(currency.balance).toFixed(7) * currency.convert) | numFormat('0,0.[00]')
                    }}</span>
                  <span>
                  <v-tooltip v-if="currency.symbol !== 'ETH' && currency.symbol !== 'BTC'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :to="'/history/' + currency.address + '/' + currency.type" class="mx-1"
                             medium
                             icon v-bind="attrs"
                             v-on="on">
                        <v-icon medium>mdi-history</v-icon>
                      </v-btn>
                    </template>
                    <span>History</span>
                  </v-tooltip>
                    <v-tooltip v-if="currency.symbol === 'ETH'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :href="'https://www.blockchain.com/eth/address/' + currency.address" target="_blank" class="mx-1"
                             medium
                             icon v-bind="attrs"
                             v-on="on">
                        <v-icon medium>mdi-history</v-icon>
                      </v-btn>
                    </template>
                    <span>History</span>
                  </v-tooltip>
                    <v-tooltip v-if="currency.symbol === 'BTC'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn :href="'https://www.blockchain.com/btc/address/' + currency.address" target="_blank" class="mx-1"
                             medium
                             icon v-bind="attrs"
                             v-on="on">
                        <v-icon medium>mdi-history</v-icon>
                      </v-btn>
                    </template>
                    <span>History</span>
                  </v-tooltip>
                    </span>
                </div>
                  <div class="subtitle-2">
                    <span v-if="$vuetify.breakpoint.width >= 400 && currency.balance >= 1" style="font-size: 1rem;"> {{
                      parseFloat(currency.balance).toFixed(7) | numFormat('0,0.[00]')
                    }} <span style="font-size: 0.9rem">{{ currency.symbol }}</span>
                          </span>
                    <span v-if="$vuetify.breakpoint.width < 400 && currency.balance >= 1" style="font-size: 0.8rem;"> {{
                        parseFloat(currency.balance).toFixed(7) | numFormat('0,0.[00]')
                      }} <span style="font-size: 0.8rem">{{ currency.symbol }}</span>
                          </span>
                    <span v-if="$vuetify.breakpoint.width >= 400 && currency.balance < 1" style="font-size: 1rem;"> {{
                        parseFloat(currency.balance).toFixed(7) | numFormat('0,0.[0000000]')
                      }} <span style="font-size: 0.9rem">{{ currency.symbol }}</span>
                          </span>
                    <span v-if="$vuetify.breakpoint.width < 400 && currency.balance < 1" style="font-size: 0.8rem;"> {{
                        parseFloat(currency.balance).toFixed(7) | numFormat('0,0.[0000000]')
                      }} <span style="font-size: 0.8rem">{{ currency.symbol }}</span>
                          </span>
                  </div>
                <div class="subtitle-2 mt-1">
                  <span style="font-size: 0.8rem;">
                      <v-tooltip v-if="false" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="currency.separated" @click="showQR(currency)" class="mx-1 font-weight-bold" :small="$vuetify.breakpoint.width < 600" :medium="$vuetify.breakpoint.width >= 600"
                                 outlined dark rounded v-bind="attrs" v-on="on">
                            <v-icon :small="$vuetify.breakpoint.width < 600" :medium="$vuetify.breakpoint.width >= 600">mdi-qrcode</v-icon>
                          </v-btn>
                        </template>
                        <span>Show <span>{{currency.symbol}} QR Code</span></span>
                      </v-tooltip>
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="currency.separated || currency.type === 'a4g' || currency.type === 'roct'" :to="'/swap/' + currency.type" class="mx-1 font-weight-bold" :small="$vuetify.breakpoint.width < 600" :medium="$vuetify.breakpoint.width >= 600"
                                 icon v-bind="attrs" v-on="on">
                            <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-swap-horizontal-bold</v-icon>
                          </v-btn>
                        </template>
                        <span>Swap <span>{{currency.symbol}}</span></span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="currency.separated" :to="'/send/' + currency.type" class="mx-1 font-weight-bold" :small="$vuetify.breakpoint.width < 600" :medium="$vuetify.breakpoint.width >= 600"
                                 icon v-bind="attrs" v-on="on">
                            <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-arrow-up-bold-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Send <span>{{currency.symbol}}</span></span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn :disabled="currency.separated || currency.type === 'roct'" :to="'/receive/' + currency.type" class="mx-1 font-weight-bold" :small="$vuetify.breakpoint.width < 600" :medium="$vuetify.breakpoint.width >= 600"
                                 icon v-bind="attrs" v-on="on">
                            <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-arrow-down-bold-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Receive <span>{{currency.symbol}}</span></span>
                      </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn disabled :to="'/currency-defi/' + currency.type" class="mx-1 font-weight-bold" :small="$vuetify.breakpoint.width < 600" :medium="$vuetify.breakpoint.width >= 600"
                                 icon v-bind="attrs" v-on="on">
                            <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-currency-usd</v-icon>
                          </v-btn>
                        </template>
                        <span>DeFi <span>{{currency.symbol}}</span></span>
                      </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn  @click="copy(currency.address)" class="mx-1 font-weight-bold" :small="$vuetify.breakpoint.width < 600" :medium="$vuetify.breakpoint.width >= 600"
                                 icon v-bind="attrs" v-on="on">
                            <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-content-copy</v-icon>
                          </v-btn>
                        </template>
                        <span>Copy Wallet Address <span>{{currency.symbol}}</span></span>
                      </v-tooltip>
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn block outlined dark rounded @click="showHidden = !showHidden" class="mb-2">
                  <v-icon class="mx-2">mdi-eye-settings-outline</v-icon>
                  {{ showHidden ? 'Hide Small Balances' : 'Show Small Balance' }}
                </v-btn>
                <v-btn block outlined dark rounded :to="'/history/' + encodeAddress(address) + '/all'" class="mb-2">
                  <v-icon class="mx-2">mdi-history</v-icon>
                  Transactions History
                </v-btn>
             <!---   <v-btn block color="#10395D" dark to="/assets">
                  <v-icon class="mx-2">mdi-apps</v-icon>
                  More Assets
                </v-btn> //-->
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Main.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import QRCodeStyling from 'qr-code-styling'

export default {
  name: 'Main',
  components: { Bar },
  data () {
    return {
      qr: false,
      showHidden: false,
      qrCode: null,
      qrCurrency: false,
      main: false,
      snackbar: false,
      text: 'Copy Success'
    }
  },
  mixins: [currency],
  mounted () {
    setTimeout(() => {
      this.show()
    }, 2000)
  },
  methods: {
    checkImage (img) {
      try {
        require(img)
        return true
      } catch (e) {
        console.log(e.toString())
        return false
      }
    },
    show () {
      this.main = true
    },
    getFlag (country) {
      return getUnicodeFlagIcon(country)
    },
    shareSupport () {
      return navigator.share
    },
    share () {
      if (navigator.share) {
        navigator.share({
          title: 'ID4Good Address',
          url: this.encodeAddress(this.address)
        }).then(() => {
          console.log('Thanks for sharing!')
        })
          .catch(console.error)
      } else {
        // fallback
      }
    },
    showQR (currency) {
      this.qrCurrency = currency
      this.generateQR(currency.address)
      this.qr = true
    },
    copy (item) {
      this.text = 'Copy Success'
      navigator.clipboard.writeText(item || this.encodeAddress(this.address))
      this.snackbar = true
    },
    async generateQR (item) {
      const qrcode = new QRCodeStyling({
        width: 300,
        height: 300,
        margin: 4,
        data: item,
        image: require('@/assets/logo.svg'),
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#3D81c2'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })
      await qrcode._canvasDrawingPromise
      this.qrCode = await qrcode._canvas.getCanvas().toDataURL('image/png')
    }
  }
}
</script>
