<template>
    <div class="Claim">
      <script charset="utf-8" type="application/javascript" src="https://js.hsforms.net/forms/shell.js"></script>
      <v-container fluid class="fill-height">
        <v-row dense>
          <v-col align-self="start" :align="$device.mobile ? 'center' : 'left'" :class="!$device.mobile ? 'pl-15' : ''">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : $store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="250px"/>
          </v-col>
        </v-row>
        <v-row dense v-if="form">
          <v-col align="center" align-self="center">
            <div class="black--text font-weight-bold overline" style="font-size: 1.2rem !important">{{ $t('Complete before to claim your ticket') }}</div>
            <div style="max-width: 700px">
            <script type="application/javascript">
              window.hbspt.forms.create({
                region: "na1",
                portalId: "5179500",
                formId: "db4d254c-6b21-4506-970e-c5d88510280a"
              });
            </script>
            </div>
          </v-col>
        </v-row>
        <v-row dense v-if="!form">
          <v-col align-self="center" align="center">
            <div v-if="!loading" class="black--text font-weight-bold overline" style="font-size: 1.2rem !important">{{ $t('Claim your ticket') }}</div>
            <div v-if="!loading" class="black--text font-weight-bold overline">{{ $t('Scan QR with ID4Good') }}</div>
          </v-col>
        </v-row>
        <v-row dense v-if="!form">
          <v-col align-self="center" align="center">
            <v-progress-circular color="black" indeterminate width="10" size="150" v-if="loading">{{ $t('Checking') }}...</v-progress-circular>
            <v-img v-if="!loading && !fail" :src="qr" contain width="250px" height="250px" class="qr"/>
            <div><v-btn v-if="linkMobile" :href="linkMobile" outlined class="my-5">Claim with ID4Good</v-btn></div>
            <div v-if="fail" class="black--text font-weight-bold overline mb-10" style="font-size: 1.5rem !important">{{ $t('Claim Failed') }}</div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align="center" align-self="end" class="overline">
            {{ $t('Download ID4Good from') }}
          </v-col>
        </v-row>
        <v-row dense>
          <v-col></v-col>
          <v-col align="center" align-self="center"><a target="_blank" href="https://apps.apple.com/pe/app/id4good/id1536997799"><v-img :src="require('@/assets/apple-store-badge.svg')" max-height="80" contain></v-img></a></v-col>
          <v-col align="center" align-self="center"><a target="_blank" href="https://play.google.com/store/apps/details?id=com.bloqs4good.id4good"><v-img :src="require('@/assets/google-play-badge.png')" max-height="115" contain></v-img></a></v-col>
          <v-col></v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="end" :align="$device.mobile ? 'center' : 'right'" :class="!$device.mobile ? 'pr-15' : ''">
            <v-img :src="require('@/assets/logobew.svg')" contain width="100px"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Claim.scss'
import { generateQR } from 'id4good.auth'
export default {
  name: 'Claim',
  data () {
    return {
      qr: null,
      loading: false,
      fail: false,
      success: false,
      hash: false,
      linkMobile: false,
      form: false
    }
  },
  mounted () {
    this.hash = this.$route.params.hash
    setTimeout(() => { this.form = true }, 500)
    window.addEventListener('message', event => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        this.continueClaim()
      }
    })
  },
  methods: {
    continueClaim () {
      this.form = false
      this.loading = true
      this.load()
      console.log('Form Submitted')
    },
    async load () {
      this.$axios.get('https://pay4good.com/asset/claimTicket', { params: { hash: this.hash, crc: Date.now() } }).then(async (result) => {
        this.qr = await generateQR(result.data.ticket)
        if (this.$device.mobile) {
          if (this.$device.ios) {
            this.linkMobile = 'https://id4good.com/' + result.data.ticket
          }
          if (this.$device.android) {
            this.linkMobile = 'id4good://wallet4good/' + result.data.ticket + ';scheme=id4good;package=com.bloqs4good.id4good;end'
          }
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.fail = true
      })
    }
  }
}
</script>
