import Vue from 'vue'
import Cryptoicon from 'vue-cryptoicon'
import icons from 'vue-cryptoicon/src/icons'

const A4G = {
  symbol: 'a4g',
  color: '#000',
  plainIcon: c => {
    return `<g fill="none" fill-rule="evenodd" id="g6"> <circle cx="16" cy="16" r="16" fill="${c || this.color}" id="circle2" /> <text xml:space="preserve" x="5.1341701" y="20.313444" id="text859"><tspan sodipodi:role="line" id="tspan857" x="5.1341701" y="20.313444" style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:80%;font-family:NeoSans;-inkscape-font-specification:NeoSans;fill:#ffffff;">A4G</tspan></text> </g>`
  },
  colorIcon () {
    return '<g fill="none" fill-rule="evenodd" id="g6"> <circle cx="16" cy="16" r="16" fill="#9dddaec" id="circle2" /> <text xml:space="preserve" x="5.1341701" y="20.313444" id="text859"><tspan sodipodi:role="line" id="tspan857" x="5.1341701" y="20.313444" style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:80%;font-family:NeoSans;-inkscape-font-specification:NeoSans;fill:#ffffff;">A4G</tspan></text></g>'
  }
}

const T4G = {
  symbol: 't4g',
  color: '#000',
  plainIcon: c => {
    return `<g fill="none" fill-rule="evenodd" id="g6"> <circle cx="16" cy="16" r="16" fill="${c || this.color}" id="circle2" /> <text xml:space="preserve" x="5.1341701" y="20.313444" id="text859"><tspan sodipodi:role="line" id="tspan857" x="5.1341701" y="20.313444" style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:80%;font-family:NeoSans;-inkscape-font-specification:NeoSans;fill:#ffffff;">T4G</tspan></text> </g>`
  },
  colorIcon () {
    return '<g fill="none" fill-rule="evenodd" id="g6"> <circle cx="16" cy="16" r="16" fill="#9dddaec" id="circle2" /> <text xml:space="preserve" x="5.1341701" y="20.313444" id="text859"><tspan sodipodi:role="line" id="tspan857" x="5.1341701" y="20.313444" style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:80%;font-family:NeoSans;-inkscape-font-specification:NeoSans;fill:#ffffff;">T4G</tspan></text></g>'
  }
}

Cryptoicon.add(icons)
Cryptoicon.add(T4G)
Cryptoicon.add(A4G)
Vue.use(Cryptoicon)
