<template>
    <div class="Ticket">
      <v-container fluid class="fill-height">
        <v-row dense>
          <v-col align-self="start" :align="$device.mobile ? 'center' : 'left'" :class="!$device.mobile ? 'pl-15' : ''">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : $store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="250px"/>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center">
            <div v-if="!loading" class="black--text font-weight-bold overline" style="font-size: 1.2rem !important">{{ $t('Claim your ticket') }}</div>
            <div v-if="!loading" class="black--text font-weight-bold overline">{{ $t('Scan QR with ID4Good') }}</div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="center" align="center">
            <v-img v-if="!loading" :src="qr" contain width="250px" height="250px" class="qr"/>
            <v-progress-circular color="black" indeterminate width="10" size="150" v-if="loading && !success && !fail">{{ $t('Checking') }}...</v-progress-circular>
            <v-img v-if="success" :src="require('@/assets/ticket/success.svg')" contain width="200px" height="200px"  class="my-5" />
            <v-img v-if="fail" :src="require('@/assets/ticket/fail.svg')" contain width="200px" height="200px"  class="my-5" />
          </v-col>
          <v-col align-self="center" align="center">
            <div v-if="success" class="black--text font-weight-bold overline mb-10" style="font-size: 1.5rem !important">{{ $t('Access Successful') }}</div>
            <div v-if="fail" class="black--text font-weight-bold overline mb-10" style="font-size: 1.5rem !important">{{ $t('Access Failed') }}</div>
            <v-img v-if="!fail && !success" :src="require('@/assets/ticket/system.svg')" contain width="200px" height="200px"  class="my-3" />
            <v-btn x-small @click="reset" dark class="mx-2">reset</v-btn>
            <v-btn x-small href="https://box4good.com/" outlined class="mx-2 ">Return to Box4Good</v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col align-self="end" :align="$device.mobile ? 'center' : 'right'" :class="!$device.mobile ? 'pr-15' : ''">
            <v-img :src="require('@/assets/logobew.svg')" contain width="100px"/>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_Ticket.scss'
import { generateQR, getChallenge, getIdentity, waitChallenge } from 'id4good.auth'
import { mapState } from 'vuex'
export default {
  name: 'Ticket',
  data () {
    return {
      qr: null,
      loading: false,
      fail: false,
      success: false
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    ...mapState('trust', {
      connected: state => state.connected,
      wallet: state => state.wallet,
      walletToken: state => state.walletToken,
      walletAssets: state => state.walletAssets,
      address: state => state.address
    })
  },
  methods: {
    clearAllIntervals () {
      for (let i = 0; i <= 1000; i++) {
        clearInterval(i)
      }
      for (let i = 0; i <= 1000; i++) {
        clearTimeout(i)
      }
    },
    reset () {
      window.location.reload()
    },
    async verifyTicket () {
      await this.walletAssets.setApprovalForAll(process.env.VUE_APP_CORE_ADDRESS, true)
      try {
        const result = await this.$axios.get('https://pay4good.com/asset/verifyTicket', {
          params: {
            address: this.address,
            hash: this.$route.params.hash,
            crc: Date.now()
          }
        })
        if (result.data.verify === 'success') {
          this.success = true
        } else {
          this.fail = true
        }
      } catch (e) {
        this.fail = true
      }
    },
    async load () {
      const challenge = await getChallenge('wallet4good.com')
      this.qr = await generateQR(challenge.code)
      // this.qr = await generateQR('asset:0:e3621e27b5bc3be0747481874fe91ece9ba0f4f17a2c518ea57cd9beef29cdf8')
      const expire = setTimeout(() => {
        this.load()
      }, 5 * 60000)
      waitChallenge(challenge.hash, 0).then(async (result) => {
        clearTimeout(expire)
        this.loading = true
        const identity = await getIdentity(challenge.hash)
        this.clearAllIntervals()
        let data = {}
        if (identity.data.data !== undefined) {
          try {
            data = JSON.parse(identity.data.data)
          } catch (e) {
            data = identity.data.data
          }
          const privateKey = data.privateKey
          await this.$store.dispatch('trust/connect', { privateKey: privateKey })
          await this.$store.dispatch('bitcoin/connect', { privateKey: privateKey })
          await this.verifyTicket()
        } else {
          console.log('You need to update your ID4Good')
        }
      }).catch(() => {
        this.fail = true
      })
    }
  }
}
</script>
