import Vue from 'vue'
import { ECPair, payments, networks } from 'bitcoinjs-lib'
import { MD5 } from 'crypto-js'
import axios from 'axios'

export const MSGS = {
  CONNECTED: 'Connected to Trust Network',
  NOT_CONNECTED: 'Not connected to Trust Network',
  NOT_READY: 'Trust Network not ready',
  NO_WALLET: 'No Trust wallet detected',
  ACCOUNT_CHANGED: 'Trust account changed',
  BITCOIN_ACCOUNT_CHANGED: 'Ethereum account changed',
  ETHERS_VUEX_INITIALIZED: 'Trust vuex module initialized',
  ETHERS_VUEX_READY: 'Trust vuex module ready'
}
export const EVENT_CHANNEL = 'bitcoin'

export const event = new Vue()

let provider
let userWallet
// let initialized
let hash

export function getProvider () {
  return provider
}

export function getWallet () {
  return userWallet
}

export async function getWalletAddress () {
  // Legacy const { address } = payments.p2pkh({ pubkey: userWallet.publicKey })
  // const network = networks.bitcoin
  // const { address } = payments.p2wpkh({ pubkey: userWallet.publicKey, network })
  return userWallet && userWallet.address
  /*
* Taproot (2021) starts with a bc1p... (length 62 characters) | (BIP86, P2TR, Bench32m) format: bech32m path: 86'
* Native Segwit (2018) starts with a bc1q... (length 42 characters) | (BIP84, P2WPKH, Bench32) format: bech32, path: 84'
* Legacy Wrapped Segwit (2017) starts with a 3 e.g. 39vL... (length 34 characters) | (BIP49, P2PKH-P2WPKH, Base 58) format: p2sh, path: 49'
* Legacy (2010) starts with a 1 e.g. 16mV... (length 34 characters) | (BIP44, P2PKH, Base 58), format: legacy , path: 44'
* */
}

export async function getBalance () {
  const address = await getWalletAddress()
  const result = await axios.get('https://api.blockcypher.com/v1/btc/main/addrs/' + address + '/balance', {
  })
  return result.data.final_balance / 10 ** 8
}

export function ready () {
  return !!provider && !!userWallet
}

export async function initWallet (privateKey) {
  hash = MD5(privateKey)
  const keyPair = ECPair.makeRandom({ rng: rng })
  // const wif = keyPair.toWIF()
  const network = networks.bitcoin
  // const LegacyAddress = payments.p2pkh({ pubkey: keyPair.publicKey }).address
  // const NativeSegwitAddress = payments.p2wpkh({ pubkey: keyPair.publicKey, network }).address
  // const { address } = payments.p2wpkh({ pubkey: keyPair.publicKey, network })
  const { address } = payments.p2pkh({ pubkey: keyPair.publicKey }) // Legacy
  // let publicKey = keyPair.publicKey.toString('hex')
  // console.log('LegacyAddress: ' + LegacyAddress)
  // console.log('NativeSegwitAddress: ' + NativeSegwitAddress)
  userWallet = keyPair
  userWallet.address = address
  if (await getBalance() === 0) { // Balance from Legacy is 0 convert to segwit
    const { address } = payments.p2wpkh({ pubkey: keyPair.publicKey, network }) // Native Segwit
    // publicKey = keyPair.publicKey.toString('hex')
    userWallet = keyPair
    userWallet.address = address
  }
  /* if (wif || address || publicKey) {
    console.log('wif: ' + wif)
    console.log('address: ' + address)
    console.log('publicKey: ' + publicKey)
  } */
  /*
* Taproot (2021) starts with a bc1p... (length 62 characters) | (BIP86, P2TR, Bench32m) format: bech32m path: 86'
* Native Segwit (2018) starts with a bc1q... (length 42 characters) | (BIP84, P2WPKH, Bench32) format: bech32, path: 84'
* Legacy Wrapped Segwit (2017) starts with a 3 e.g. 39vL... (length 34 characters) | (BIP49, P2PKH-P2WPKH, Base 58) format: p2sh, path: 49'
* Legacy (2010) starts with a 1 e.g. 16mV... (length 34 characters) | (BIP44, P2PKH, Base 58), format: legacy , path: 44'
* */
}

export async function connect (ctx) {
  // initialized = true
  // console.log(initialized)
  // provider = new WebSocket('wss://socket.blockcypher.com/v1/btc/main?token=' + process.env.VUE_APP_BTC_TOKEN)
  provider = new WebSocket('wss://ws.blockchain.info/inv')
}

function rng () {
  return Buffer.from(hash.toString())
}

export default {
  connect,
  getProvider,
  getWallet,
  getWalletAddress,
  getBalance,
  ready
}
