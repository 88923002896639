<template>
  <div class="CreateAsset">
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="finish && !stack" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-container fluid v-if="!loading">
                  <v-row class="title font-weight-bold white--text">
                    <v-col>{{ transactionResult.confirmations > 0 ? 'SUCCESS' : 'FAIL' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Transaction:</v-col>
                    <v-col align="left">{{ $device.mobile ? '...' + transactionResult.transactionHash.substring(transactionResult.transactionHash.length - 10) : '...' + transactionResult.transactionHash.substring(transactionResult.transactionHash.length - 32) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Block:</v-col>
                    <v-col align="left">{{ $device.mobile ? '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 10) : '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 32) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">From:</v-col>
                    <v-col align="left">{{ $device.mobile ? '...' + encodeAddress(address).substring(encodeAddress(address).length - 10) : encodeAddress(address) }}</v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Asset:</v-col>
                    <v-col align="left">{{ name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" to="/assets" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="finish && stack" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-container fluid v-if="!loading">
                  <v-row class="title font-weight-bold white--text">
                    <v-col>IN PROCESS</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Transaction:</v-col>
                    <v-col align="left">pending</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Block:</v-col>
                    <v-col align="left">pending</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">From:</v-col>
                    <v-col align="left">{{ '...' + encodeAddress(address).substring(encodeAddress(address).length - 10) }}</v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Asset:</v-col>
                    <v-col align="left">{{ name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" to="/assets" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="main && !finish && ready" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid>
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10 mb-2" v-if="loading" size="150" color="white" width="10" indeterminate>Sync
                </v-progress-circular>
                <v-progress-linear
                  v-if="loading"
                  v-model="process"
                  striped
                  color="white"
                  height="30"
                  class="my-5"
                >
                  <template v-slot:default="{ }">
                    <strong class="black--text">{{ processText }}</strong>
                  </template>
                </v-progress-linear>
                <v-container v-if="!loading" fluid class="fill-height" style="max-width: 700px">
                  <v-row>
                    <v-col>
                      <v-text-field :rules="[value => value.length >= 4 && value.length < 51 || 'Asset Name required 4 - 50']" v-model="name" dense label="Digital Asset Name" outlined dark/>
                      <v-text-field :rules="[value => value.length >= 4 && value.length < 51 || 'Creator Name required 4 - 50']" v-model="creator" dense label="Creator Name" outlined dark/>
                      <v-text-field step="1" min="1" max="256" :rules="[value => (Number(value) <= 256 && Number(value) > 0) || 'Quantity required range 1 - 256']" v-model="quantity" type="number" dense label="Quantity" outlined dark/>
                      <v-text-field v-model="royalty" dense
                                    :rules="[value => (Number(value) <= 100 && Number(value) >= 0) || 'Incorrect percentage']"
                                    label="Royalty" suffix="%" type="number" step="1" min="0" max="100" outlined dark/>
                      <v-textarea :rules="[value => value.length >= 4  && value.length <= 500 || 'Description required 4 - 500']" v-model="description" rows="3" dense label="Description" outlined dark/>
                      <v-img :src="preview" class="rounded-xl" contain max-width="250" max-height="250" height="250"
                             width="250"></v-img>
                      <input type="file" style="display: none" accept="image/*" ref="inputPreview"
                             @change="previewImage"/>
                      <v-btn dark class="mt-1 mb-2" @click="$refs.inputPreview.click()">Set Preview</v-btn>
                      <v-card class="rounded-lg my-5" color="#00000077">
                        <v-card-title>
                          <div style="width: 50px"></div>
                          <v-spacer/>
                          Files
                          <v-spacer/>
                          <v-btn outlined dark small @click="addFile">
                            <v-icon small>mdi-plus</v-icon>
                          </v-btn>
                        </v-card-title>
                        <v-card-text>
                          <v-container class="pa-0">
                            <v-row dense v-for="(file, index) in files" v-bind:key="index">
                              <v-col>
                                <v-file-input :class="{ 'mx-1': true, uniqueFile: file.unique !== undefined ? file.unique : false , noUniqueFile: file.unique  !== undefined ? !file.unique : false }" v-model="file.file"
                                              prepend-icon="mdi-circle-multiple-outline" dense :error-messages="file.unique  !== undefined ? !file.unique ? 'The file already exists on the platform' : '' : ''"
                                              label="File" show-size @change="(e) => { calculateFee(e, index) }">
                                </v-file-input>
                              </v-col>
                              <v-col cols="3">
                                <v-text-field class="mx-1" v-model="file.tag" dense label="Tag"/>
                              </v-col>
                              <v-col cols="2">
                                <v-btn outlined dark small @click="files.splice(index, 1)">
                                  <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mt-5 overline text-uppercase">
                      <div>The content of the digital asset will be encrypted, and will only be accessible by the owner
                        of the digital asset.
                      </div>
                      <div>Cost depend of size of digital asset.</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="mt-5 overline black--text text-uppercase">
                      <div>Estimate Cost: {{ (currentFee).toFixed(7) | numFormat('0,0.00[00]') }} {{ getSymbol('t4g') }}</div>
                      <div class="red--text font-weight-bold" v-show="balanceToken.USD < (currentFee).toFixed(7)">Insufficient {{ getSymbol('t4g') }} in your Wallet</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-btn class="mt-10" @click="preCreateDigitalAsset" dark block :disabled="balanceToken.USD < (currentFee).toFixed(7) || name.length === 0 || creator.length === 0 || description.length === 0 || this.files.find(item => item.unique === false)">Create Digital Asset</v-btn>
                      <v-btn class="mt-1 mb-10" to="/assets" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
    <v-dialog fullscreen v-model="editPreview" v-if="editPreview" content-class="CreateAsset">
      <v-container fluid class="fill-height dialog-profile">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="editor-box">
              <PinturaEditor
                v-bind="editorDefaults"
                :src="preview"
                :imageCropAspectRatio="1"
                @pintura:process="finishPreview"
              ></PinturaEditor>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import './_CreateAsset.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'
import CryptoJS from 'crypto-js'
import { create } from 'ipfs-http-client'
import { utils } from 'ethers'
import { getEditorDefaults, createDefaultImageWriter } from 'pintura'
import { PinturaEditor } from 'vue-pintura'

const chunkSize = 1024 * 1024

export default {
  name: 'CreateAsset',
  components: { Bar, PinturaEditor },
  mixins: [currency],
  data () {
    return {
      main: false,
      loading: false,
      currentFee: 1,
      snackbar: false,
      editPreview: false,
      editorDefaults: getEditorDefaults(),
      name: '',
      creator: '',
      quantity: 1,
      royalty: 0,
      description: '',
      track: null,
      files: [],
      filePreview: {
        file: null,
        tag: '_preview',
        cid: ''
      },
      filesEncrypted: {},
      process: 0,
      processText: '',
      count: 0,
      size: 0,
      stack: false,
      progressSize: 0,
      transactionResult: { blockHash: '' },
      finish: false,
      preview: require('@/assets/vasset.webp'),
      text: 'Copy Success'
    }
  },
  watch: {
    royalty: {
      deep: true,
      handler: function (value) {
        this.royalty = Math.round(value)
      }
    }
  },
  async mounted () {
    this.editorDefaults.imageWriter = createDefaultImageWriter({
      targetSize: {
        width: 250,
        height: 250,
        upscale: true
      }
    })
    const response = await fetch(this.preview)
    const data = await response.blob()
    this.filePreview.file = new File([data], 'preview.webp', { type: '' })
    setTimeout(() => {
      this.show()
    }, 300)
  },
  methods: {
    addFile () {
      // console.log('add')
      this.files.push({
        file: null,
        tag: null,
        cid: null
      })
      this.$forceUpdate()
    },
    async show () {
      this.main = true
    },
    async calculateFee (e, index) {
      // console.log(e)
      if (e === null) {
        return
      }
      if (e.size > 20 * 1024 * 1024 && !this.$store.state.localBox) {
        this.text = 'Files over 20MB require a Box4Good'
        this.snackbar = true
        setTimeout(() => {
          this.files[index].file = null
          this.$forceUpdate()
        }, 500)
        return
      }

      this.currentFee = 1
      let size = 0
      for (const file of this.files) {
        size += file.file.size
      }
      this.size = size
      if (size > 50 * 1024 * 1024) {
        this.currentFee += 1 + 0.020 * size / 1024 / 1024
        this.text = 'The files size generates a cost of ' + this.currentFee.toFixed(4)
        this.snackbar = true
      }
      const reader = new FileReader()
      reader.onloadend = async (event) => {
        // console.log(this.files)
        const md5 = CryptoJS.MD5(event.target.result).toString()
        this.files[index].md5 = md5
        this.$axios.get('https://pay4good.com/asset/verifyUniqueDigitalAsset', {
          params: {
            md5: md5,
            valid: new Date(),
            crc: Date.now()
          }
        }).then((file) => {
          // console.log(file)
          this.files[index].unique = false
          this.$forceUpdate()
        }).catch(() => {
          // console.log('unique')
          this.files[index].unique = true
          this.$forceUpdate()
        })
        // console.log(this.files)
      }
      setTimeout(() => {
        reader.readAsBinaryString(e)
      }, 500)
    },
    async finishPreview (result) {
      // console.log(result.dest)
      this.filePreview.file = result.dest
      this.preview = URL.createObjectURL(result.dest)
      this.editPreview = false
    },
    async previewImage (e) {
      const file = e.target.files[0]
      if (file.size > 5 * 1024 * 1024) {
        this.text = '5MB limit supported'
        this.snackbar = true
        return
      }
      const url = URL.createObjectURL(file)
      // const img = new Image()
      // img.src = url
      // await img.decode()
      this.filePreview.file = file
      this.preview = url
      this.editPreview = true
    },
    async preCreateDigitalAsset () {
      this.loading = true
      this.processText = 'Checking...'
      let size = 0
      for (const file of this.files) {
        size += file.file.size
      }

      this.walletToken.USD.approve(process.env.VUE_APP_CORE_ADDRESS, utils.parseEther('500'))

      const track = await this.$axios.get('https://pay4good.com/asset/preCreateDigitalAsset', {
        params: {
          owner: this.address,
          name: this.name,
          creator: this.creator,
          quantity: this.quantity,
          royalty: this.royalty,
          description: this.description,
          size: size,
          crc: Date.now()
        }
      })
      this.track = track.data
      await this.uploadFiles()
    },
    async createDigitalAsset () {
      if (!this.finish) {
        await this.stackDigitalAsset()
        return
      }
      this.processText = 'Mint Asset'
      this.files.push(this.filePreview)
      const transaction = await this.$axios.get('https://pay4good.com/asset/createDigitalAsset', {
        params: {
          id: this.track.id,
          files: this.files,
          crc: Date.now()
        }
      })
      this.transactionResult = transaction.data
      this.files.pop()
      this.process = 100
      this.processText = 'Mint Complete'
      this.loading = false
      this.finish = true
    },
    async stackDigitalAsset () {
      this.stack = true
      this.processText = 'Mint Asset'
      this.files.push(this.filePreview)
      const transaction = await this.$axios.get('https://pay4good.com/asset/stackDigitalAsset', {
        params: {
          id: this.track.id,
          files: this.files,
          crc: Date.now()
        }
      })
      this.transactionResult = transaction.data
      this.files.pop()
      this.process = 100
      this.processText = 'Mint in queue'
      this.loading = false
      this.finish = true
    },
    async uploadFiles () {
      if (this.track.id) {
        // upload Preview
        this.count++
        this.filePreview.type = this.filePreview.file.type
        this.filePreview.name = this.filePreview.file.name
        this.filePreview.lastModified = this.filePreview.file.lastModified
        await this._uploadFile(this.filePreview, 0)
        // upload files
        for (const file of this.files) {
          file.type = file.file.type
          file.name = file.file.name
          file.lastModified = file.file.lastModified
          file.cid = ''
          this.count++
          await this._uploadFile(file, 0)
        }
      }
    },
    _uploadFile (file, offset) {
      if (offset === 0) {
        this.filesEncrypted[file.name] = []
      }
      const nextSlice = offset + chunkSize + 1
      const blob = file.file.slice(offset, nextSlice)
      const reader = new FileReader()
      reader.onloadend = async (event) => {
        if (event.target.readyState !== FileReader.DONE) {
          return
        }
        this.progressSize += chunkSize
        this.processText = 'Encrypted ' + file.name
        const dataString = JSON.stringify(Array.from(new Uint8Array(event.target.result)))
        if (file.tag === '_preview') {
          this.filesEncrypted[file.name].push(dataString)
        } else {
          const encrypted = CryptoJS.AES.encrypt(dataString, this.track.key)
          this.filesEncrypted[file.name].push(encrypted.toString())
        }
        if (nextSlice < file.file.size) {
          this.process = Math.floor(this.progressSize / this.size * 50)
          this._uploadFile(file, nextSlice)
        } else {
          const encryptedFile = new File([JSON.stringify(this.filesEncrypted[file.name])], file.name + '.encrypted', {
            type: file.type,
            lastModified: file.lastModified
          })
          const ipfs = create(this.$store.state.localBox ? process.env.VUE_APP_IPFS_LOCAL.replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : process.env.VUE_APP_IPFS)
          const CID = await ipfs.add(encryptedFile, { progress: (prog) => console.log(prog) })
          file.cid = CID.path
          file.size = encryptedFile.size
          this.process = Math.floor(this.progressSize / this.size * 50)
          this.count--
          // Upload File
          if (this.count === 0) {
            // console.log('no more files')
            await this.createDigitalAsset()
          }
        }
      }
      reader.readAsArrayBuffer(blob)
    }
  }
}
</script>
