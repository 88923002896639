import Vue from 'vue'
import Vuex from 'vuex'
import trust from './trust'
import bitcoin from './bitcoin'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    box: false,
    sign: false,
    identity: false,
    localBox: false,
    dev: false,
    customLogo: false,
    rocarbon: false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    trust,
    bitcoin
  }
})
