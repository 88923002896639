<template>
  <div class="Bar">
    <v-app-bar bottom app class="rounded-t-xl shadow">
      <v-spacer></v-spacer>
      <v-btn to="/main" tile fab light class="mb-10 rounded-lg" v-show="!$route.meta.marketplace">
        <v-icon>mdi-cash-plus</v-icon>
        <div class="btn-text">Main</div>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn to="/assets" tile fab light class="mb-10 rounded-lg" v-show="!$route.meta.marketplace">
        <v-icon>mdi-apps</v-icon>
        <div class="btn-text">MY APPs</div>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn to="/buy" tile fab light class="mb-10 rounded-lg" v-show="!$route.meta.marketplace">
        <v-icon>mdi-hand-coin-outline</v-icon>
        <div class="btn-text">Buy</div>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn disabled to="/store" tile fab light class="mb-10 rounded-lg" v-show="!$route.meta.marketplace">
        <v-icon>mdi-basket-plus-outline</v-icon>
        <div class="btn-text">Imagine</div>
      </v-btn>
      <v-spacer v-if="$vuetify.breakpoint.mobile"></v-spacer>
      <v-spacer v-if="!$vuetify.breakpoint.mobile" style="width: 60%"></v-spacer>
      <div v-if="!$vuetify.breakpoint.mobile" class="mb-1 rounded-lg">
        <v-img :src="require('@/assets/logobew.svg')" contain width="200px"/>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import './_Bar.scss'
export default {
  name: 'Bar'
}
</script>
