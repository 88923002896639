<template>
  <div class="ShareBox">
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid>
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>Sync
                </v-progress-circular>
                <v-container v-if="!loading" fluid class="fill-height" style="max-width: 700px">
                  <v-row>
                    <v-col align-self="center" align="center" class="title text-uppercase font-weight-bold">
                      {{ attr.name }}
                    </v-col>
                  </v-row>
                  <v-row v-for="(item, index) in items" v-bind:key="index" style="border-bottom: 1px solid #00000022">
                    <v-col align-self="center" align="center" class="overline">
                      <span class="subtitle-1 font-weight-bold text-no-wrap">
                        {{ item.name }} <v-icon @click="editNameDialog(item)"  x-small>mdi-square-edit-outline</v-icon>
                      </span>
                      <v-img :src="item.qr" contain max-width="200" max-height="200" width="200" height="200" style="border-radius: 15px" />
                      <v-btn class="my-2" @click="revoke(item)">Revoke</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center" class="overline font-weight-bold">
                      Create Share
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-text-field label="name" v-model="name" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="3">
                      <v-btn @click="create" :disabled="name.length === 0">Create</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-btn class="mt-10 mb-10" to="/assets" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
    <v-dialog dark max-width="100%" v-model="editDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5">
        <v-card-text align="center">
          <v-text-field style="max-width: 700px" v-model="item.name" label="name"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="edit">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import './_ShareBox.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'
import QRCodeStyling from 'qr-code-styling'

export default {
  name: 'ShareBox',
  components: { Bar },
  mixins: [currency],
  data () {
    return {
      main: false,
      loading: true,
      snackbar: false,
      editDialog: false,
      name: '',
      item: '',
      text: 'Copy Success',
      hash: null,
      assetAddress: null,
      items: []
    }
  },
  async mounted () {
    console.log(this.$route)
    this.hash = this.$route.params.hash
    this.assetAddress = this.$route.params.address
    const attr = await this.$axios.get('https://pay4good.com/asset/getAssetAttr', {
      params: {
        hash: this.hash,
        address: await this.assetAddress,
        version: process.env.VUE_APP_VERSION,
        crc: Date.now()
      }
    })
    this.attr = attr.data
    this.load()
  },
  methods: {
    show () {
      this.main = true
      this.loading = false
    },
    async qr (url) {
      const qrcode = new QRCodeStyling({
        width: 200,
        height: 200,
        margin: 4,
        data: url,
        image: require('@/assets/logo.png'),
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#3D81c2'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })
      await qrcode._canvasDrawingPromise
      return await qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    async load () {
      const items = await this.$axios.get('https://pay4good.com/asset/getSubAssets', {
        params: {
          box: this.hash,
          owner: await this.address,
          crc: Date.now()
        }
      })
      if (items.data.subs) {
        this.items = items.data.subs
        for (const item of this.items) {
          item.qr = await this.qr('https://box4good.com/#/?hash=' + item.key)
        }
      }
      this.show()
      console.log(this.items)
    },
    async create () {
      this.loading = true
      await this.$axios.get('https://pay4good.com/asset/createSubAsset', {
        params: {
          box: this.hash,
          owner: await this.address,
          name: this.name,
          crc: Date.now()
        }
      })
      this.name = ''
      this.load()
    },
    editNameDialog (item) {
      this.item = item
      this.editDialog = true
    },
    async edit () {
      this.editDialog = false
      this.loading = true
      await this.$axios.get('https://pay4good.com/asset/editSubAsset', {
        params: {
          hash: this.item.key,
          box: this.hash,
          owner: this.item.owner,
          name: this.item.name,
          crc: Date.now()
        }
      })
      this.load()
    },
    async revoke (item) {
      this.item = item
      this.loading = true
      await this.$axios.get('https://pay4good.com/asset/deleteSubAsset', {
        params: {
          hash: this.item.key,
          box: this.hash,
          owner: this.item.owner,
          crc: Date.now()
        }
      })
      this.load()
    }
  }
}
</script>
