import Vue from 'vue'
import { Contract as ContractModule, providers, utils as utilsModule, Wallet as WalletModule } from 'ethers'
import { assetsAbi } from './ecr1155'

export const PROVIDER_CHECK_MS = 1000

// messages
export const MSGS = {
  CONNECTED: 'Connected to Trust Network',
  NOT_CONNECTED: 'Not connected to Trust Network',
  NOT_READY: 'Trust Network not ready',
  NO_WALLET: 'No Trust wallet detected',
  ACCOUNT_CHANGED: 'Trust account changed',
  ETHEREUM_ACCOUNT_CHANGED: 'Ethereum account changed',
  ETHERS_VUEX_INITIALIZED: 'Trust vuex module initialized',
  ETHERS_VUEX_READY: 'Trust vuex module ready'
}
export const EVENT_CHANNEL = 'trust'
export const event = new Vue()
export const utils = utilsModule
export const Contract = ContractModule

export const LOG_TRANSACTIONS = [
  'block'
]

const abi = [
  // Read-Only Functions
  'function balanceOf(address owner) view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function symbol() view returns (string)',

  // Authenticated Functions
  'function transfer(address to, uint amount) returns (boolean)',
  'function approve(address spender, uint value) returns (boolean)',
  // Events
  'event Transfer(address indexed from, address indexed to, uint amount)'
]

// for trust
let provider
let providerEthereum
let chainId
let userWallet
let userWalletEthereum
let userWalletEthereumToken
let userWalletToken
let userWalletAssets
// eslint-disable-next-line no-unused-vars
let currentAccount
let providerInterval
// eslint-disable-next-line no-unused-vars
let initialized

// get the name of this network
export async function getNetName () {
  switch (chainId) {
    case '0x1':
      return 'Mainnet'
    case '0x2':
      return 'Morden (deprecated)'
    case '0x3':
      return 'Ropsten Test Network'
    case '0x4':
      return 'Rinkeby Test Network'
    case '0x5':
      return 'Goerli Test Network'
    case '0x2a':
      return 'Kovan Test Network'
    case 7788:
      return 'Trust4Good Network Development'
    case undefined:
    case null:
      return 'No Chain!'
    // if you give your ganache an id your can detect it here if you want
    default:
      return 'Unknown'
  }
}

// get deployed address for a contract from its networks object and current network id or null
export async function getNetworkAddress (networks) {
  if (!networks[chainId] || !networks[chainId].address) return null
  return networks[chainId].address
}

export function getProvider () {
  return provider
}

export function getProviderEthereum () {
  return providerEthereum
}

export function getWallet () {
  return userWallet
}

export function getWalletEthereum () {
  return userWalletEthereum
}

export function getWalletEthereumToken () {
  return userWalletEthereumToken
}

export function getWalletToken () {
  return userWalletToken
}

export function getWalletAssets () {
  return userWalletAssets
}

export async function getWalletAddress () {
  return userWallet && await userWallet.getAddress()
}

export function ready () {
  return !!provider && !!userWallet
}

export async function beginWallet () {
  const randomWallet = WalletModule.createRandom()
  return randomWallet
}

export async function initWallet (privateKey) {
  userWallet = new WalletModule(privateKey, provider)
  currentAccount = userWallet.getAddress()
  userWalletToken = {}
  userWalletToken.USD = new ContractModule(process.env.VUE_APP_CONTRACT, abi, userWallet)
  userWalletToken.ROCT = new ContractModule(process.env.VUE_APP_CONTRACT_ROCT, abi, userWallet)
  userWalletToken.CAD = new ContractModule(process.env.VUE_APP_CONTRACT_CAD, abi, userWallet)
  userWalletToken.CLP = new ContractModule(process.env.VUE_APP_CONTRACT_CLP, abi, userWallet)
  userWalletToken.BRL = new ContractModule(process.env.VUE_APP_CONTRACT_BRL, abi, userWallet)
  userWalletToken.USDT = new ContractModule(process.env.VUE_APP_CONTRACT_USDT, abi, userWallet)
  userWalletToken.USDC = new ContractModule(process.env.VUE_APP_CONTRACT_USDC, abi, userWallet)
  userWalletToken.BUSD = new ContractModule(process.env.VUE_APP_CONTRACT_BUSD, abi, userWallet)
  userWalletAssets = new ContractModule(process.env.VUE_APP_CONTRACT_ASSETS, assetsAbi, userWallet)
  event.$emit(EVENT_CHANNEL, MSGS.ACCOUNT_CHANGED)
}

export async function initWalletEthereum (privateKey) {
  userWalletEthereum = new WalletModule(privateKey, providerEthereum)
  userWalletEthereumToken = {}
  userWalletEthereumToken.USDT = new ContractModule('0xdAC17F958D2ee523a2206206994597C13D831ec7', abi, userWalletEthereum)
  userWalletEthereumToken.USDC = new ContractModule('0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', abi, userWalletEthereum)
  userWalletEthereumToken.BUSD = new ContractModule('0x4Fabb145d64652a948d72533023f6E7A623C7C53', abi, userWalletEthereum)
  event.$emit(EVENT_CHANNEL, MSGS.ACCOUNT_CHANGED)
}

export async function connect (ctx) {
  try {
    let rpc = process.env.VUE_APP_RPC
    if (ctx.rootState.box !== false && !localStorage.dev) {
      rpc = process.env.VUE_APP_RPC_LOCAL
    }
    provider = new providers.StaticJsonRpcProvider(rpc)
    initialized = true
    chainId = (await provider.detectNetwork()).chainId
    if (!provider) return event.$emit(EVENT_CHANNEL, MSGS.NOT_CONNECTED)
  } catch (err) {
    if (err.code === 4001) {
      // EIP-1193 userRejectedRequest error
      // If this happens, the user rejected the connection request.
      // console.log('Please connect to Trust wallet')
      event.$emit(EVENT_CHANNEL, MSGS.NOT_READY, err)
    } else {
      // console.error('Error requesting Trust connection/accounts', err)
      event.$emit(EVENT_CHANNEL, MSGS.NOT_READY, err)
    }
  }
}

export async function connectEthereum (ctx) {
  try {
    providerEthereum = new providers.JsonRpcProvider(process.env.VUE_APP_RPC_ETHEREUM)
    if (!providerEthereum) return event.$emit(EVENT_CHANNEL, MSGS.NOT_CONNECTED)
  } catch (err) {
    if (err.code === 4001) {
      // EIP-1193 userRejectedRequest error
      // If this happens, the user rejected the connection request.
      // console.log('Please connect to Ethereum wallet')
      event.$emit(EVENT_CHANNEL, MSGS.NOT_READY, err)
    } else {
      // console.error('Error requesting Ethereum connection/accounts', err)
      event.$emit(EVENT_CHANNEL, MSGS.NOT_READY, err)
    }
  }
}

// stop interval looking for ethereum provider changes
export async function stopWatchProvider () {
  if (providerInterval) clearInterval(providerInterval)
  providerInterval = null
}

// start ethereum provider checker
// startProviderWatcher()

export default {
  connect,
  connectEthereum,
  getNetName,
  getProvider,
  getProviderEthereum,
  getWallet,
  getWalletEthereum,
  getWalletEthereumToken,
  getWalletToken,
  getWalletAddress,
  getWalletAssets,
  getNetworkAddress,
  ready
}
