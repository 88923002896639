import {
  MSGS,
  EVENT_CHANNEL,
  connect,
  event,
  ready,
  getProvider,
  getWallet,
  getWalletAddress,
  getNetName,
  getWalletToken,
  getWalletAssets,
  initWallet,
  beginWallet,
  getProviderEthereum,
  getWalletEthereum,
  connectEthereum,
  initWalletEthereum, getWalletEthereumToken
} from './Trust'
import { utils } from 'ethers'

export default {
  async preconnect (ctx) {
    try {
      // console.log('preconnect')
      const randomWallet = await beginWallet()
      return randomWallet
    } catch (err) {
      ctx.dispatch('disconnect', err)
    }
  },
  async connect (ctx, payload) {
    try {
      // console.log('connect Trust')
      await connect(ctx)
      await connectEthereum(ctx)
      await initWallet(payload.privateKey)
      await initWalletEthereum(payload.privateKey)
      const oldAddress = ctx.state.address
      const oldNetwork = ctx.state.network

      const provider = getProvider()
      if (!provider) throw new Error(MSGS.NOT_CONNECTED)

      const providerEthereum = getProviderEthereum()
      if (!providerEthereum) {
        // console.log('No Ethereum Provider')
      }

      const wallet = getWallet()
      if (!wallet) throw new Error(MSGS.NO_WALLET)
      const walletEthereum = getWalletEthereum()
      if (!walletEthereum) {
        // console.log('No Ethereum Wallet')
      }
      const walletToken = getWalletToken()
      const walletEthereumToken = getWalletEthereumToken()
      const walletAssets = getWalletAssets()
      const address = await getWalletAddress()
      const network = await getNetName()

      if (network !== oldNetwork || address !== oldAddress) {
        ctx.commit('connected', true)
        ctx.commit('error', null)
        ctx.commit('address', address)
        ctx.commit('wallet', wallet)
        ctx.commit('walletEthereum', walletEthereum)
        ctx.commit('walletEthereumToken', walletEthereumToken)
        ctx.commit('walletToken', walletToken)
        ctx.commit('walletAssets', walletAssets)
        ctx.commit('fee', utils.formatEther(await provider.getGasPrice()))
        ctx.commit('balance', utils.formatEther(await wallet.getBalance()))
        if (walletEthereum) {
          ctx.commit('balanceEthereum', utils.formatEther(await walletEthereum.getBalance()))
          const balanceEthereumToken = {
            USDT: utils.formatUnits(await walletEthereumToken.USDT.balanceOf(walletEthereum.getAddress()), 6),
            USDC: utils.formatUnits(await walletEthereumToken.USDC.balanceOf(walletEthereum.getAddress()), 6),
            BUSD: utils.formatUnits(await walletEthereumToken.USDC.balanceOf(walletEthereum.getAddress()))
          }
          ctx.commit('balanceEthereumToken', balanceEthereumToken)
        }
        const balanceToken = {
          USD: utils.formatUnits(await walletToken.USD.balanceOf(wallet.getAddress())),
          ROCT: utils.formatUnits(await walletToken.ROCT.balanceOf(wallet.getAddress())),
          CAD: utils.formatUnits(await walletToken.CAD.balanceOf(wallet.getAddress())),
          CLP: utils.formatUnits(await walletToken.CLP.balanceOf(wallet.getAddress())),
          BRL: utils.formatUnits(await walletToken.BRL.balanceOf(wallet.getAddress())),
          USDT: utils.formatUnits(await walletToken.USDT.balanceOf(wallet.getAddress())),
          USDC: utils.formatUnits(await walletToken.USDC.balanceOf(wallet.getAddress())),
          BUSD: utils.formatUnits(await walletToken.USDC.balanceOf(wallet.getAddress()))
        }
        ctx.commit('balanceToken', balanceToken)
        ctx.commit('network', network)

        /* console.log(`Your address/user has changed.
          Address: ${address}
          Network: ${network}
          Gas Price: ${await provider.getGasPrice()}
          Current Block #: ${await provider.getBlockNumber()}
          Your ether balance: ${await provider.getBalance(address)}`)
        event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_READY) */

        provider.on('block', (blockNumber) => {
          event.$emit(EVENT_CHANNEL, MSGS.ACCOUNT_CHANGED)
          // console.log('Block Trust mined:', blockNumber)
        })
        providerEthereum.on('block', (blockNumber) => {
          event.$emit(EVENT_CHANNEL, MSGS.ETHEREUM_ACCOUNT_CHANGED)
          // console.log('Block Ethereum mined:', blockNumber)
        })
      }
    } catch (err) {
      ctx.dispatch('disconnect', err)
    }
  },
  async disconnect (ctx, err) {
    // const oldAddress = ctx.state.address
    ctx.commit('connected', false)
    ctx.commit('error', err)
    ctx.commit('address', '')
    ctx.commit('balance', 0)
    ctx.commit('wallet', '')
    ctx.commit('network', '')
    ctx.commit('ens', null)

    /* const msg = err ? `There was an error: ${err.message}` : (oldAddress
      ? 'You have been disconnected from your Ethereum connection. Please check MetaMask, etc.'
      : 'You are not connected to an Ethereum node and wallet. Please check MetaMask, etc.')
     console.log(msg) */
  },
  async logout (ctx) {
    ctx.commit('address', '')
    ctx.commit('user', '')
    // console.log('You have been logged out from your Ethereum connection')
  },
  async notConnected (ctx) {
    ctx.commit('address', '')
    ctx.commit('wallet', '')
    ctx.commit('balance', 0)
    // console.log('You are not connected to the Ethereum network. Please check MetaMask,etc.')
  },
  async update (ctx) {
    const provider = getProvider()
    const wallet = getWallet()
    if (!wallet) throw new Error(MSGS.NO_WALLET)
    const walletEthereum = getWalletEthereum()
    const walletEthereumToken = getWalletEthereumToken()
    const walletToken = getWalletToken()
    const walletAssets = getWalletAssets()
    ctx.commit('wallet', wallet)
    ctx.commit('walletEthereum', walletEthereum)
    ctx.commit('walletEthereumToken', walletEthereumToken)
    ctx.commit('walletToken', walletToken)
    ctx.commit('walletAssets', walletAssets)
    ctx.commit('fee', utils.formatEther(await provider.getGasPrice()))
    ctx.commit('balance', utils.formatEther(await wallet.getBalance()))
    if (walletEthereum) {
      ctx.commit('balanceEthereum', utils.formatEther(await walletEthereum.getBalance()))
      const balanceEthereumToken = {
        USDT: utils.formatUnits(await walletEthereumToken.USDT.balanceOf(walletEthereum.getAddress()), 6),
        USDC: utils.formatUnits(await walletEthereumToken.USDC.balanceOf(walletEthereum.getAddress()), 6),
        BUSD: utils.formatUnits(await walletEthereumToken.USDC.balanceOf(walletEthereum.getAddress()))
      }
      ctx.commit('balanceEthereumToken', balanceEthereumToken)
    }
    const balanceToken = {
      USD: utils.formatUnits(await walletToken.USD.balanceOf(wallet.getAddress())),
      ROCT: utils.formatUnits(await walletToken.ROCT.balanceOf(wallet.getAddress())),
      CAD: utils.formatUnits(await walletToken.CAD.balanceOf(wallet.getAddress())),
      CLP: utils.formatUnits(await walletToken.CLP.balanceOf(wallet.getAddress())),
      BRL: utils.formatUnits(await walletToken.BRL.balanceOf(wallet.getAddress())),
      USDT: utils.formatUnits(await walletToken.USDT.balanceOf(wallet.getAddress())),
      USDC: utils.formatUnits(await walletToken.USDC.balanceOf(wallet.getAddress())),
      BUSD: utils.formatUnits(await walletToken.USDC.balanceOf(wallet.getAddress()))
    }
    ctx.commit('balanceToken', balanceToken)
  },
  async updateEthereum (ctx) {
    const walletEthereum = getWalletEthereum()
    if (!walletEthereum) {
      // console.log('No Ethereum Wallet')
    }
    ctx.commit('walletEthereum', walletEthereum)
    ctx.commit('balanceEthereum', utils.formatEther(await walletEthereum.getBalance()))
  },
  async init (ctx) {
    event.$on(EVENT_CHANNEL, async function (msg) {
      // console.log('Ethers event received', msg)
      switch (msg) {
        case MSGS.NOT_READY:
          await ctx.dispatch('disconnect')
          break
        case MSGS.NO_WALLET:
          await ctx.dispatch('logout')
          break
        case MSGS.ACCOUNT_CHANGED:
          await ctx.dispatch('update')
          break
        case MSGS.ETHEREUM_ACCOUNT_CHANGED:
          await ctx.dispatch('updateEthereum')
          break
        case MSGS.CONNECTED:
          await ctx.dispatch('connect')
          break
        case MSGS.NOT_CONNECTED:
          await ctx.dispatch('notConnected')
          break
      }
    })

    if (ready()) {
      event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_INITIALIZED)
    }

    ctx.commit('initialized', true)
  }
}
