import {
  MSGS,
  EVENT_CHANNEL,
  connect,
  event,
  ready,
  getProvider,
  getWallet,
  initWallet, getWalletAddress, getBalance
} from './Bitcoin'

export default {
  async connect (ctx, payload) {
    try {
      // console.log('connect Bitcoin')
      await connect(ctx)
      await initWallet(payload.privateKey)
      const provider = getProvider()
      const wallet = getWallet()
      provider.onopen = (event) => {
        provider.send(JSON.stringify({ op: 'blocks_sub' }))
      }
      provider.onmessage = (message) => {
        // console.log(message)
        const response = JSON.parse(message.data)
        if (response.op === 'block') {
          event.$emit(EVENT_CHANNEL, MSGS.ACCOUNT_CHANGED)
          // console.log('Block Bitcoin mined:', response.x.height)
        }
      }
      ctx.commit('wallet', wallet)
      ctx.commit('address', await getWalletAddress())
      ctx.commit('balance', await getBalance())
    } catch (err) {
      console.log(err)
      ctx.dispatch('disconnect', err)
    }
  },
  async disconnect (ctx, err) {
  },
  async logout (ctx) {
    ctx.commit('address', '')
    // console.log('You have been logged out from your Ethereum connection')
  },
  async notConnected (ctx) {
    ctx.commit('address', '')
    ctx.commit('wallet', '')
    ctx.commit('balance', 0)
    // console.log('You are not connected to the Ethereum network. Please check MetaMask,etc.')
  },
  async update (ctx) {
    ctx.commit('balance', await getBalance())
  },
  async init (ctx) {
    event.$on(EVENT_CHANNEL, async function (msg) {
      // console.log('Bitcoin event received', msg)
      switch (msg) {
        case MSGS.NOT_READY:
          await ctx.dispatch('disconnect')
          break
        case MSGS.NO_WALLET:
          await ctx.dispatch('logout')
          break
        case MSGS.ACCOUNT_CHANGED:
          await ctx.dispatch('update')
          break
        case MSGS.CONNECTED:
          await ctx.dispatch('connect')
          break
        case MSGS.NOT_CONNECTED:
          await ctx.dispatch('notConnected')
          break
      }
    })

    if (ready()) {
      event.$emit(EVENT_CHANNEL, MSGS.ETHERS_VUEX_INITIALIZED)
    }

    ctx.commit('initialized', true)
  }
}
