import firebase from 'firebase'

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyAtx4YmQMcE5E5xj5Ssc_sB5_0sbMvElgg',
  authDomain: 'wallet4good.firebaseapp.com',
  databaseURL: 'https://wallet4good-default-rtdb.firebaseio.com',
  projectId: 'wallet4good',
  storageBucket: 'wallet4good.appspot.com',
  messagingSenderId: '669355287112',
  appId: '1:669355287112:web:9cb4f184e4c9a942d8ee7d',
  measurementId: 'G-WEBQCVNHZD'
}

if (!firebase.apps.length) {
  firebase.initializeApp(config)
} else {
  firebase.app()
}

export default firebase
