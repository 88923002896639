<template>
  <div class="AssetsRocarbon">
    <v-dialog v-model="qr" fullscreen>
      <v-card style="background-color: #000000ee">
        <v-card-text class="" style="height: 100vh">
          <v-container fluid class="my-auto fill-height" v-if="qrAsset">
            <v-row>
              <v-col align-self="start" align="center">
                <div class="white--text title" style="font-size: 2rem !important;">{{ qrAsset.name }}</div>
                  <!-- <div v-if="(qrAsset.kind < 3 || qrAsset.kind == 10) && qrAsset.country !== null" class="black--text font-weight-bold"
                        style="font-size: 2rem !important;">
                    {{ getFlag(qrAsset.country) }}
                  </div> -->
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind < 3 || qrAsset.kind == 10" >
                  Box4Good
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 3">
                  Unique
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 4">
                  Series
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 5">
                  Ticket
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 6">
                  Reward
                </div>
                <div style="font-size: 1rem;" class="white--text" v-if="qrAsset.kind == 7">
                  RoCarbon
                </div>
               <!-- <v-icon size="48"
                >{{ assetTypes[qrAsset.kind].icon }}
                </v-icon> -->
              </v-col>
              </v-row>
            <v-row>
              <v-col align-self="center" align="center">
                <v-img contain :src="qrCode" max-width="300" style="border-radius: 15px"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="end" align="center">
                <v-text-field class="my-5" dark outlined dense :value="encodeAddress(qrAsset.address)" readonly hide-details
                              append-icon="mdi-content-copy" @click:append="copyOwnerAsset(encodeAddress(qrAsset.address))"/>
                <v-btn class="my-5" dark block outlined @click="qr = false">Close</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main && !finish && ready" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 800px">
            <v-row dense>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>Sync
                </v-progress-circular>
                <v-container fluid v-if="!loading" class="pa-0">
                  <v-row>
                    <v-col>
                      <v-select v-if="false" dense v-model="path" @change="changePath" :items="Object.values(createItems)" item-value="path" item-text="name"
                                label="Create"></v-select>
                      <v-text-field class="my-1" light v-model="search" label="Search" block outlined dense/>
                      <v-progress-linear v-if="syncing" color="white" dark indeterminate height="15"
                                         class="overline rounded">Sync
                      </v-progress-linear>
                    </v-col>
                  </v-row>
                  <v-row class="pl-1 pr-15"
                    v-for="(asset, index) in pending.filter(obj => search.length < 3 || obj.name.includes(search))"
                    v-bind:key="index">
                    <v-col align-self="center" align="center">
                      <v-icon :size="$vuetify.breakpoint.xsOnly && $vuetify.breakpoint.width >= 400 ? 24 : 48"
                              >mdi-progress-upload
                      </v-icon>
                    </v-col>
                    <v-col align-self="center" align="left">
                      <span class="subtitle-1 font-weight-bold">
                        {{ asset.name }}
                      </span>
                      <div class="subtitle-2"></div>
                    </v-col>
                    <v-col align-self="center" :align="$vuetify.breakpoint.width < 400 ? 'center' : 'right'"
                           :cols="$vuetify.breakpoint.width < 400 ? 12 : 6" class="px-0 mt-0 pt-0 overline">
                      In Progress
                    </v-col>
                  </v-row>
                  <v-row
                    v-for="(asset, index) in (assets.filter(obj => search.length < 3 || obj.name.toLowerCase().includes(search.toLowerCase()) || obj.hash.toLowerCase().includes(search.toLowerCase()))).slice((page - 1) * 10, page * 10)"
                    v-bind:key="index" class="rounded-lg shadow-currency left-box my-3 mx-1 my-0" align="center" justify="center"
                  >
                    <v-col align-self="center" align="center" cols="6" class="pa-5">
                      <div align="left" class="overline">
                        <span class="subtitle-1 font-weight-bold" v-if="asset.name.length < 128">
                          {{ asset.name }} <v-icon v-if="false" @click="editAssetName(asset)" medium>mdi-square-edit-outline</v-icon>
                        </span>
                        <span class="subtitle-1 font-weight-bold" v-if="asset.name.length >= 128">
                          {{ asset.name.substring(0, 128) }}... <v-icon v-if="false" @click="editAssetName(asset)" medium>mdi-square-edit-outline</v-icon>
                        </span>
                      </div>
                      <div align="left" class="overline" v-if="asset.metadata">
                        <span style="font-size: 0.8rem;">{{ asset.metadata.location }}</span>
                      </div>
                      <div align="left" v-if="asset.metadata">
                        <span style="font-size: 1rem;">{{ asset.metadata.description }}</span>
                      </div>
                      <div align="left" v-if="asset.metadata" class="mt-5" style="font-size: 1.6rem; font-weight: bold">
                        <img :src="require('@/assets/roct.png')" width="20px" height="20px" />
                        {{
                          asset.balance | numFormat('0,0')
                        }} {{ getSymbol(asset.token) }}
                      </div>
                    </v-col>
                    <v-col align-self="center" align="center" class="pa-5" :cols="$vuetify.breakpoint.width >= 700 ? 6 : 12" style="min-height: 200px">
                      <div>
                        <v-img class="shadow-currency shadow-currency-border" v-if="asset.previewImageUrl" style="margin-left: 5px; border-radius: 5px;" :src="asset.previewImageUrl" height="50%" />
                      </div>
                      <v-tooltip bottom v-if="asset.kind >= 3 && asset.kind < 10">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="showAsset(asset)" class="mx-1"
                                 :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                 icon v-bind="attrs" v-on="on">
                            <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-folder-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Preview</span>
                      </v-tooltip>
                      <v-tooltip bottom v-if="asset.kind >= 3 && asset.kind < 10 && asset.kind != 5">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn @click="showAssetFiles(asset)" class="mx-1"
                                 :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600"
                                 icon v-bind="attrs" v-on="on">
                            <v-icon :medium="$vuetify.breakpoint.width < 600" :large="$vuetify.breakpoint.width >= 600">mdi-file-settings-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Files</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-pagination
                        v-model="page"
                        :length="maxPage"
                      ></v-pagination>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="my-5"></v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="finish && !sellFinish" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>
                  Sync
                </v-progress-circular>
                <v-container fluid v-if="!loading">
                  <v-row class="title font-weight-bold white--text">
                    <v-col>{{ transactionResult.confirmations > 0 ? 'SUCCESS' : 'FAIL' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Transaction:</v-col>
                    <v-col align="left">{{
                        $device.mobile ? '...' + transaction.hash.substring(transaction.hash.length - 10) : '...' + transaction.hash.substring(transaction.hash.length - 32)
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Block:</v-col>
                    <v-col align="left">{{
                        $device.mobile ? '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 10) : '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 32)
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">From:</v-col>
                    <v-col align="left">{{
                        '...' + encodeAddress(address).substring(encodeAddress(address).length - 10)
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">To:</v-col>
                    <v-col align="left">
                      {{'...' + destination.substring(destination.length - 10) }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Asset:</v-col>
                    <v-col align="left">{{ asset.name }} ...{{ asset.hash.substring(asset.hash.length - 10) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Tx Fee:</v-col>
                    <v-col align="left">{{ (Number(amount)).toFixed(8) }} {{ getSymbol('t4g') }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" @click="back" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="sellFinish" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>
                  Sync
                </v-progress-circular>
                <v-container fluid v-if="!loading">
                  <v-col class="my-3 overline text-uppercase">
                    <div class="font-weight-bold">Share the link, so you can start receiving offers</div>
                  </v-col>
                  <v-row>
                    <v-col align-self="center" align="center" class="">
                      <!-- <v-img v-show="false" :src="image" contain width="250" height="250" class="qrcode"/> -->
                      <v-btn outlined dark block class="my-5" v-if="shareSupport" @click="share">Share Link</v-btn>
                      <v-text-field v-model="sell.url" readonly append-icon="mdi-content-copy"
                                    @click:append="copySell"/>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" @click="back" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
    <v-dialog dark max-width="100%" v-model="editDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-text-field style="max-width: 700px" v-model="asset.name" label="name"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="saveAsset">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="transferDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field style="max-width: 700px" v-model="destination" label="To" append-icon="mdi-content-paste"
                              @click:append="paste"></v-text-field>
                <v-text-field style="max-width: 700px" v-model="quantity" label="quantity" type="number" step="1" min="1" :max="asset.balance" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold title">Fee</div>
                <div class="subtitle-1">
                  {{ currentFee }}
                  A4G
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="transferAssetComplete">Transfer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="receiveDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field :rules="[value => Number(value) <= getBalance(asset.token) || 'Insufficient funds']"
                              placeholder="0" v-model="amount" label="Amount"
                              :suffix="getSymbol(asset.token)"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold title">Balance</div>
                <div class="subtitle-1">
                  {{ parseFloat(getBalance(asset.token)).toFixed(7) | numFormat('0,0.00[0000]') }}
                  {{ getSymbol(asset.token) }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold subtitle-1">Fee</div>
                <div class="subtitle-2">Box4Good
                  {{ currentFee }}
                  A4G
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="receiveTokenComplete">Transfer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="sendDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field :rules="[value => Number(value) <= asset.balance || 'Insufficient funds']" placeholder="0"
                              v-model="amount" label="Amount"
                              :suffix="getSymbol(asset.token)"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold title">Balance</div>
                <div class="subtitle-1">
                  {{ parseFloat(asset.balance).toFixed(7) | numFormat('0,0.00[0000]') }}
                  {{ getSymbol(asset.token) }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="font-weight-bold subtitle-1">Fee</div>
                <div class="subtitle-2">
                  {{ currentFee }}
                  A4G
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="sendTokenComplete">Transfer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="sellDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-tabs
          dark
          v-model="sellTab"
        >
          <v-tab href="#stab0">
            Sell
          </v-tab>
          <v-tab href="#stab1">
            Dept of Good
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="sellTab" dark>
          <v-tab-item value="stab0">
            <v-card-text align="center">
              <v-container>
                <v-row>
                  <v-col>
                    <v-select v-model="sellType" :items="['Direct']" label="Sell Type" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card v-if="sellType === 'Direct'">
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field :rules="[value => Number(value) > 0]" placeholder="0" v-model="price"
                                          label="Price"
                                          :suffix="getSymbol('t4g')"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="asset.kind == 4">
                          <v-col>
                            <v-text-field type="number" :rules="[value => Number(value) > 0 && value <= asset.assetBalance]"
                                          placeholder="0" v-model="amount" label="Quantity"
                                          suffix="Asset"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field :rules="[value => Number(value) >= 1]" v-model="sellDuration" :min="1" :max="24 * 30"
                                          type="number" label="Duration" suffix="Hours"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-textarea rows="3" v-model="sellDescription" label="Additional Description"></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="font-weight-bold subtitle-1">Fee</div>
                            <div class="subtitle-2">
                              {{ currentFee }}
                              A4G + 10% over sell price
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                    <v-card v-if="sellType === 'Bid'">
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field :rules="[value => Number(value) > 0]" placeholder="0" v-model="price"
                                          label="Start Price"
                                          :suffix="getSymbol('t4g')"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row v-if="asset.kind == 4">
                          <v-col>
                            <v-text-field type="number" :rules="[value => Number(value) > 0 && value <= asset.assetBalance]"
                                          placeholder="0" v-model="amount" label="Quantity"
                                          suffix="Asset"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field :rules="[value => Number(value) >= 1]" v-model="sellDuration" :min="1" :max="24 * 30"
                                          type="number" label="Duration" suffix="Hours"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-textarea rows="3" v-model="sellDescription" label="Additional Description"></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="font-weight-bold subtitle-1">Fee</div>
                            <div class="subtitle-2">
                              {{ currentFee }}
                              A4G + 10% over sell price
                            </div>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="stab1">
            <v-card-text align="center" class="overline">
              The publication of the asset in DeptOfGood allows you to access a larger showcase for your content, you must bear in mind that while it is in DeptOfGood you will not be able to sell directly or transfer your asset.
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="sellTab === 'stab0'" outlined @click="sellAssetComplete"
                 :disabled="!(Number(amount) > 0 && amount <= asset.assetBalance && price > 0)">Sell
          </v-btn>
          <v-btn v-if="sellTab === 'stab1'" outlined @click="publishAssetComplete">Publish</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="settingDialog" v-if="settingDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-tabs
          dark
          v-model="settingTab"
          @change="settingAsset(asset)"
          :vertical="$vuetify.breakpoint.width < 600"
        >
          <v-tab href="#stab0">
            <v-icon small dark>mdi-credit-card-outline</v-icon><span class="ml-1" v-if="$vuetify.breakpoint.width > 960">Payment</span>
          </v-tab>
          <v-tab href="#stab2">
            <v-icon small dark>mdi-piggy-bank-outline</v-icon><span class="ml-1" v-if="$vuetify.breakpoint.width > 960">Tips</span>
          </v-tab>
          <v-tab href="#stab1">
            <v-icon small dark>mdi-monitor</v-icon><span class="ml-1" v-if="$vuetify.breakpoint.width > 960">Display</span>
          </v-tab>
          <v-tab @click="$router.push({name: 'ShareBox', params: { hash: asset.hash, address: asset.address }})">
            <v-icon small dark>mdi-share-outline</v-icon><span class="ml-1" v-if="$vuetify.breakpoint.width > 960">Share</span>
          </v-tab>
          <v-spacer></v-spacer>
          <v-btn :to="'/history/' + encodeAddress(asset.address) + '/all'" class="mx-2">
            <v-icon small dark v-if="$vuetify.breakpoint.width <= 860">mdi-history</v-icon><span v-if="$vuetify.breakpoint.width > 860">Transactions History</span>
          </v-btn>
          <v-btn :disabled="!settingOnline" color="green" @click="connectToBox(asset)">
            <v-icon small dark v-if="$vuetify.breakpoint.width <= 860">mdi-connection</v-icon><span v-if="$vuetify.breakpoint.width > 860">Connect to Box4Good</span>
          </v-btn>
        <v-tabs-items v-model="settingTab" dark>
          <v-tab-item value="stab0">
            <v-card-text align="center">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field label="Site" v-model="asset.site"></v-text-field>
                    <v-select :items="countries" v-model="asset.country" item-value="code" item-text="name"
                              label="Country"></v-select>
                    <v-text-field v-if="asset.kind == 5" label="Ticket URL" v-model="asset.ticketURL"></v-text-field>
                    <div class="overline">
                      Changing the country implies a change in the currency used by the Box4Good.
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="stab2">
            <v-card-text align="center">
              <v-container>
                <v-row>
                  <v-col>
                    <div class="overline" v-if="!asset.tips">
                      There is no associated Tips Asset, one must be minted.
                    </div>
                    <v-text-field label="Hash" v-model="findTipsAsset(asset.tips).hash" readonly></v-text-field>
                    <v-text-field label="Address" v-model="asset.tips" readonly></v-text-field>
                    <v-btn v-if="!asset.tips" @click="mintTipsAsset(asset)" dark class="mt-2" color="green">Mint Tips Asset</v-btn>
                    <v-btn v-if="asset.tips" @click="mintTipsAsset(asset)" dark class="mt-2" color="green">Mint New Tips Asset</v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="stab1">
            <v-card-text align="center">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field :rules="[value => Number(value) >= 60]" type="number" min="60" placeholder="60"
                                  v-model="settingInterval" label="Interval"
                                  suffix="Seconds"></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-for="(asset, index) in supportAssets.filter(item => item.files ? item.files.length > 0 : false)" v-bind:key="index">
                  <v-col align-self="center" align="center">
                    <v-img :src="asset.preview" class="rounded-xl" max-width="150" max-height="150" height="150"
                           width="150" contain/>
                    <div align="center" class="font-weight-bold">{{ asset.name }}
                      (...{{ asset.hash.substring(asset.hash.length - 10) }})
                    </div>
                  </v-col>
                  <v-col align-self="center" align="left">
                <span>
                  <v-checkbox dense :label="file.tag" v-model="file.selected" v-for="(file, idx) in asset.files"
                              v-bind:key="idx"></v-checkbox>
                </span>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        </v-tabs>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="settingTab === 'stab1'" outlined @click="settingAssetClearComplete">Clear</v-btn>
          <v-btn outlined @click="settingAssetComplete">Save</v-btn>
          <v-btn outlined @click="settingDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="assetDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col align-self="center" align="center">
                <v-img :src="asset.preview" class="rounded-xl" contain max-width="250" max-height="250" height="250"
                       width="250"/>
                <div align="center" v-if="asset.kind == 3">
                  DAF
                </div>
                <div align="center" v-if="asset.kind == 4">
                  {{ asset.assetBalance }} of {{ asset.quantity }} Series
                </div>
              </v-col>
              <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
                <v-text-field label="Name" v-model="asset.name" readonly></v-text-field>
                <v-text-field v-if="asset.metadata" label="Location" v-model="asset.metadata.location" readonly></v-text-field>
                <v-text-field v-if="asset.metadata" label="Type" v-model="asset.metadata.type" readonly></v-text-field>
                <v-text-field v-if="asset.metadata" label="Type" v-model="asset.metadata.method" readonly></v-text-field>
                <v-text-field label="Creator" v-model="asset.creator" readonly></v-text-field>
                <v-text-field v-if="asset.kind != 5 && asset.kind != 6" label="Royalty" v-model="asset.royalty" suffix="%" readonly></v-text-field>
                <v-text-field label="Original Owner ID4Good" :value="asset.originalOwnerEncoded" readonly hide-details
                              append-icon="mdi-content-copy" @click:append="copyOwnerAsset(asset.originalOwnerEncoded)"/>
                <v-text-field v-if="asset.kind == 5" label="Ticket Validator" :value="'https://' + hostname + '/#/ticket/' + asset.verificator" readonly></v-text-field>
                <v-textarea label="Description" v-model="asset.description" rows="3" readonly></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="assetDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="assetViewDialog" transition="dialog-bottom-transition" fullscreen>
      <v-btn x-small @click="assetViewDialog = false" fixed dark outlined fab bottom right class="ma-2">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card>
        <v-container fluid class="fill-height">
          <v-row>
            <v-col align-self="center" align="center">
              <v-img v-if="assetViewDialog" :src="assetViewUrl" contain height="100%" max-height="100%" width="auto"
                     max-width="auto"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog dark max-width="100%" v-model="assetFilesDialog" transition="dialog-bottom-transition"
              content-class="ma-0 AssetsDialog">
      <v-card class="pa-5" :loading="loadingDialog">
        <v-card-text align="center">
          <v-container>
            <v-row>
              <v-col align-self="center" align="center">
                <v-img :src="asset.preview" class="rounded-xl" contain max-width="250" max-height="250" height="250"
                       width="250"/>
                <div align="center" v-if="asset.kind == 3">
                  DAF
                </div>
                <div align="center" v-if="asset.kind == 4">
                  {{ asset.assetBalance }} of {{ asset.quantity }} Series
                </div>
              </v-col>
              <v-col align-self="center" align="center" :cols="$vuetify.breakpoint.smAndDown ? 12 : 8">
                <v-container>
                  <v-row v-for="(file, index) in asset.files" v-bind:key="index">
                    <v-col align-self="center" align="center">
                      <v-text-field hide-details label="File Name" v-model="file.name" readonly></v-text-field>
                    </v-col>
                    <v-col align-self="center" align="center">
                      <v-text-field hide-details label="Tag" v-model="file.tag" readonly></v-text-field>
                    </v-col>
                    <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" align-self="center" align="center">
                      <v-btn class="mr-5" v-if="file.type.includes('image')" outlined @click="viewAssetFile(file)">
                        View
                      </v-btn>
                      <v-btn outlined @click="downloadAssetFile(file)">Download</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="assetFilesDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import './_AssetsRocarbon.scss'
import currency from '@/mixins/currency'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import Bar from '@/components/Bar/Bar'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import QRCodeStyling from 'qr-code-styling'
// import { create } from 'ipfs-http-client'

const {
  utils,
  Wallet,
  Contract
} = require('ethers')

const abi = [
  // Read-Only Functions
  'function balanceOf(address owner) view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function symbol() view returns (string)',

  // Authenticated Functions
  'function transfer(address to, uint amount) returns (boolean)',

  // Events
  'event Transfer(address indexed from, address indexed to, uint amount)'
]

export default {
  name: 'AssetsRocarbon',
  components: { Bar },
  data () {
    return {
      qr: false,
      qrCurrency: false,
      qrAsset: false,
      qrCode: null,
      assetTypes: {
        1: {
          name: 'Box4Good',
          icon: 'mdi-checkbox-intermediate'
        },
        2: {
          name: 'NFC Asset',
          icon: 'mdi-nfc-variant'
        },
        3: {
          name: 'Digital Asset',
          icon: 'mdi-cube-outline'
        },
        4: {
          name: 'Digital Assets',
          icon: 'mdi-view-grid-plus-outline'
        },
        5: {
          name: 'Digital Ticket',
          icon: 'mdi-ticket-confirmation-outline'
        },
        6: {
          name: 'Digital Ticket Remember',
          icon: 'mdi-ticket'
        },
        7: {
          name: 'RoCarbon',
          icon: 'mdi-molecule-co2'
        },
        10: {
          name: 'Tips Asset',
          icon: 'mdi-piggy-bank-outline'
        }
      },
      countries: [
        {
          code: 'US',
          name: 'United States'
        },
        {
          code: 'CA',
          name: 'Canada'
        },
        {
          code: 'BR',
          name: 'Brazil'
        },
        {
          code: 'SV',
          name: 'El Salvador'
        },
        {
          code: 'CL',
          name: 'Chile'
        }
      ],
      createItems: [
        {
          path: '/create-asset',
          name: 'DAF (Asset/NFT)'
        },
        {
          path: '/create-ticket',
          name: 'DTicket'
        },
        {
          path: '/create-address-book',
          name: 'Address Book',
          disabled: true
        },
        {
          path: '/create-name',
          name: 'DName',
          disabled: true
        },
        {
          path: '/create-rocarbon',
          name: 'RoCarbon',
          disabled: !this.$store.state.rocarbon && false
        }
      ],
      page: 1,
      maxPage: 1,
      hostname: window.location.hostname,
      finish: false,
      sellFinish: false,
      destination: '',
      main: false,
      loading: false,
      loadingDialog: false,
      snackbar: false,
      pending: [],
      asset: {
        name: '',
        hash: ''
      },
      editDialog: false,
      transferDialog: false,
      receiveDialog: false,
      sellDialog: false,
      settingDialog: false,
      settingOnline: false,
      settingInterval: 60,
      settingDisplay: {},
      settingTab: 'stab0',
      sendDialog: false,
      assetDialog: false,
      assetViewUrl: null,
      assetViewDialog: false,
      sell: null,
      image: null,
      assetFilesDialog: false,
      sellType: 'Direct',
      sellTab: 'stab0',
      sellDuration: 1,
      sellDescription: '',
      transaction: { hash: '' },
      transactionResult: { blockHash: '' },
      text: 'Paste Success',
      assets: [],
      supportAssets: [],
      amount: 1,
      quantity: 1,
      price: 0,
      search: '',
      dev: false,
      syncing: false,
      count: 0
    }
  },
  watch: {
    amount: {
      deep: true,
      handler: function () {
        this.estimateFee('t4g')
      }
    },
    destination: {
      deep: true,
      handler: function () {
        this.estimateFee('t4g')
      }
    }
  },
  mixins: [currency],
  async mounted () {
    this.loading = true
    setTimeout(() => {
      this.show()
    }, 300)
    try {
      this.assets = await this.cacheGet('assets')
      this.maxPage = Math.ceil(this.assets.length / 10)
    } catch (e) {
      this.assets = []
      this.maxPage = 1
    }
    this.getAssets(this.walletAssets, this.address)
    this.loading = false
  },
  methods: {
    show () {
      this.main = true
    },
    changePath (items) {
      this.$router.push({ path: items })
    },
    getFlag (country) {
      return getUnicodeFlagIcon(country)
    },
    showQR (currency) {
      this.qrCurrency = currency
      this.generateQRAsset(currency.address)
      this.qr = true
    },
    showQRAsset (asset) {
      this.qrAsset = asset
      this.generateQRAsset(this.encodeAddress(asset.address))
      this.qr = true
    },
    showAddress (asset) {
      this.text = this.encodeAddress(asset.address)
      navigator.clipboard.writeText(this.encodeAddress(asset.address))
      this.snackbar = true
    },
    async back () {
      this.finish = false
      this.sellFinish = false
      this.loading = true
      this.destination = null
      this.quantity = 1
      this.amount = 1
      this.price = 0
      this.getAssets(this.walletAssets, this.address)
      this.loading = false
    },
    shareSupport () {
      return navigator.share
    },
    share () {
      if (navigator.share) {
        navigator.share({
          title: 'Wallet4Good',
          url: this.sell.url
        }).then(() => {
          console.log('Thanks for sharing!')
        })
          .catch(console.error)
      } else {
        // fallback
      }
    },
    copy () {
      this.text = 'Copy Success'
      navigator.clipboard.writeText(this.url)
      this.snackbar = true
    },
    copySell () {
      this.text = 'Copy Success'
      navigator.clipboard.writeText(this.sell.url)
      this.snackbar = true
    },
    copyOwnerAsset (item) {
      this.text = 'Copy Success'
      navigator.clipboard.writeText(item)
      this.snackbar = true
    },
    paste () {
      navigator.clipboard.readText().then((address) => {
        this.destination = address
        this.text = 'Paste Success'
        this.snackbar = true
      })
    },
    editAssetName (asset) {
      this.asset = asset
      this.editDialog = true
    },
    async viewAssetFile (file) {
      this.loadingDialog = true
      const uri = this.$store.state.localBox !== false ? file.file_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : file.file_url
      const data = await this.$axios.get(uri)
      const parts = data.data
      const result = []
      parts.forEach((item) => {
        const decrypted = CryptoJS.AES.decrypt(item, this.asset.privateKey).toString(CryptoJS.enc.Utf8)
        result.push(new Uint8Array(JSON.parse(decrypted)).buffer)
      })
      const blob = new File(result, file.name, {
        type: file.type,
        lastModified: file.lastModified
      })
      this.assetViewUrl = window.URL.createObjectURL(blob)
      this.loadingDialog = false
      this.assetViewDialog = true
    },
    async downloadAssetFile (file) {
      this.loadingDialog = true
      const uri = this.$store.state.localBox !== false ? file.file_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : file.file_url
      const data = await this.$axios.get(uri)
      const parts = data.data
      const result = []
      parts.forEach((item) => {
        const decrypted = CryptoJS.AES.decrypt(item, this.asset.privateKey).toString(CryptoJS.enc.Utf8)
        result.push(new Uint8Array(JSON.parse(decrypted)).buffer)
      })
      const blob = new File(result, file.name, {
        type: file.type,
        lastModified: file.lastModified
      })
      const a = document.createElement('a')
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = file.name
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
      this.loadingDialog = false
    },
    async showAssetFiles (asset) {
      this.loadingDialog = true
      this.asset = asset
      const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + asset.pathUri)
      const metadataRaw = await this.$axios.get(url)
      const metadata = metadataRaw.data
      const imageUrl = this.$store.state.localBox !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : metadata.image_url
      const preview = await this.$axios.get(imageUrl)
      const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
      this.asset.preview = window.URL.createObjectURL(blob)
      this.asset.originalOwner = metadata.owner
      this.asset.originalOwnerEncoded = this.encodeAddress(this.asset.originalOwner)
      this.asset.creator = metadata.creator
      this.asset.royalty = metadata.royalty
      this.asset.description = metadata.description
      this.asset.files = metadata.attributes
      this.assetFilesDialog = true
      this.loadingDialog = false
    },
    async showAsset (asset) {
      this.loadingDialog = true
      this.asset = asset
      const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + asset.pathUri)
      const metadataRaw = await this.$axios.get(url)
      const metadata = metadataRaw.data
      const imageUrl = this.$store.state.localBox !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : metadata.image_url
      const preview = await this.$axios.get(imageUrl)
      const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
      this.asset.preview = window.URL.createObjectURL(blob)
      this.asset.originalOwner = metadata.owner
      this.asset.originalOwnerEncoded = this.encodeAddress(this.asset.originalOwner)
      this.asset.creator = metadata.creator
      this.asset.royalty = metadata.royalty
      this.asset.description = metadata.description
      this.assetDialog = true
      this.loadingDialog = false
    },
    async previewIcon (asset) {
      console.log(asset)
      try {
        const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + asset.pathUri)
        const metadataRaw = await this.$axios.get(url)
        const metadata = metadataRaw.data
        const imageUrl = this.$store.state.localBox !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : metadata.image_url
        const preview = await this.$axios.get(imageUrl)
        const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
        asset.previewImageUrl = window.URL.createObjectURL(blob)
        this.$forceUpdate()
        return true
      } catch (e) {
        return false
      }
    },
    connectToBox (asset) {
      this.$axios.get('https://pay4good.com/asset/setConnectHash', {
        params: {
          hash: asset.hash,
          time: 1 * 60 * 60,
          crc: Date.now()
        }
      }).then((result) => {
        this.$axios.post('https://' + asset.dns + ':9696/token', {
          token: result.data.token
        }).then((result) => {
          this.$store.state.localBox = asset.dns
        })
      })
    },
    async settingAsset (asset) {
      this.loadingDialog = true
      this.settingOnline = false
      this.asset = asset
      try {
        this.$axios.get('https://' + asset.dns + ':9696/ping', {
        }).then(() => {
          this.settingOnline = true
          this.text = 'Box4Good Detected'
          this.snackbar = true
        })
      } catch (e) {
      }
      if (this.settingTab === 'stab0') {
      }
      if (this.settingTab === 'stab1') {
        this.supportAssets = this.assets.filter(item => item.kind >= 3 && item.kind < 10)
        for (const item of this.supportAssets) {
          const result = await this.getAssetFiles(item)
          item.preview = result.preview
          item.files = result.files
        }
      }
      this.settingDialog = true
      this.loadingDialog = false
    },
    async getMetadata (pathUri) {
      try {
        const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + pathUri)
        const metadataRaw = await this.$axios.get(url)
        return metadataRaw.data
      } catch (e) {
        return false
      }
    },
    async getAssetFiles (asset) {
      console.log('get!')
      console.log(asset)
      const url = this.$store.state.localBox !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : ('https://asset4good.com/' + asset.pathUri)
      const metadataRaw = await this.$axios.get(url)
      const metadata = metadataRaw.data
      const imageUrl = this.$store.state.localBox !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL).replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : metadata.image_url
      const preview = await this.$axios.get(imageUrl)
      const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
      const result = {
        preview: window.URL.createObjectURL(blob),
        files: metadata.attributes.filter(item => item.type.includes('image'))
      }
      return result
    },
    async settingAssetComplete () {
      this.loadingDialog = true
      if (this.settingTab === 'stab0') {
        await this.$axios.get('https://pay4good.com/asset/setAssetSite', {
          params: {
            hash: this.asset.hash,
            address: this.asset.address,
            site: this.asset.site,
            ticket: this.asset.ticketURL,
            crc: Date.now()
          }
        })
        await this.$axios.get('https://pay4good.com/asset/setAssetCountry', {
          params: {
            hash: this.asset.hash,
            address: this.asset.address,
            country: this.asset.country,
            crc: Date.now()
          }
        })
        this.loading = true
        this.getAssets(this.walletAssets, this.address)
        this.loading = false
      }
      if (this.settingTab === 'stab1') {
        const display = {}
        display.interval = this.settingInterval
        display.update = moment().unix()
        display.items = []
        for (const item of this.supportAssets) {
          for (const file of item.files) {
            if (file.selected) {
              display.items.push({
                content: file.file_url,
                type: file.type,
                hash: item.hash
              })
            }
          }
        }
        await this.$axios.get('https://pay4good.com/asset/setDisplay', {
          params: {
            hash: this.asset.hash,
            address: this.address,
            display: display,
            crc: Date.now()
          }
        })
      }
      this.loadingDialog = false
      this.settingDialog = false
    },
    async settingAssetClearComplete () {
      this.loadingDialog = true
      const display = {}
      display.update = moment().unix()
      display.items = false
      await this.$axios.get('https://pay4good.com/asset/setDisplay', {
        params: {
          hash: this.asset.hash,
          address: this.address,
          display: display,
          crc: Date.now()
        }
      })
      this.loadingDialog = false
      this.settingDialog = false
    },
    sendToken (asset) {
      this.amount = asset.balance
      this.asset = asset
      this.destination = this.wallet.getAddress()
      this.sendDialog = true
    },
    async sendTokenComplete () {
      this.loading = true
      this.finish = true
      this.sendDialog = false
      const tx = {}
      tx.value = utils.parseEther(this.amount.toString())
      this.transaction = await this.asset.wallet.transfer(this.destination, tx.value)
      this.wallet.provider.once(this.transaction.hash, (transaction) => {
        this.transactionResult = transaction
        this.loading = false
      })
    },
    receiveToken (asset) {
      this.amount = 0
      this.asset = asset
      this.destination = this.asset.address
      this.receiveDialog = true
    },
    async receiveTokenComplete () {
      this.loading = true
      this.finish = true
      this.receiveDialog = false
      const tx = {}
      tx.value = utils.parseEther(this.amount.toString())
      this.transaction = await this.walletToken.USD.transfer(this.destination, tx.value)
      tx.from = await this.wallet.getAddress()
      tx.to = this.destination
      tx.value = utils.parseEther((this.amount * this.currentFee * 100).toFixed(18).toString())
      await this.wallet.signTransaction(tx)
      await this.wallet.sendTransaction(tx)
      this.wallet.provider.once(this.transaction.hash, (transaction) => {
        this.transactionResult = transaction
        this.loading = false
      })
    },
    sellAsset (asset) {
      this.amount = 1
      this.asset = asset
      this.destination = process.env.VUE_APP_CORE_ADDRESS
      this.sellDialog = true
    },
    async sellAssetComplete () {
      this.loading = true
      this.sellDialog = false
      await this.walletAssets.setApprovalForAll(process.env.VUE_APP_CORE_ADDRESS, true)
      const metadataRaw = await this.$axios.get('https://asset4good.com/' + this.asset.pathUri)
      const metadata = metadataRaw.data
      const sell = await this.$axios.get('https://pay4good.com/asset/createSell', {
        params: {
          address: this.address,
          hash: this.asset.hash,
          type: this.sellType,
          price: this.price,
          amount: this.amount,
          preview: metadata.image_url,
          royalty: metadata.royalty,
          originalOwner: metadata.owner,
          duration: this.sellDuration,
          description: this.sellDescription,
          crc: Date.now()
        }
      })
      this.sell = sell.data
      await this.generateQR()
      this.loading = false
      this.finish = true
      this.sellFinish = true
    },
    async publishAssetComplete () {
      this.loading = true
      this.sellDialog = false
      await this.walletAssets.setApprovalForAll(process.env.VUE_APP_CORE_ADDRESS, true)
      await this.$axios.get('https://deptofgood-bloqs4good.web.app/api/assets/registerAsset', {
        params: {
          identity: this.$store.state.identity,
          hash: this.asset.hash,
          cid: this.asset.pathUri
        }
      })
      await this.$axios.get('https://pay4good.com/asset/setAssetPublish', {
        params: {
          address: this.asset.address,
          hash: this.asset.hash,
          publish: true,
          crc: Date.now()
        }
      })
      this.loading = false
      this.text = 'Publish Success'
      this.snackbar = true
    },
    transferAsset (asset) {
      this.amount = 1
      this.asset = asset
      this.transferDialog = true
    },
    async transferAssetComplete () {
      this.loading = true
      this.finish = true
      this.transferDialog = false
      this.transaction = await this.walletAssets.transferAsset(this.address, this.decodeAddress(this.destination), this.quantity, this.asset.id)
      this.wallet.provider.once(this.transaction.hash, (transaction) => {
        this.transactionResult = transaction
        this.loading = false
      })
    },
    async saveAsset () {
      this.loadingDialog = true
      await this.$axios.get('https://pay4good.com/asset/setAssetName', {
        params: {
          address: this.asset.address,
          hash: this.asset.hash,
          name: this.asset.name,
          crc: Date.now()
        }
      })
      this.editDialog = false
      this.loadingDialog = false
    },
    async generateQR () {
      const qrcode = new QRCodeStyling({
        width: 250,
        height: 250,
        margin: 4,
        data: this.sell.url,
        image: require('@/assets/logo.png'),
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#3D81c2'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })
      await qrcode._canvasDrawingPromise
      this.image = await qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    async generateQRAsset (item) {
      const qrcode = new QRCodeStyling({
        width: 300,
        height: 300,
        margin: 4,
        data: item,
        image: require('@/assets/logo.svg'),
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#3D81c2'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })
      await qrcode._canvasDrawingPromise
      this.qrCode = await qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    async getAssets (wallet, address) {
      this.syncing = true
      const storeAssets = (await wallet.getAssetsByOwner(address))
      const assets = []
      this.count = 0
      // Retraive Pending assets
      this.$axios.get('https://pay4good.com/asset/getPendingAssets', {
        params: {
          owner: this.address,
          valid: new Date(),
          crc: Date.now()
        }
      }).then(result => {
        this.pending = result.data
      })
      for (const asset of storeAssets) {
        if (asset.kind.toString() !== '7') {
          continue
        }
        this.count++
        this.getAsset(wallet, address, assets, asset)
      }
    },
    async getAsset (wallet, address, assets, asset) {
      const id = (await wallet.getIndexOfByHash(address, asset.assetHash))
      const assetBalance = await wallet.balanceOf(address, id)
      if (assetBalance === 0) {
        this.count--
        console.log(this.count)
        if (this.count === 0) {
          this.syncing = false
          this.assets = assets
          this.maxPage = Math.ceil(this.assets.length / 10)
          await this.cacheSet('assets', assets)
          for (const asset of assets) {
            this.previewIcon(asset)
          }
        }
        return
      }
      const assetWallet = new Wallet(asset.assetPK, this.wallet.provider)
      let attr = false
      let contract = process.env.VUE_APP_CONTRACT
      let token = 't4g'
      try {
        attr = await this.$axios.get('https://pay4good.com/asset/getAssetAttr', {
          params: {
            hash: asset.assetHash,
            address: await assetWallet.getAddress(),
            version: process.env.VUE_APP_VERSION,
            crc: Date.now()
          }
        })
        if (attr.data.country === 'US') {
          contract = process.env.VUE_APP_CONTRACT
          token = 't4g'
        }
        if (attr.data.country === 'SV') {
          contract = process.env.VUE_APP_CONTRACT
          token = 't4g'
        }
        if (attr.data.country === 'CA') {
          contract = process.env.VUE_APP_CONTRACT_CAD
          token = 't4gcad'
        }
        if (attr.data.country === 'BR') {
          contract = process.env.VUE_APP_CONTRACT_BRL
          token = 't4gbrl'
        }
        if (attr.data.country === 'CL') {
          contract = process.env.VUE_APP_CONTRACT_CLP
          token = 't4gclp'
        }
        if (asset.kind.toString() === '7') {
          contract = process.env.VUE_APP_CONTRACT_ROCT
          token = 'roct'
        }
      } catch (e) {
      }
      const assetWalletToken = new Contract(contract, abi, assetWallet)
      const balance = utils.formatUnits(await assetWalletToken.balanceOf(assetWallet.getAddress()))

      let sellStatus = null
      try {
        sellStatus = await this.$axios.get('https://pay4good.com/asset/getSellByHash', {
          params: {
            hash: asset.assetHash,
            owner: this.address,
            crc: Date.now()
          }
        })
      } catch (e) {
        sellStatus = null
      }
      assets.push({
        id: id,
        wallet: assetWalletToken,
        hash: asset.assetHash,
        kind: asset.kind.toString(),
        pathUri: asset.pathUri.toString(),
        assetBalance: assetBalance.toString(),
        balance: balance.toString(),
        quantity: asset.quantity.toString(),
        address: await assetWallet.getAddress(),
        privateKey: asset.assetPK,
        owner: asset.owner,
        token: token,
        contract: contract,
        country: attr ? attr.data.country : null,
        verificator: attr ? attr.data.verificator : null,
        site: attr ? attr.data.site : null,
        name: attr ? attr.data.name : '-',
        ticketURL: attr ? attr.data.ticketURL ? attr.data.ticketURL : null : null,
        ip: attr ? attr.data.ip : false,
        tips: attr ? attr.data.tips : false,
        publish: attr ? attr.data.publish === 'true' : false,
        dns: asset.assetHash.substring(asset.assetHash.length - 16) + '.box4good.com',
        sellStatus: !!sellStatus.data,
        sell: sellStatus.data,
        metadata: await this.getMetadata(asset.pathUri)
      })
      this.count--
      console.log(this.count)
      if (this.count === 0) {
        this.assets = assets
        this.maxPage = Math.ceil(this.assets.length / 10)
        await this.updateTipsAssets()
        this.syncing = false
        await this.cacheSet('assets', assets)
        for (const asset of assets) {
          this.previewIcon(asset)
        }
      }
    },
    findTipsAsset (address) {
      const tip = this.assets.find(item => item.address === address)
      return tip || { hash: '', address: '' }
    },
    mintTipsAsset (asset) {
      this.loadingDialog = true
      this.$axios.get('https://pay4good.com/asset/mintTipsAsset', {
        params: {
          hash: asset.hash,
          address: this.address,
          crc: Date.now()
        }
      }).then(result => {
        this.loadingDialog = false
        this.loading = true
        this.getAssets(this.walletAssets, this.address)
        this.loading = false
      })
    },
    async updateTipsAssets () {
      for (const asset of this.assets) {
        if (asset.kind) {
          if (asset.tips) {
            const assetFound = this.assets.find(item => item.address === asset.tips)
            if (!assetFound) {
              continue
            }
            console.log(assetFound)
            console.log('tips!!!')
            const assetWallet = new Wallet(assetFound.privateKey, this.wallet.provider)
            console.log(asset)
            const assetWalletToken = new Contract(asset.contract, abi, assetWallet)
            const balance = utils.formatUnits(await assetWalletToken.balanceOf(assetWallet.getAddress()))
            assetFound.country = asset.country
            assetFound.token = asset.token
            assetFound.wallet = assetWalletToken
            assetFound.balance = balance.toString()
          }
        }
      }
    }
  }
}
</script>
