<template>
  <div class="CreateHardwareWalletConnector">
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="finish && !stack" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-container fluid v-if="!loading">
                  <v-row class="title font-weight-bold white--text">
                    <v-col>{{ transactionResult.confirmations > 0 ? 'SUCCESS' : 'FAIL' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Transaction:</v-col>
                    <v-col align="left">{{ $device.mobile ? '...' + transactionResult.transactionHash.substring(transactionResult.transactionHash.length - 10) : '...' + transactionResult.transactionHash.substring(transactionResult.transactionHash.length - 32) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Block:</v-col>
                    <v-col align="left">{{ $device.mobile ? '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 10) : '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 32) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">From:</v-col>
                    <v-col align="left">{{ $device.mobile ? '...' + encodeAddress(address).substring(encodeAddress(address).length - 10) : encodeAddress(address) }}</v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Asset:</v-col>
                    <v-col align="left">{{ name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" to="/assets" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="finish && stack" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-container fluid v-if="!loading">
                  <v-row class="title font-weight-bold white--text">
                    <v-col>IN PROCESS</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Transaction:</v-col>
                    <v-col align="left">pending</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Block:</v-col>
                    <v-col align="left">pending</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">From:</v-col>
                    <v-col align="left">{{ '...' + encodeAddress(address).substring(encodeAddress(address).length - 10) }}</v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Asset:</v-col>
                    <v-col align="left">{{ name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" to="/assets" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="main && !finish && ready" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid>
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10 mb-2" v-if="loading" size="150" color="white" width="10" indeterminate>Sync
                </v-progress-circular>
                <v-progress-linear
                  v-if="loading"
                  v-model="process"
                  striped
                  color="white"
                  height="30"
                  class="my-5"
                >
                  <template v-slot:default="{ }">
                    <strong class="black--text">{{ processText }}</strong>
                  </template>
                </v-progress-linear>
                <v-container v-if="!loading" fluid class="fill-height" style="max-width: 700px">
                  <v-row>
                    <v-col>
                      <v-img :src="preview" class="rounded-xl" contain max-width="250" max-height="250" height="250" width="250"></v-img>
                      <v-alert
                        v-if="this.Tokens[this.selecttoken].symbol != 'BTC' && this.Tokens[this.selecttoken].symbol != 'ETH'"
                        shaped
                        color="#272727"
                        type="info"
                        dismissible
                      >Self-Custody DeFi Wallet: The private keys of your assets are encrypted inside of your ID4Good. Your keys, your coins.</v-alert>
                         <v-alert
                           dismissible
                           v-show="hardwarewalletaddress.length === 0 && btnmessage.length != 10"
                            shaped
                            color="#272727"
                            type="info"
                          >Before proceeding, please connect and unlock your device, then open a app of your choice. e.g. ETH, BTC, ID4Good. (Only Chrome in Desktop is Supported)</v-alert>

                      <v-select dense :items="Devices" v-model="selectdevice" item-value="id" item-text="name"
                                label="Device *"
                                @change="updateChannel"
                                :disabled="hardwarewalletaddress.length > 0"
                                ></v-select>
                      <v-select dense :items="Channels" v-model="selectchannel" item-value="id" item-text="name"
                                label="Channel *"
                                :disabled="selectdevice === 0 || hardwarewalletaddress.length > 0"
                                ></v-select>
                      <v-alert
                        dismissible
                        v-show="selectchannel === 1 && hardwarewalletaddress.length === 0"
                        shaped
                        type="warning"
                      >(Experimental) Only Available with Google Chrome Beta, the following experimental flags must be enable #enable-experimental-web-platform-feature and #enable-web-bluetooth-new-permissions-backend</v-alert>
                      <v-select dense :items="Tokens" v-model="selecttoken" item-value="id" item-text="name"
                                label="Token *"
                                @change="updateSelectBTCFormat"
                                :disabled="hardwarewalletaddress.length > 0"
                      ></v-select>
                      <v-select dense :items="BTCFormats" v-model="selectBTCformat" item-value="index" item-text="name"
                                label="Bitcoin Wallet Address Format *"
                                @change="updateConnectorName"
                                :disabled="hardwarewalletaddress.length > 0"
                                v-if="this.Tokens[this.selecttoken].symbol === 'BTC'"
                      ></v-select>
                      <v-text-field :rules="[value => (Number(value) <= 65535 && Number(value) >= 0) || 'Address index required range 0 - 65535']"
                                    label="Address Index (Optional)"
                                    :disabled="hardwarewalletaddress.length > 0"
                                    v-model="addressindex"
                                    @change="updateConnectorName"
                                    type="number" step="1" min="0" max="65535"
                                    dense outlined dark/>
                      <v-slider
                        v-model="addressindex"
                        :disabled="hardwarewalletaddress.length > 0"
                        @change="updateConnectorName"
                        class="align-center"
                        :max="65535"
                        :min="0"
                        :step="1"
                      >
                      </v-slider>
                      <v-alert
                        dismissible
                        v-show="this.Tokens[this.selecttoken].symbol === 'BTC'"
                        shaped
                        type="info"
                      >{{ this.BTCFormats[this.selectBTCformat].desc }}</v-alert>
                      <v-text-field v-if="this.Tokens[this.selecttoken].symbol === 'wA4G'" disabled :rules="[value => value.length > 0 || 'Smart Contract Address required']" v-model="smartcontractaddress" dense label="Smart Contract Address (ERC20)" outlined dark/>

                      <v-btn dark v-show="hardwarewalletaddress.length === 0 && btnmessage.length != 10" class="mt-1 mb-2" @click="GetHardwareWalletAddress(false)">{{ btnmessage }}</v-btn>
                      <v-alert
                        dismissible
                        v-show="btnmessage.length === 10 && !this.verify && !this.verified"
                        shaped
                        color="#272727"
                        type="info"
                      >{{ btnmessage }}</v-alert>
                      <v-alert
                        dismissible
                        v-show="hardwarewalletaddress.length > 0 && !this.verify && !this.verified"
                        shaped
                        type="info"
                      >{{ btnmessage }}</v-alert>
                      <v-alert
                        dismissible
                        v-show="hardwarewalletaddress.length > 0 && this.verified"
                        shaped
                        type="success"
                      >{{ btnmessage }}</v-alert>
                      <v-alert
                        dismissible
                        v-show="errorunlock != 0 && !this.verify && !this.verified"
                        shaped
                        type="error"
                      >The device must be connected, unlock the device and open the required App for the selected Token {{ this.Tokens[this.selecttoken].symbol }} : {{ errorunlock }}</v-alert>
                      <v-text-field :rules="[value => value.length > 0 || 'Hardware Wallet Address required']" v-model="hardwarewalletaddress" dense label="ID4Good Wallet Address" disabled outlined dark/>
                      <v-btn dark v-show="hardwarewalletaddress.length > 0 && !this.verified" class="mt-1 mb-2" @click="GetHardwareWalletAddress(true)">Verify (Optional)</v-btn>
                     <!-- <v-text-field v-if="decodedaddress && this.verify" :value="decodedaddress" dense label="Wallet Address on Nano Device" disabled outlined dark/> -->
                      <v-alert
                        dismissible
                        v-show="decodedaddress && this.verify && !this.verified"
                        shaped
                        type="info"
                      >Please verify the following wallet address on your device: {{ decodedaddress }}</v-alert>
                      <v-alert
                        dismissible
                        v-show="!decodedaddress && this.verify && !this.verified"
                        shaped
                        type="info"
                      >Please verify the following wallet address on your device: {{ hardwarewalletaddress }}</v-alert>
                      <v-alert
                        dismissible
                        v-show="errorunlock != 0 && this.verify && !this.verified"
                        shaped
                        type="error"
                      > {{ this.Tokens[this.selecttoken].symbol }} : {{ errorunlock }}</v-alert>
                      <v-text-field :rules="[value => value.length >= 4 && value.length < 51 || 'Connector Name required 4 - 50']" v-model="connectorname" dense label="Connector Name *" outlined dark/>
                      <v-text-field
                        step="1"
                        min="1"
                        max="256"
                        :rules="[value => (Number(value) <= 256 && Number(value) > 0) || 'Quantity required range 1 - 256']"
                        v-model="quantity"
                        type="number"
                        dense
                        label="Quantity"
                        outlined dark/>
                      <v-slider
                        v-model="quantity"
                        class="align-center"
                        :max="256"
                        :min="1"
                        :step="1"
                      >
                      </v-slider>
                    </v-col>
                  </v-row>

                  <v-alert
                    dismissible
                    shaped
                    type="info"
                    color="#272727"
                  >Estimated Cost: {{ (currentFee).toFixed(7) | numFormat('0,0.00[00]') }} {{ getSymbol('t4g') }}</v-alert>

                  <v-alert
                    dismissible
                    v-show="balanceToken.USD < (currentFee).toFixed(7)"
                    shaped
                    type="error"
                  >Insufficient Balance of {{ getSymbol('t4g') }}</v-alert>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-btn class="mt-10" @click="preCreateDigitalAsset" dark block :disabled="balanceToken.USD < (currentFee).toFixed(7) || connectorname.length === 0 || hardwarewalletaddress.length === 0 || this.Tokens[this.selecttoken].symbol.length === 0 || this.files.find(item => item.unique === false)">Create Connector</v-btn>
                      <v-btn class="mt-1 mb-10" to="/assets" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
    <v-dialog fullscreen v-model="editPreview" v-if="editPreview" content-class="CreateHardwareWalletConnector">
      <v-container fluid class="fill-height dialog-profile">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="editor-box">
              <PinturaEditor
                v-bind="editorDefaults"
                :src="preview"
                :imageCropAspectRatio="1"
                @pintura:process="finishPreview"
              ></PinturaEditor>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>
import './_CreateHardwareWalletConnector.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'
import CryptoJS from 'crypto-js'
import { create } from 'ipfs-http-client'
import { utils } from 'ethers'
import { getEditorDefaults, createDefaultImageWriter } from 'pintura'
import { PinturaEditor } from 'vue-pintura'
// import { LedgerSigner } from '@ethersproject/hardware-wallets'
// import { listen } from '@ledgerhq/logs'
import AppBtc from '@ledgerhq/hw-app-btc'
import AppEth from '@ledgerhq/hw-app-eth'
// Keep this import if you want to use a Ledger Nano S/X with the USB protocol and delete the @ledgerhq/hw-transport-webhid import
import TransportWebUSB from '@ledgerhq/hw-transport-webusb'
import TransportWebBle from '@ledgerhq/hw-transport-web-ble'
// import TransportWebBle from '@types/web-bluetooth'

const chunkSize = 1024 * 1024

export default {
  name: 'CreateHardwareWalletConnector',
  components: { Bar, PinturaEditor },
  mixins: [currency],
  data () {
    return {
      main: false,
      loading: false,
      currentFee: 1,
      snackbar: false,
      editPreview: false,
      editorDefaults: getEditorDefaults(),
      btnmessage: 'get wallet address',
      name: '',
      hardwarewalletaddress: '',
      decodedaddress: false,
      selectdevice: 0,
      selectchannel: 0,
      selectBTCformat: 2,
      selecttoken: 0,
      addressindex: 0,
      selectpath: '',
      verified: false,
      selectformat: '',
      connectorname: 'A4G Nano S 0',
      quantity: 1,
      verify: false,
      smartcontractaddress: '',
      errorunlock: 0,
      transport: '',
      appWallet: '',
      Devices: [
        {
          name: 'Ledger: Nano S',
          shortName: 'Nano S',
          id: 0
        },
        {
          name: 'Ledger: Nano X',
          shortName: 'Nano X',
          id: 1
        },
        {
          name: 'Ledger: Nano S Plus',
          shortName: 'Nano S Plus',
          id: 2
        },
        {
          name: 'Android (SmartPhone)',
          disabled: true
        },
        {
          name: 'iOS (iPhone, iPad)',
          disabled: true
        },
        {
          name: 'Wearable',
          disabled: true
        },
        {
          name: 'Tag',
          disabled: true
        }
      ],
      Channels: [
        {
          name: 'USB',
          id: 0

        },
        {
          name: 'Bluetooth',
          id: 1
        }
      ],
      Tokens: [
        {
          name: 'Apta4Good',
          symbol: 'A4G',
          id: 0,
          path: "44'/161803'/0'/0/"
          // path: "44'/60'/0'/0/0"
        },
        {
          name: 'Token4Good USD',
          symbol: 'T4G-USD',
          id: 1,
          path: "44'/161803'/0'/0/"
        },
        {
          name: 'Bitcoin',
          symbol: 'BTC',
          id: 2
        },
        {
          name: 'Ethereum',
          symbol: 'ETH',
          id: 3,
          path: "44'/60'/0'/0/"
        },
        {
          name: 'Token4Good CAD',
          symbol: 'T4G-CAD',
          path: "44'/161803'/0'/0/",
          id: 4
        },
        {
          name: 'Token4Good BRL',
          symbol: 'T4G-BRL',
          id: 5,
          path: "44'/161803'/0'/0/"
        },
        {
          name: 'Token4Good CLP',
          symbol: 'T4G-CLP',
          id: 6,
          path: "44'/161803'/0'/0/"
        },
        {
          name: 'Good Bitcoin',
          symbol: 'gBTC',
          id: 7,
          path: "44'/161803'/0'/0/",
          disabled: true
        },
        {
          name: 'Good Ethereum',
          symbol: 'gETH',
          id: 8,
          path: "44'/161803'/0'/0/",
          disabled: true
        },
        {
          name: 'Wrapped A4G (ERC20)',
          symbol: 'wA4G',
          path: "44'/60'/0'/0/",
          smartcontract: '',
          id: 9,
          disabled: true
        },
        {
          name: 'Tether',
          symbol: 'USDT',
          smartcontract: '',
          id: 10,
          disabled: true
        },
        {
          name: 'Binance Coin',
          symbol: 'BNB',
          id: 11,
          disabled: true
        },
        {
          name: 'U.S. Dollar Coin',
          symbol: 'USDC',
          id: 12,
          disabled: true
        },
        {
          name: 'Cardano',
          symbol: 'ADA',
          id: 13,
          disabled: true
        },
        {
          name: 'Solana',
          symbol: 'SOL',
          id: 14,
          disabled: true
        },
        {
          name: 'Ripple',
          symbol: 'XRP',
          id: 15,
          disabled: true
        },
        {
          name: 'Polkadot',
          symbol: 'DOT',
          id: 16,
          disabled: true
        },
        {
          name: 'Avalanche',
          symbol: 'AVAX',
          id: 17,
          disabled: true
        }
      ],
      BTCFormats: [
        {
          name: 'Taproot (2021) (Experimental)',
          index: 0,
          shortName: 'Taproot',
          desc: 'Taproot (2021) starts with a bc1p... (length 62 characters)',
          format: 'bech32m',
          path: "86'/0'/0'/0/"
        },
        {
          name: 'Legacy (2010)',
          shortName: 'Legacy',
          index: 1,
          desc: 'Legacy (2010) starts with a 1 e.g. 16mV... (length 34 characters)',
          format: 'legacy',
          path: "44'/0'/0'/0/"
        },
        {
          name: 'Native Segwit (2018) (Recommended)',
          shortName: 'Native Segwit',
          index: 2,
          desc: 'Native Segwit (2018) starts with a bc1q... (length 42 characters)',
          format: 'bech32',
          path: "84'/0'/0'/0/"
        },
        {
          name: 'Legacy Wrapped Segwit (2017)',
          index: 3,
          shortName: 'Legacy Segwit',
          desc: 'Legacy Wrapped Segwit (2017) starts with a 3 e.g. 39vL... (length 34 characters)',
          format: 'p2sh',
          path: "49'/0'/0'/0/"
        }],
      track: null,
      files: [],
      filePreview: {
        file: null,
        tag: '_preview',
        cid: ''
      },
      filesEncrypted: {},
      process: 0,
      processText: '',
      count: 0,
      size: 0,
      stack: false,
      progressSize: 0,
      transactionResult: { blockHash: '' },
      finish: false,
      preview: require('@/assets/ledger.webp'),
      text: 'Copy Success'
    }
  },
  watch: {
    royalty: {
      deep: true,
      handler: function (value) {
        this.royalty = Math.round(value)
      }
    }
  },
  async mounted () {
    this.editorDefaults.imageWriter = createDefaultImageWriter({
      targetSize: {
        width: 250,
        height: 250,
        upscale: true
      }
    })
    const response = await fetch(this.preview)
    const data = await response.blob()
    this.filePreview.file = new File([data], 'preview.webp', { type: '' })
    setTimeout(() => {
      this.show()
    }, 300)
  },
  methods: {
    updateChannel () {
      if (this.selectdevice === 0) {
        this.selectchannel = 0
      }
      this.updateConnectorName()
    },
    updateSelectBTCFormat () {
      if (this.Tokens[this.selecttoken].symbol === 'BTC') {
        this.selectBTCformat = 2
      }
      this.updateConnectorName()
    },
    updateConnectorName () {
      if (this.Tokens[this.selecttoken].symbol === 'BTC') {
        this.connectorname = this.Tokens[this.selecttoken].symbol + ' ' + this.Devices[this.selectdevice].shortName + ' ' + this.BTCFormats[this.selectBTCformat].shortName + ' ' + this.addressindex
        this.errorunlock = 0
      } else {
        this.connectorname = this.Tokens[this.selecttoken].symbol + ' ' + this.Devices[this.selectdevice].shortName + ' ' + this.addressindex
        this.errorunlock = 0
      }
    },
    addFile () {
      // console.log('add')
      this.files.push({
        file: null,
        tag: null,
        cid: null
      })
      this.$forceUpdate()
    },
    async show () {
      this.main = true
    },
    getFlag (country) {
      return getUnicodeFlagIcon(country)
    },
    async GetHardwareWalletAddress (verify) {
      this.verify = verify
      this.errorunlock = 0
      this.btnmessage = 'Connecting'

      try {
        // trying to connect to your Ledger device with USB or Bluetooth protocol
        switch (this.selectchannel) {
          case 0:
            this.btnmessage = 'Connecting by USB, Unlock Device'
            this.transport = await TransportWebUSB.create()
            break

          case 1:
            this.btnmessage = 'Connecting by Bluetooth, Pair and Unlock Device'
            this.transport = await TransportWebBle.create()
            break
        }
        if (verify) {
          this.btnmessage = 'verifying'
        }
        if (this.Tokens[this.selecttoken].symbol === 'BTC') {
          this.selectpath = this.BTCFormats[this.selectBTCformat].path + this.addressindex
          this.btnmessage = 'Getting ' + this.Tokens[this.selecttoken].symbol + ' ' + this.BTCFormats[this.selectBTCformat].shortName + ' ' + 'Wallet Address'
          this.appWallet = new AppBtc(this.transport)
          const { bitcoinAddress } = await this.appWallet.getWalletPublicKey(
            this.selectpath,
            { verify: verify, format: this.BTCFormats[this.selectBTCformat].format }
          )
          /*
          * Taproot (2021) starts with a bc1p... (length 62 characters) | (BIP86, P2TR, Bench32m) format: bech32m path: 86'
          * Native Segwit (2018) starts with a bc1q... (length 42 characters) | (BIP84, P2WPKH, Bench32) format: bech32, path: 84'
          * Legacy Wrapped Segwit (2017) starts with a 3 e.g. 39vL... (length 34 characters) | (BIP49, P2PKH-P2WPKH, Base 58) format: p2sh, path: 49'
          * Legacy (2010) starts with a 1 e.g. 16mV... (length 34 characters) | (BIP44, P2PKH, Base 58), format: legacy , path: 44'
          * */
          this.hardwarewalletaddress = bitcoinAddress
          if (this.verify) {
            this.btnmessage = this.Tokens[this.selecttoken].symbol + ' ' + this.BTCFormats[this.selectBTCformat].shortName + ' Wallet Address Verified'
            this.verified = true
          } else {
            this.btnmessage = this.Tokens[this.selecttoken].symbol + ' ' + this.BTCFormats[this.selectBTCformat].shortName + ' Wallet Address Retrieved'
          }
        }
        if (this.Tokens[this.selecttoken].symbol === 'ETH') {
          this.selectpath = this.Tokens[this.selecttoken].path + this.addressindex
          this.btnmessage = 'Getting ' + this.Tokens[this.selecttoken].symbol + ' Wallet Address'
          this.appWallet = new AppEth(this.transport)
          const { address } = await this.appWallet.getAddress(
            this.selectpath, verify
          )
          this.hardwarewalletaddress = address
          if (this.verify) {
            this.btnmessage = this.Tokens[this.selecttoken].symbol + ' Wallet Address Verified'
            this.verified = true
          } else {
            this.btnmessage = this.Tokens[this.selecttoken].symbol + ' Wallet Address Retrieved'
          }
        }
        if (this.Tokens[this.selecttoken].symbol === 'A4G' || this.Tokens[this.selecttoken].symbol === 'T4G-USD' || this.Tokens[this.selecttoken].symbol === 'T4G-CAD' || this.Tokens[this.selecttoken].symbol === 'T4G-CLP' || this.Tokens[this.selecttoken].symbol === 'T4G-BRL' || this.Tokens[this.selecttoken].symbol === 'gBTC' || this.Tokens[this.selecttoken].symbol === 'gETH') {
          this.selectpath = this.Tokens[this.selecttoken].path + this.addressindex
          this.btnmessage = 'Getting ' + this.Tokens[this.selecttoken].symbol + ' Wallet Address'
          this.appWallet = new AppEth(this.transport)
          const { address } = await this.appWallet.getAddress(
            this.selectpath, verify
          )
          this.decodedaddress = address
          this.hardwarewalletaddress = this.encodeAddress(address)
          if (this.verify) {
            this.btnmessage = this.Tokens[this.selecttoken].symbol + ' Wallet Address Verified'
            this.verified = true
          } else {
            this.btnmessage = this.Tokens[this.selecttoken].symbol + ' Wallet Address Retrieved'
          }
        }
        // console.log('select path: ' + this.selectpath)
        this.verify = false
      } catch (error) {
      // Catch any error thrown and displays it on the screen
        if (verify) {
          this.verified = false
        }
        this.btnmessage = 'get wallet address'
        this.errorunlock = error.message
      }
    },
    async calculateFee (e, index) {
      // console.log(e)
      if (e === null) {
        return
      }
      if (e.size > 20 * 1024 * 1024 && !this.$store.state.localBox) {
        this.text = 'Files over 20MB require a Box4Good'
        this.snackbar = true
        setTimeout(() => {
          this.files[index].file = null
          this.$forceUpdate()
        }, 500)
        return
      }

      this.currentFee = 1
      let size = 0
      for (const file of this.files) {
        size += file.file.size
      }
      this.size = size
      if (size > 50 * 1024 * 1024) {
        this.currentFee += 1 + 0.020 * size / 1024 / 1024
        this.text = 'The files size generates a cost of ' + this.currentFee.toFixed(4)
        this.snackbar = true
      }
      const reader = new FileReader()
      reader.onloadend = async (event) => {
        // console.log(this.files)
        const md5 = CryptoJS.MD5(event.target.result).toString()
        this.files[index].md5 = md5
        this.$axios.get('https://pay4good.com/asset/verifyUniqueDigitalAsset', {
          params: {
            md5: md5,
            valid: new Date(),
            crc: Date.now()
          }
        }).then((file) => {
          // console.log(file)
          this.files[index].unique = false
          this.$forceUpdate()
        }).catch(() => {
          // console.log('unique')
          this.files[index].unique = true
          this.$forceUpdate()
        })
        // console.log(this.files)
      }
      setTimeout(() => {
        reader.readAsBinaryString(e)
      }, 500)
    },
    async finishPreview (result) {
      // console.log(result.dest)
      this.filePreview.file = result.dest
      this.preview = URL.createObjectURL(result.dest)
      this.editPreview = false
    },
    async previewImage (e) {
      const file = e.target.files[0]
      if (file.size > 5 * 1024 * 1024) {
        this.text = '5MB limit supported'
        this.snackbar = true
        return
      }
      const url = URL.createObjectURL(file)
      // const img = new Image()
      // img.src = url
      // await img.decode()
      this.filePreview.file = file
      this.preview = url
      this.editPreview = true
    },
    async preCreateDigitalAsset () {
      this.loading = true
      this.processText = 'Checking...'
      let size = 0
      for (const file of this.files) {
        size += file.file.size
      }

      this.walletToken.USD.approve(process.env.VUE_APP_CORE_ADDRESS, utils.parseEther('500'))

      const track = await this.$axios.get('https://pay4good.com/asset/preCreateDigitalAsset', {
        params: {
          owner: this.address,
          name: this.connectorname,
          creator: this.address,
          quantity: this.quantity,
          royalty: 0,
          description: this.connectorname,
          connectorname: this.connectorname,
          hardwarewalletaddress: this.hardwarewalletaddress,
          selecttoken: this.Tokens[this.selecttoken].symbol,
          smartcontractaddress: this.smartcontractaddress,
          selectdevice: this.selectdevice,
          selectchannel: this.selectchannel,
          selectpath: this.selectpath,
          selectappwallet: false,
          size: size,
          hw: true,
          crc: Date.now()
        }
      })
      this.track = track.data
      await this.uploadFiles()
    },
    async createDigitalAsset () {
      if (!this.finish) {
        // await this.stackDigitalAsset()
        // return
      }
      this.processText = 'Mint Asset'
      this.files.push(this.filePreview)
      const transaction = await this.$axios.get('https://pay4good.com/asset/createDigitalAsset', {
        params: {
          id: this.track.id,
          files: this.files,
          crc: Date.now()
        }
      })
      this.transactionResult = transaction.data
      this.files.pop()
      this.process = 100
      this.processText = 'Mint Complete'
      this.loading = false
      this.finish = true
    },
    async stackDigitalAsset () {
      this.stack = true
      this.processText = 'Mint Asset'
      this.files.push(this.filePreview)
      const transaction = await this.$axios.get('https://pay4good.com/asset/stackDigitalAsset', {
        params: {
          id: this.track.id,
          files: this.files,
          crc: Date.now()
        }
      })
      this.transactionResult = transaction.data
      this.files.pop()
      this.process = 100
      this.processText = 'Mint in queue'
      this.loading = false
      this.finish = true
    },
    async uploadFiles () {
      if (this.track.id) {
        // upload Preview
        this.count++
        this.filePreview.type = this.filePreview.file.type
        this.filePreview.name = this.filePreview.file.name
        this.filePreview.lastModified = this.filePreview.file.lastModified
        await this._uploadFile(this.filePreview, 0)
        // upload files
        for (const file of this.files) {
          file.type = file.file.type
          file.name = file.file.name
          file.lastModified = file.file.lastModified
          file.cid = ''
          this.count++
          await this._uploadFile(file, 0)
        }
      }
    },
    _uploadFile (file, offset) {
      if (offset === 0) {
        this.filesEncrypted[file.name] = []
      }
      const nextSlice = offset + chunkSize + 1
      const blob = file.file.slice(offset, nextSlice)
      const reader = new FileReader()
      reader.onloadend = async (event) => {
        if (event.target.readyState !== FileReader.DONE) {
          return
        }
        this.progressSize += chunkSize
        this.processText = 'Encrypted ' + file.name
        const dataString = JSON.stringify(Array.from(new Uint8Array(event.target.result)))
        if (file.tag === '_preview') {
          this.filesEncrypted[file.name].push(dataString)
        } else {
          const encrypted = CryptoJS.AES.encrypt(dataString, this.track.key)
          this.filesEncrypted[file.name].push(encrypted.toString())
        }
        if (nextSlice < file.file.size) {
          this.process = Math.floor(this.progressSize / this.size * 50)
          this._uploadFile(file, nextSlice)
        } else {
          const encryptedFile = new File([JSON.stringify(this.filesEncrypted[file.name])], file.name + '.encrypted', {
            type: file.type,
            lastModified: file.lastModified
          })
          const ipfs = create(this.$store.state.localBox ? process.env.VUE_APP_IPFS_LOCAL.replace('127.0.0.1', this.$store.state.localBox).replace('http', 'https') : process.env.VUE_APP_IPFS)
          const CID = await ipfs.add(encryptedFile, { progress: (prog) => console.log(prog) })
          file.cid = CID.path
          file.size = encryptedFile.size
          this.process = Math.floor(this.progressSize / this.size * 50)
          this.count--
          // Upload File
          if (this.count === 0) {
            // console.log('no more files')
            await this.createDigitalAsset()
          }
        }
      }
      reader.readAsArrayBuffer(blob)
    }
  }
}
</script>
