<template>
  <div class="Welcome">
      <v-dialog
        v-model="beta"
        width="500"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Beta
          </v-card-title>
          <v-card-text class="pa-8">
            Thank you for downloading wallet4good, please note that we are in <strong>BETA</strong> testing and not fully ready to support you. We are working to make sure that when we are deployed we can support you fully. Please try it, it has all funcionality; however, the ability to have full financial capabilities will be available In the next 2 weeks. If you have any issues or problems please contact us at <a href="mailto:help@bloqs4good.com">help@bloqs4good.com</a>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="beta = false"
            >
              Continue
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-container fluid class="fill-height">
      <v-row>
        <v-col align-self="start" align="center">
          <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : $store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <span v-if="!$device.mobile && !loading" class="black--text font-weight-bold">Scan QR for Sign In</span>
          <v-img v-if="!$device.mobile && !loading" :src="qr" contain width="300px" height="300px" class="qr"/>
          <v-btn :href="link" block color="#10395D" dark @click="loading = true"
                 class="mb-10" x-large v-if="$device.mobile && !loading">Sign In with ID4Good
          </v-btn>
          <v-btn v-if="!loading" plain class="mt-1 mb-10" @click="ledgerDialog = true">
            <div class="overline align-center d-inline-flex">or use your <img class="mx-2" :src="require('@/assets/ledger.webp')" width="60px" /></div>
          </v-btn>
          <v-progress-circular color="grey lighten-5" indeterminate width="10" size="150" v-if="loading">Sync</v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="end" align="center">
          <v-img :src="require('@/assets/logobew.svg')" contain width="200px"/>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="ledgerDialog" dark max-width="300px">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          Select Connector
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          Select the connection method to use with your Ledger
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="connectLedger(0)">USB</v-btn>
          <v-btn @click="connectLedger(1)">Bluetooth</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Welcome.scss'
import { generateQR, getChallenge, waitChallenge, getIdentity } from 'id4good.auth'
import TransportWebUSB from '@ledgerhq/hw-transport-webusb'
import TransportWebBle from '@ledgerhq/hw-transport-web-ble'
import AppEth from '@ledgerhq/hw-app-eth'
import { Ledger } from '@/classes/Ledger'

export default {
  name: 'Welcome',
  data () {
    return {
      ledgerDialog: false,
      beta: true,
      qr: null,
      link: null,
      ready: false,
      loading: false,
      transport: null,
      snackbar: false,
      text: ''
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    async connectLedger (conn) {
      this.ledgerDialog = false
      this.loading = true
      switch (conn) {
        case 0:
          this.transport = await TransportWebUSB.create()
          break

        case 1:
          this.transport = await TransportWebBle.create()
          break
      }
      const app = new Ledger(new AppEth(this.transport))
      app.setPath("44'/161803'/0'/0/0")
      this.text = 'Approve access in your Ledger'
      this.snackbar = true
      const seed = await app.signMessage('wallet4good')
      const privateKey = seed.r
      await this.$store.dispatch('trust/connect', { privateKey: privateKey })
      await this.$store.dispatch('bitcoin/connect', { privateKey: privateKey })
      await this.$router.push({ name: 'Main' })
    },
    clearAllIntervals () {
      for (let i = 0; i <= 1000; i++) {
        clearInterval(i)
      }
      for (let i = 0; i <= 1000; i++) {
        clearTimeout(i)
      }
    },
    async load () {
      const challenge = await getChallenge('wallet4good.com')
      // console.log(challenge)
      this.qr = await generateQR(challenge.code)
      if (this.$device.mobile) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
        }
      }
      // console.log('load')
      const expire = setTimeout(() => {
        this.load()
      }, 5 * 60000)
      waitChallenge(challenge.hash, 0).then(async (result) => {
        clearTimeout(expire)
        this.loading = true

        const identity = await getIdentity(challenge.hash)
        this.$store.state.sign = true
        this.$store.state.identity = identity.identity
        this.clearAllIntervals()
        let data = {}
        if (identity.data.data !== undefined) {
          try {
            data = JSON.parse(identity.data.data)
          } catch (e) {
            data = identity.data.data
          }
          const privateKey = data.privateKey
          await this.$store.dispatch('trust/connect', { privateKey: privateKey })
          await this.$store.dispatch('bitcoin/connect', { privateKey: privateKey })
          if (this.$store.state.rocarbon) {
            await this.$router.push({ name: 'AssetsRocarbon' })
            return
          }
          await this.$router.push({ name: 'Main' })
        } else {
          // console.log('You need to update your ID4Good')
        }
      }).catch((error) => {
        // console.log('error')
        console.log(error)
        // console.log(this.$store.state.sign)
        if (!this.$store.state.sign) {
          window.location.reload()
        }
      })
    }
  }
}
</script>
