<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
    <div id="version">
      <v-icon small color="green" v-if="$store.state.localBox !== false">
        mdi-vector-link
      </v-icon> {{ version }}
    </div>
  </v-app>
</template>

<script>
import firebase from 'firebase'
import { create } from 'ipfs-http-client'
import ipIs from 'private-ip'

export default {
  name: 'App',
  data: () => ({
    conn: 'Global',
    version: ''
  }),
  created () {
    // this.$store.state.rocarbon = true
    if (window.location.hostname === 'orcaex.vault4good.com') {
      this.$i18n.locale = 'es'
      this.$store.state.customLogo = 'orcaex.png'
    }
    if (window.location.hostname === 'oobit.vault4good.com') {
      this.$i18n.locale = 'en'
      this.$store.state.customLogo = 'oobit.png'
    }
    if (window.location.hostname === 'jinni.vault4good.com') {
      this.$i18n.locale = 'en'
      this.$store.state.customLogo = 'jinni.svg'
    }
    if (window.location.hostname === 'rocarbon.vault4good.com') {
      this.$store.state.rocarbon = true
    }
    if (this.$store.state.rocarbon) {
      this.$i18n.locale = 'en'
      this.$store.state.customLogo = 'rocarbon5.png'
    }
  },
  async mounted () {
    try {
      this.$axios.get('http://127.0.0.1:9555/ping').then(async service => {
        if (service.data === 'pong') {
          const hash = await this.$axios.get('http://127.0.0.1:9555/hash')
          this.$store.state.box = hash.data
          this.conn = 'Local'
          try {
            // await this.getIP()
          } catch (e) {
          }
          if (this.$route.name !== 'Ticket') {
            await this.$router.push({ name: 'WelcomeBox' })
          }
        }
      })
    } catch (e) {
      console.log(e)
    }
    this.version = 'v' + process.env.VUE_APP_VERSION + ' ' + this.conn
  },
  methods: {
    devMode () {
      if (localStorage.dev) {
        this.$store.state.dev = true
      }
    },
    async getIP () {
      const ip = await this.$axios.get('http://127.0.0.1:9555/myip')
      await this.$axios.get('https://pay4good.com/asset/setAssetIP', {
        params: {
          hash: this.$store.state.box,
          ip: ip.data,
          crc: Date.now()
        }
      })
    },
    async getIPLegacy () {
      const ipfs = create(process.env.VUE_APP_IPFS_LOCAL)
      const ips = await ipfs.swarm.localAddrs()
      const valid = []
      for (const ip of ips) {
        if (ip.nodeAddress().address === '127.0.0.1') {
          continue
        }
        if (ip.nodeAddress().address === '::1') {
          continue
        }
        if (!ipIs(ip.nodeAddress().address)) {
          continue
        }
        valid.push(ip.nodeAddress().address)
      }
      const validUnique = [...new Set(valid)]
      await this.$axios.get('https://pay4good.com/asset/setAssetIP', {
        params: {
          hash: this.$store.state.box,
          ip: validUnique.pop(),
          crc: Date.now()
        }
      })
    },
    registerNotifications () {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          const token = firebase.messaging().getToken()
          this.$axios.get('https://pay4good.com/asset/updateNotify', {
            params: {
              hash: this.$store.state.box,
              token: token,
              crc: Date.now()
            }
          })
          firebase.messaging().onMessage((payload) => {
            console.log('Message received. ', payload)
          })
        }
      })
    },
    async verifyRequest () {
      const result = await this.$axios.get('https://pay4good.com/asset/verifyAssetRequest', {
        params: {
          hash: this.register,
          crc: Date.now()
        }
      })
      console.log(result.data.request)
      if (result.data.request) {
        await this.$axios.post('http://127.0.0.1:9555/update', {
          url: result.data.request.url,
          key: result.data.request.key
        })
      }
    }
  }
}
</script>

<style>
#app {
  background-color: #FFFFFF;
  background-image: url("../src/assets/background.svg");
  background-attachment: fixed;
  background-size: cover;
}

#version {
  font-size: 0.7rem;
  position: fixed;
  color: #10395D;
  top: 5px;
  right: 20px;
  z-index: 10;
}
</style>
