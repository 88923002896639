/* eslint-disable */
export default {
  initialized: function (state, value) {
    state.initialized = value
  },
  connected: function (state, value) {
    state.connected = value
  },
  error: function (state, value) {
    state.error = value
  },
  wallet: function (state, value) {
    state.wallet = value
  },
  walletEthereum: function (state, value) {
    state.walletEthereum = value
  },
  walletToken: function (state, value) {
    state.walletToken = value
  },
  walletEthereumToken: function (state, value) {
    state.walletEthereumToken = value
  },
  walletAssets: function (state, value) {
    state.walletAssets = value
  },
  balance: function (state, value) {
    state.balance = value
  },
  balanceEthereum: function (state, value) {
    state.balanceEthereum = value
  },
  balanceEthereumToken: function (state, value) {
    state.balanceEthereumToken = value
  },
  balanceToken: function (state, value) {
    state.balanceToken = value
  },
  fee: function (state, value) {
    state.fee = value
  },
  feeToken: function (state, value) {
    state.feeToken = value
  },
  address: function (state, value) {
    state.address = value
  },
  addressToken: function (state, value) {
    state.addressToken = value
  },
  network: function (state, value) {
    state.network = value
  }
}
