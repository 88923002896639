<template>
  <div class="Swap">
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main && ready" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="left" class="py-5">
                <v-select @click="setAmount(0)" @change="updateTotal" dense v-model="origin" :items="Object.values(currencies).filter(item => item.separated !== true && item.type !== 'btc' && item.type !== 'eth' && item.type !== 'a4g' && item.type !== 'roct' && getBalanceFixed(item.type) > 0)" item-value="type" item-text="name"
                          label="From"></v-select>
                <span class="subtitle-2 font-weight">Balance:</span>
                <span v-if="$vuetify.breakpoint.width < 400 && getBalanceFixed(origin) >= 1" style="font-size: 0.8rem;"> {{
                    getBalanceFixed(origin) | numFormat(currencies[origin].numFormat)
                  }} <span style="font-size: 0.8rem">{{ origin ? getSymbol(origin) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width >= 400 && getBalanceFixed(origin) >= 1" style="font-size: 1rem;"> {{
                    getBalanceFixed(origin) | numFormat(currencies[origin].numFormat)
                  }} <span style="font-size: 0.9rem">{{ origin ? getSymbol(origin) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width >= 400 && getBalanceFixed(origin) < 1" style="font-size: 1rem;"> {{
                    getBalanceFixed(origin) | numFormat(currencies[origin].numFormat)
                  }} <span style="font-size: 0.9rem">{{ origin ? getSymbol(origin) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width < 400 && getBalanceFixed(origin) < 1" style="font-size: 0.8rem;"> {{
                    getBalanceFixed(origin) | numFormat(currencies[origin].numFormat)
                  }} <span style="font-size: 0.8rem">{{ origin ? getSymbol(origin) : '' }}</span>
                          </span>
                <span>     Select All </span>
                <v-icon @click="setAmount(getBalanceFixed(origin))" medium>mdi-arrange-bring-forward</v-icon>
              </v-col>
              </v-row>
            <v-row>
              <v-col align-self="center" align="left" class="py-2">
                <v-slider
                  v-model="amount"
                  class="align-center"
                  :max="getBalanceFixed(origin)"
                  :min="currencies[origin].unit"
                  :step="currencies[origin].unit"
                >
                </v-slider>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="left" class="py-2">
                <v-select @change="updateTotal" dense v-model="destination" :items="Object.values(currencies).filter(item => item.type !== 'a4g' && item.type !== 'btc' && item.type !== 'eth' && item.type !== 'usdc' && item.type !== 'usdt' && item.type !== 'busd' && item.type !== 'roct' && item.type !== origin)" item-value="type" item-text="name"
                          label="To"></v-select>
                <span class="subtitle-2 font-weight">Balance:</span>
                <span v-if="$vuetify.breakpoint.width < 400 && getBalanceFixed(destination) >= 1" style="font-size: 0.8rem;"> {{
                    getBalanceFixed(destination) | numFormat(currencies[destination].numFormat)
                  }} <span style="font-size: 0.8rem">{{ destination ? getSymbol(destination) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width >= 400 && getBalanceFixed(destination) >= 1" style="font-size: 1rem;"> {{
                    getBalanceFixed(destination) | numFormat(currencies[destination].numFormat)
                  }} <span style="font-size: 0.9rem">{{ destination ? getSymbol(destination) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width >= 400 && getBalanceFixed(destination) < 1" style="font-size: 1rem;"> {{
                    getBalanceFixed(destination) | numFormat(currencies[destination].numFormat)
                  }} <span style="font-size: 0.9rem">{{ destination ? getSymbol(destination) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width < 400 && getBalanceFixed(destination) < 1" style="font-size: 0.8rem;"> {{
                    getBalanceFixed(destination) | numFormat(currencies[destination].numFormat)
                  }} <span style="font-size: 0.8rem">{{ destination ? getSymbol(destination) : '' }}</span>
                          </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="left" class="py-0">
                <v-text-field
                              :rules="[value => Number(value) <= getBalanceFixed(origin) && Number(value) > 0 || 'Invalid Amount']"
                              type="number" :step="currencies[origin].unit" :min="currencies[origin].unit"
                              :disabled="!origin" placeholder="0" v-model="amount" label="Amount"
                              :suffix="origin ? getSymbol(origin) : ''"></v-text-field>
<!--                <v-checkbox dense append-icon="mdi-information-outline" label="Anonymous"></v-checkbox>-->
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="origin" align-self="center" align="right" class="py-0">
                <div class="font-weight">Summary</div>
                <div><span class="font-weight">Estimated network fee: ~</span> {{ parseFloat(this.currentFee).toFixed(7) | numFormat('0,0.[0000000]') }}
                  {{ ['a4g', 't4g', 't4gcad', 't4gclp', 't4gbrl'].includes(origin) ? getSymbol('a4g') : ['eth'].includes(origin) ? getSymbol('eth') : ['btc'].includes(origin) ? getSymbol('btc') : '' }}
                </div>
                <div class="font-weight-bold"><span
                  >Amount after swap: ~</span>{{ parseFloat(amountConvert).toFixed(7) | numFormat('0,0.[0000000]') }}
                  {{ origin ? getSymbol(destination) : '' }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-5 overline text-uppercase">
                <v-alert
                  v-if="disabled"
                  shaped
                  color="#272727"
                  type="info"
                >Temporary Unavailable</v-alert>
                <v-alert
                  v-if="destination.substring(0, 3) === 't4g'"
                  shaped
                  color="#272727"
                  type="info"
                  dismissible
                >1 {{ getSymbol(destination) }} Loyalty Token is equivalent to $1 {{ currencies[destination].convertType }} and can be redeem for goods and services in our platforms and partners network.</v-alert>
                <v-alert
                  v-if="destination === 'a4g'"
                  shaped
                  color="#272727"
                  type="info"
                  dismissible
                >1 {{ getSymbol(destination) }} Loyalty Token is equivalent to ${{ currencies[destination].convert }} {{ currencies[destination].convertType }} and can be redeemed for goods and services exclusively within our network of partners and services.</v-alert>
              </v-col>
              <v-alert
                v-if="origin === 'eth' || origin === 'btc'"
                shaped
                color="#272727"
                type="info"
                dismissible
              >Self-Custody DeFi Wallet: The private keys of yours {{ getSymbol(origin) }} are encrypted inside of your id4good. Your Keys, Your Coins.</v-alert>
            </v-row>
            <v-row>
              <v-col align-self="center" align="center">
                <v-btn @click="swap" block outlined dark rounded :disabled="disabled || getBalanceFixed(origin) < Number(amount) || Number(amount) <= 0">Swap</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="finish" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>Sync</v-progress-circular>
                <v-container fluid v-if="!loading">
                  <v-row class="title font-weight-bold white--text">
                    <v-col>{{ transactionResult.payment === 'success' ? 'SUCCESS' : 'FAIL' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Transaction:</v-col>
                    <v-col align="left">{{ $device.mobile ? '...' + transaction.hash.substring(transaction.hash.length - 10) : '...' + transaction.hash.substring(transaction.hash.length - 32) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Block:</v-col>
                    <v-col align="left">{{ $device.mobile ? '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 10) : '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 32) }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">From:</v-col>
                    <v-col align="left">{{ currencies[origin].name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">To:</v-col>
                    <v-col align="left">{{ currencies[destination].name }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Amount:</v-col>
                    <v-col align="left">{{ (Number(this.amountConvert)).toFixed(8) }} {{ origin ? getSymbol(destination) : '' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" to="/main" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Paste Success
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_Swap.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'
import { utils } from 'ethers'
import { script } from 'bitcoinjs-lib'
import { event, EVENT_CHANNEL, MSGS } from '@/store/bitcoin/Bitcoin'

export default {
  name: 'Swap',
  components: { Bar },
  props: {
    currency: String
  },
  data () {
    return {
      main: false,
      origin: null,
      destination: 'a4g',
      amount: 0,
      disabled: false,
      amountConvert: null,
      rate: null,
      loading: false,
      finish: false,
      snackbar: false,
      transaction: { hash: '' },
      transactionResult: { blockHash: '' }
    }
  },
  mixins: [currency],
  watch: {
    origin: {
      deep: true,
      handler: function () {
        for (const currency in this.currencies) {
          if (this.currencies[currency].type !== this.origin) {
            // console.log(this.currencies[currency])
            this.destination = this.currencies[currency].type
            break
          }
        }
      }
    },
    destination: {
      deep: true,
      handler: async function (value) {
        this.disabled = false
        if (value === 'eth') {
          const balance = await this.walletEthereum.provider.getBalance(process.env.VUE_APP_CORE_ADDRESS)
          // console.log(parseFloat(balance).toFixed(7))
          // console.log(this.amount)
          if (parseFloat(balance).toFixed(7) <= parseFloat(this.amount).toFixed(7)) {
            this.disabled = true
          }
        }
        if (value === 'btc') {
          this.disabled = true
        }
      }
    },
    amount: {
      deep: true,
      handler: function (value) {
        this.updateTotal()
        const myvalue = Number(value).toFixed(this.currencies[this.origin].fixed)
        return myvalue
      }
    }
  },
  mounted () {
    this.origin = this.currency
    setTimeout(() => {
      this.show()
    }, 300)
  },
  methods: {
    async updateTotal () {
      await this.estimateFee(this.origin)
      const originUSD = this.currencies[this.origin].convert
      const destinationUSD = this.currencies[this.destination].convert
      this.rate = originUSD / destinationUSD
      this.amountConvert = (Number(this.amount) * this.rate) * 0.985
    },
    show () {
      for (const currency in this.currencies) {
        if (this.currencies[currency].type !== this.origin) {
          // console.log(this.currencies[currency])
          this.destination = this.currencies[currency].type
          break
        }
      }
      this.main = true
    },
    setAmount (item) {
      this.amount = item
    },
    paste () {
      navigator.clipboard.readText().then((address) => {
        this.destination = address
        this.snackbar = true
      })
    },
    async swap () {
      this.amount = this.amount.toString()
      // console.log('swap')
      this.loading = true
      this.main = false
      this.finish = true
      this.transactionResult = {}
      let tx = {}
      let result = {}
      switch (this.origin) {
        case 'a4g':
          this.disabled = true
          /* tx = {
            from: this.currencies[this.origin].address,
            to: process.env.VUE_APP_PAYMENT_ADDRESS,
            value: utils.parseEther(this.amount)
          }
          await this.wallet.signTransaction(tx)
          this.transaction = await this.wallet.sendTransaction(tx)
          result = await this.$axios.get('https://pay4good.com/api/swapCurrencies', {
            params: {
              from: this.currencies[this.origin].address,
              origin: this.origin,
              destination: this.destination,
              amount: this.amount,
              transaction: this.transaction.hash
            }
          })
          this.transactionResult = result.data
          this.loading = false */
          break
        case 't4g':
          tx.value = utils.parseEther(this.amount)
          this.transaction = await this.walletToken.USD.transfer(process.env.VUE_APP_PAYMENT_ADDRESS, tx.value)
          result = await this.$axios.get('https://pay4good.com/api/swapCurrencies', {
            params: {
              from: this.decodeAddress(this.currencies[this.origin].address),
              origin: this.origin,
              destination: this.destination,
              amount: this.amount,
              transaction: this.transaction.hash,
              crc: Date.now()
            }
          })
          this.transactionResult = result.data
          this.loading = false
          break
        case 't4gcad':
          tx.value = utils.parseEther(this.amount)
          this.transaction = await this.walletToken.CAD.transfer(process.env.VUE_APP_PAYMENT_ADDRESS, tx.value)
          result = await this.$axios.get('https://pay4good.com/api/swapCurrencies', {
            params: {
              from: this.decodeAddress(this.currencies[this.origin].address),
              origin: this.origin,
              destination: this.destination,
              amount: this.amount,
              transaction: this.transaction.hash,
              crc: Date.now()
            }
          })
          this.transactionResult = result.data
          this.loading = false
          break
        case 't4gbrl':
          tx.value = utils.parseEther(this.amount)
          this.transaction = await this.walletToken.BRL.transfer(process.env.VUE_APP_PAYMENT_ADDRESS, tx.value)
          result = await this.$axios.get('https://pay4good.com/api/swapCurrencies', {
            params: {
              from: this.decodeAddress(this.currencies[this.origin].address),
              origin: this.origin,
              destination: this.destination,
              amount: this.amount,
              transaction: this.transaction.hash,
              crc: Date.now()
            }
          })
          this.transactionResult = result.data
          this.loading = false
          break
        case 't4gclp':
          tx.value = utils.parseEther(this.amount)
          this.transaction = await this.walletToken.CLP.transfer(process.env.VUE_APP_PAYMENT_ADDRESS, tx.value)
          result = await this.$axios.get('https://pay4good.com/api/swapCurrencies', {
            params: {
              from: this.decodeAddress(this.currencies[this.origin].address),
              origin: this.origin,
              destination: this.destination,
              amount: this.amount,
              transaction: this.transaction.hash,
              crc: Date.now()
            }
          })
          this.transactionResult = result.data
          this.loading = false
          break
        case 'eth':
          tx = {
            from: this.currencies[this.origin].address,
            to: process.env.VUE_APP_PAYMENT_ADDRESS,
            value: utils.parseEther(this.amount)
          }
          await this.walletEthereum.signTransaction(tx)
          this.transaction = await this.walletEthereum.sendTransaction(tx)
          this.$axios.get('https://pay4good.com/api/swapCurrencies', {
            params: {
              from: this.currencies[this.origin].address,
              origin: this.origin,
              destination: this.destination,
              amount: this.amount,
              transaction: this.transaction.hash,
              crc: Date.now()
            }
          }).then((result) => { this.transactionResult = result.data })
          this.walletEthereum.provider.once(this.transaction.hash, (transaction) => {
            this.loading = false
          })
          break
        case 'btc':
          tx = {
            inputs: [{ addresses: [this.currencies[this.origin].address] }],
            outputs: [{
              addresses: [process.env.VUE_APP_PAYMENT_ADDRESS_BITCOIN],
              value: this.amount * 10 ** 8
            }]
          }
          this.$axios.post('https://api.blockcypher.com/v1/btc/main/txs/new', tx)
            .then((tmp) => {
              const tmptx = tmp.data
              // console.log(tmptx)
              tmptx.pubkeys = []
              tmptx.signatures = tmptx.tosign.map((tosign, n) => {
                tmptx.pubkeys.push(this.walletBitcoin.publicKey.toString('hex'))
                return script.signature.encode(
                  this.walletBitcoin.sign(Buffer.from(tosign, 'hex')), 0x01).toString('hex').slice(0, -2)
              })
              this.$axios.post('https://api.blockcypher.com/v1/btc/main/txs/send', tmptx)
                .then(async (done) => {
                  // console.log(done.data)
                  this.transaction.hash = done.data.tx.hash
                  this.transactionResult.hash = done.data.tx.hash
                  this.transactionResult.blockHash = done.data.tx.hash
                  this.transactionResult.confirmations = 1
                  event.$emit(EVENT_CHANNEL, MSGS.ACCOUNT_CHANGED)
                  result = await this.$axios.get('https://pay4good.com/api/swapCurrencies', {
                    params: {
                      from: this.address,
                      origin: this.origin,
                      destination: this.destination,
                      amount: this.amount,
                      transaction: this.transaction.hash,
                      crc: Date.now()
                    }
                  })
                  this.transactionResult = result.data
                  this.loading = false
                })
                .catch((error) => {
                  console.log(error.data)
                  this.loading = false
                })
            })
          break
      }
    }
  }
}
</script>
