<template>
  <div class="CurrencyDeFi">
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main && ready" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col>
                <v-btn @click="mode = 'invest'" outlined>Invest</v-btn>
              </v-col>
              <v-col>
                <v-btn @click="mode = 'pawn'" outlined>Pawn</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="mode === 'invest'" align-self="center" align="center">
                <h1>Invest</h1>
              </v-col>
              <v-col v-if="mode === 'pawn'" align-self="center" align="center">
                <h1>Pawn</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="left" class="py-0">
                <v-select @click="setAmount(0)" v-model="origin" :items="Object.values(currencies).filter(item => getBalance(item.type) > 0)"
                          item-value="type" item-text="name"
                          label="From"></v-select>
                <span>Balance:</span>
                <span v-if="$vuetify.breakpoint.width < 400 && getBalance(origin) >= 1" style="font-size: 0.8rem;"> {{
                    parseFloat(getBalance(origin)).toFixed(7) | numFormat('0,0.[00]')
                  }} <span style="font-size: 0.8rem">{{ origin ? getSymbol(origin) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width >= 400 && getBalance(origin) >= 1" style="font-size: 1rem;"> {{
                    parseFloat(getBalance(origin)).toFixed(7) | numFormat('0,0.[00]')
                  }} <span style="font-size: 0.9rem">{{ origin ? getSymbol(origin) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width >= 400 && getBalance(origin) < 1" style="font-size: 1rem;"> {{
                    parseFloat(getBalance(origin)).toFixed(7) | numFormat('0,0.[0000000]')
                  }} <span style="font-size: 0.9rem">{{ origin ? getSymbol(origin) : '' }}</span>
                          </span>
                <span v-if="$vuetify.breakpoint.width < 400 && getBalance(origin) < 1" style="font-size: 0.8rem;"> {{
                    parseFloat(getBalance(origin)).toFixed(7) | numFormat('0,0.[0000000]')
                  }} <span style="font-size: 0.8rem">{{ origin ? getSymbol(origin) : '' }}</span>
                          </span>
                <span>     Select All </span>
                <v-icon @click="setAmount(getBalance(origin))" medium>mdi-arrange-bring-forward</v-icon>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="left" class="py-5">
                <v-text-field dense
                              :rules="[value => Number(value) <= getBalance(origin) && Number(value) > 0 || 'Insufficient funds']"
                              type="number" step="1" min="0"
                              :disabled="!origin" placeholder="0" v-model="amount" label="Amount"
                              :suffix="origin ? getSymbol(origin) : ''"></v-text-field>
                <!--                <v-checkbox dense append-icon="mdi-information-outline" label="Anonymous"></v-checkbox>-->
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h2>Simulation</h2>
              </v-col>
            </v-row>
            <v-row v-if="mode === 'invest'">
              <v-col>
                <div>Month Rentability*: 3% (last 30 days)</div>
                <div>Invest Fee: 1 T4G-USD</div>
                <div>Estimate Rentability in 30 days: {{ amount * 0.03 }} {{ origin ? getSymbol(origin) : '' }}</div>
              </v-col>
            </v-row>
            <v-row v-if="mode === 'pawn'">
              <v-col>
                  <div>Rating: 10%</div>
                <div>Pawn Fee: 15% over Rating by 30 days</div>
                <div>Total Received: {{ (amount * 0.1 * currencies[origin].convert).toFixed(currencies[origin].fixed) }} T4G-USD</div>
                <div>Total to Pay to recover in 30 days: {{ ((amount * 0.1 + amount * 0.1 * 0.15) * currencies[origin].convert).toFixed(currencies[origin].fixed) }} T4G-USD</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="origin" align-self="center" align="right" class="py-0">
                <div class="font-weight">Summary</div>
                <div><span class="font-weight">Network Fee: </span> {{ this.currentFee }}
                  {{
                    ['a4g', 't4g'].includes(origin) ? getSymbol('a4g') : ['eth'].includes(origin) ? getSymbol('eth') : ['btc'].includes(origin) ? getSymbol('btc') : ''
                  }}
                </div>
                <div><span
                  class="font-weight">Total: </span>{{ (Number(amount)).toFixed(8) }}
                  {{ origin ? getSymbol(origin) : '' }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" align="center">
                <v-btn disabled block outlined dark rounded>{{ mode === 'invest' ? 'Invest' : 'Pawn' }}</v-btn>
                <div style="color: red" class="my-10">Demo Only</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-10"></v-col>
            </v-row>
            <v-row>
              <v-col>
                <span style="font-size: 0.8rem; font-style: italic">* Performance is only a reference to past performance.</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <v-scale-transition>
      <v-card v-if="finish" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>
                  Sync
                </v-progress-circular>
                <v-container fluid v-if="!loading">
                  <v-row class="title font-weight-bold white--text">
                    <v-col>{{ transactionResult.confirmations > 0 ? 'SUCCESS' : 'FAIL' }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Transaction:</v-col>
                    <v-col align="left">{{
                        $device.mobile ? '...' + transaction.hash.substring(transaction.hash.length - 10) : '...' + transaction.hash.substring(transaction.hash.length - 32)
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Block:</v-col>
                    <v-col align="left">{{
                        $device.mobile ? '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 10) : '...' + transactionResult.blockHash.substring(transactionResult.blockHash.length - 32)
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">From:</v-col>
                    <v-col align="left">{{
                        '...' + this.currencies[this.origin].address.substring(this.currencies[this.origin].address.length - 10)
                      }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">To:</v-col>
                    <v-col align="left">
                      {{ $device.mobile ? '...' + destination.substring(destination.length - 10) : destination }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="font-weight-bold" align="right">Amount:</v-col>
                    <v-col align="left">{{ (Number(amount)).toFixed(8) }} {{
                        origin ? currencies[origin].symbol : ''
                      }}
                    </v-col>
                  </v-row>
                  <v-row v-if="origin === 'btc'">
                    <v-col align-self="center" align="center" class="overline">
                      This transaction is dependent on network timings, so it can take anywhere from 1 hour to 4 hours to complete.
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn class="mt-10" to="/main" block outlined dark rounded>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <Camera v-if="camera" v-on:close="closeCamera" />
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Paste Success
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_CurrencyDeFi.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'
import { utils } from 'ethers'
import { script } from 'bitcoinjs-lib'
import { event, EVENT_CHANNEL, MSGS } from '@/store/bitcoin/Bitcoin'
import Camera from '@/components/Camera/Camera'

export default {
  name: 'CurrencyDeFi',
  components: { Bar, Camera },
  props: {
    currency: String
  },
  data () {
    return {
      camera: false,
      main: false,
      origin: null,
      mode: 'invest',
      destination: process.env.VUE_APP_CORE_ADDRESS,
      amount: null,
      loading: false,
      finish: false,
      snackbar: false,
      transaction: { hash: '' },
      transactionResult: { blockHash: '' }
    }
  },
  mixins: [currency],
  watch: {
    amount: {
      deep: true,
      handler: function () {
        this.estimateFee(this.origin)
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.show()
    }, 300)
  },
  methods: {
    closeCamera (data) {
      this.camera = false
      this.destination = data
    },
    show () {
      this.main = true
      console.log(this.currency)
      this.origin = this.currency ? this.currency : 'a4g'
    },
    setAmount (item) {
      this.amount = item
    },
    paste () {
      navigator.clipboard.readText().then((address) => {
        this.destination = address
        this.snackbar = true
      })
    },
    async send () {
      console.log('send')
      this.loading = true
      this.main = false
      this.finish = true
      this.transactionResult = {}
      let tx = {}
      switch (this.origin) {
        case 'a4g':
          tx = {
            from: this.decodeAddress(this.currencies[this.origin].address),
            to: this.decodeAddress(this.destination),
            value: utils.parseEther(this.amount)
          }
          await this.wallet.signTransaction(tx)
          this.transaction = await this.wallet.sendTransaction(tx)
          this.wallet.provider.once(this.transaction.hash, (transaction) => {
            this.transactionResult = transaction
            this.loading = false
          })
          break
        case 't4g':
          tx.value = utils.parseEther(this.amount)
          this.transaction = await this.walletToken.USD.transfer(this.decodeAddress(this.destination), tx.value)
          this.wallet.provider.once(this.transaction.hash, (transaction) => {
            this.transactionResult = transaction
            console.log(transaction)
            this.loading = false
          })
          break
        case 't4gcad':
          tx.value = utils.parseEther(this.amount)
          this.transaction = await this.walletToken.CAD.transfer(this.decodeAddress(this.destination), tx.value)
          this.wallet.provider.once(this.transaction.hash, (transaction) => {
            this.transactionResult = transaction
            console.log(transaction)
            this.loading = false
          })
          break
        case 't4gclp':
          tx.value = utils.parseEther(this.amount)
          this.transaction = await this.walletToken.CLP.transfer(this.decodeAddress(this.destination), tx.value)
          this.wallet.provider.once(this.transaction.hash, (transaction) => {
            this.transactionResult = transaction
            console.log(transaction)
            this.loading = false
          })
          break
        case 't4gbrl':
          tx.value = utils.parseEther(this.amount)
          this.transaction = await this.walletToken.BRL.transfer(this.decodeAddress(this.destination), tx.value)
          this.wallet.provider.once(this.transaction.hash, (transaction) => {
            this.transactionResult = transaction
            console.log(transaction)
            this.loading = false
          })
          break
        case 'eth':
          tx = {
            from: this.currencies[this.origin].address,
            to: this.destination,
            value: utils.parseEther(this.amount)
          }
          await this.walletEthereum.signTransaction(tx)
          this.transaction = await this.walletEthereum.sendTransaction(tx)
          this.walletEthereum.provider.once(this.transaction.hash, (transaction) => {
            this.transactionResult = transaction
            this.loading = false
          })
          break
        case 'btc':
          tx = {
            inputs: [{ addresses: [this.currencies[this.origin].address] }],
            outputs: [{
              addresses: [this.destination],
              value: this.amount * 10 ** 8
            }]
          }
          this.$axios.post('https://api.blockcypher.com/v1/btc/main/txs/new', tx)
            .then((tmp) => {
              const tmptx = tmp.data
              console.log(tmptx)
              tmptx.pubkeys = []
              tmptx.signatures = tmptx.tosign.map((tosign, n) => {
                tmptx.pubkeys.push(this.walletBitcoin.publicKey.toString('hex'))
                return script.signature.encode(
                  this.walletBitcoin.sign(Buffer.from(tosign, 'hex')), 0x01).toString('hex').slice(0, -2)
              })
              this.$axios.post('https://api.blockcypher.com/v1/btc/main/txs/send', tmptx)
                .then((done) => {
                  console.log(done.data)
                  this.transaction.hash = done.data.tx.hash
                  this.transactionResult.hash = done.data.tx.hash
                  this.transactionResult.blockHash = done.data.tx.hash
                  this.transactionResult.confirmations = 1
                  event.$emit(EVENT_CHANNEL, MSGS.ACCOUNT_CHANGED)
                  this.loading = false
                })
                .catch((error) => {
                  console.log(error.data)
                  this.loading = false
                })
            })
      }
    }
  }
}
</script>
