<template>
  <div class="History">
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid class="fill-height" style="max-width: 700px">
            <v-row>
              <v-col>
                <v-text-field v-model="search" label="Search" dark outlined dense/>
                <v-progress-linear v-if="syncing" color="white" dark indeterminate height="15"
                                   class="overline rounded">Sync
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-row v-for="(tx, index) in transactions.filter(obj => search.length < 3 || (obj.realTo ? encodeAddress(obj.realTo.toLowerCase()).includes(search.toLowerCase()) : false) || encodeAddress(obj.from.toLowerCase()).includes(search.toLowerCase()) || encodeAddress(obj.to.toLowerCase()).includes(search.toLowerCase()) || encodeAddress(obj.hash.toLowerCase()).includes(search.toLowerCase())).slice((page - 1 ) * 10, page * 10)" v-bind:key="index" style="border-bottom: 1px solid #FFFFFF22">
              <v-col align-self="center" align="center" cols="4">
                <v-div v-if="(tx.contract ? tx.amount : tx.value) >= 1 ">
                  {{ tx.from === decodeAddress(queryAddress) ? '-' : '+' }}{{ (tx.contract ? parseFloat(tx.amount).toFixed(7) : parseFloat(tx.value).toFixed(7)) | numFormat('0,0.[00]') }} {{ getContractSymbol(tx.contract) }}
                </v-div>
                <v-div v-if="(tx.contract ? tx.amount : tx.value) < 1 ">
                  {{ tx.from === decodeAddress(queryAddress) ? '-' : '+' }}{{ (tx.contract ? parseFloat(tx.amount).toFixed(7) : parseFloat(tx.value).toFixed(7)) | numFormat('0,0.[0000000]') }} {{ getContractSymbol(tx.contract) }}
                </v-div>
                <v-div v-if="tx.method === 'transferAsset'">
                  {{ tx.quantity }} DAF
                </v-div>
                <div>
                  <v-icon :color="tx.from === decodeAddress(queryAddress) ? 'red' : 'green'" large>{{ tx.from === decodeAddress(queryAddress) ? 'mdi-call-made' : 'mdi-call-received' }}</v-icon>
                  {{ tx.from === decodeAddress(queryAddress) ? 'SENT' : 'RECEIVED' }}
                </div>
                <div class="overline" style="font-size: 0.6rem !important; position: absolute; margin-top: 20px"></div>
              </v-col>
              <v-col align="left" cols="8">
                <div class="overline" style="font-size: 0.9rem !important;">
                      <span v-if="$vuetify.breakpoint.width >= 550">
                        <strong class="">{{ tx.from === decodeAddress(queryAddress) ? 'TO: ' : 'FROM: ' }}</strong>~{{ tx.from === decodeAddress(queryAddress) ? tx.realTo ? encodeAddress(tx.realTo).substring(encodeAddress(tx.realTo).length - 10) : encodeAddress(tx.to).substring(encodeAddress(tx.to).length - 10) : encodeAddress(tx.from).substring(encodeAddress(tx.from).length - 10)
                        }}
                        <v-icon @click="copyAsset(tx.from === decodeAddress(queryAddress) ? tx.realTo ? encodeAddress(tx.realTo) : encodeAddress(tx.to) : encodeAddress(tx.from))" medium>mdi-content-copy</v-icon>
                        <v-icon disabled medium>mdi-account-search</v-icon>
                        <v-icon disabled medium>mdi-square-edit-outline</v-icon>
                      </span>
                      <span v-if="$vuetify.breakpoint.width < 550">
                        <strong class="">{{ tx.from === decodeAddress(queryAddress) ? 'TO: ' : 'FROM: ' }}</strong>~{{ tx.from === decodeAddress(queryAddress) ? tx.realTo ? encodeAddress(tx.realTo).substring(encodeAddress(tx.realTo).length - 10) : encodeAddress(tx.to).substring(encodeAddress(tx.to).length - 10) : encodeAddress(tx.from).substring(encodeAddress(tx.from).length - 10)
                    }}
                        <div>
                        <v-icon @click="copyAsset(tx.from === decodeAddress(queryAddress) ? tx.realTo ? encodeAddress(tx.realTo) : encodeAddress(tx.to) : encodeAddress(tx.from))" medium>mdi-content-copy</v-icon>
                        <v-icon disabled medium>mdi-account-search</v-icon>
                        <v-icon disabled medium>mdi-square-edit-outline</v-icon>
                        </div>
                      </span>

                </div>
                <div class="overline" style="font-size: 0.7rem !important;">
                Date: {{ new Date(tx.timestamp * 1000).toLocaleString() }}
                </div>
                <div class="overline" style="font-size: 0.7rem !important;">
                      <span v-if="$vuetify.breakpoint.width >= 550" class="mr-5">
                          Transaction: ~{{ encodeAddress(tx.hash).substring(encodeAddress(tx.hash).length - 10) }}
                        <v-icon @click="copyAsset(encodeAddress(tx.hash))" medium>mdi-content-copy</v-icon>
                        <v-icon disabled medium>mdi-text-search</v-icon>
                        <v-icon disabled medium>mdi-file-pdf-box</v-icon>
                      </span>
                      <span v-if="$vuetify.breakpoint.width < 550" class="mr-5">
                          Transaction: ~{{ encodeAddress(tx.hash).substring(encodeAddress(tx.hash).length - 10) }}
                        <div>
                        <v-icon @click="copyAsset(encodeAddress(tx.hash))" medium>mdi-content-copy</v-icon>
                        <v-icon disabled medium>mdi-text-search</v-icon>
                        <v-icon disabled medium>mdi-file-pdf-box</v-icon>
                        </div>
                      </span>
                </div>
              </v-col>
            </v-row>

          </v-container>
        </v-card-text>
        <v-card-actions align="center">
          <v-container>
            <v-row>
              <v-col align-self="center" align="center">
                <v-pagination
                  v-model="page"
                  :length="getLength()"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        Copy Success: ~{{ text.substring(text.length - 32) }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_History.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'

export default {
  name: 'History',
  components: { Bar },
  props: {
    queryAddress: String,
    queryContract: String
  },
  data () {
    return {
      page: 1,
      main: false,
      loading: false,
      finish: false,
      snackbar: false,
      syncing: true,
      text: 'Copy Success',
      search: '',
      transactions: []
    }
  },
  mixins: [currency],
  async mounted () {
    this.syncing = true
    setTimeout(() => {
      this.show()
    }, 300)
    await this.getTransactions()
  },
  methods: {
    getLength () {
      return parseInt(this.transactions.filter(obj => this.search.length < 3 || (obj.realTo ? obj.realTo.includes(this.search) : false) || obj.from.includes(this.search) || obj.to.includes(this.search) || obj.hash.includes(this.search)).length / 10)
    },
    show () {
      this.main = true
      this.destination = 'a4g'
    },
    getContractSymbol (contract) {
      let token = 'A4G'
      if (process.env.VUE_APP_CONTRACT === contract) {
        token = 'T4G-USD'
      }
      if (process.env.VUE_APP_CONTRACT_CAD === contract) {
        token = 'T4G-CAD'
      }
      if (process.env.VUE_APP_CONTRACT_BRL === contract) {
        token = 'T4G-BRL'
      }
      if (process.env.VUE_APP_CONTRACT_CLP === contract) {
        token = 'T4G-CLP'
      }
      if (process.env.VUE_APP_CONTRACT_ASSETS === contract) {
        token = 'DAF'
      }
      if (process.env.VUE_APP_CONTRACT_ASSETS_OLD === contract) {
        token = 'DAF'
      }
      return token
    },
    async getTransactions () {
      const result = await this.$axios.get('https://pay4good.com/api/getTransactions',
        {
          params: {
            address: this.decodeAddress(this.queryAddress),
            currency: this.queryContract,
            crc: Date.now()
          }
        })
      this.transactions = result.data.filter(item => ['transferAsset', 'transfer', 'transferFrom', undefined].includes(item.method))
      this.syncing = false
    },
    copy () {
      navigator.clipboard.writeText(this.currencies[this.destination].address)
      this.snackbar = true
    },
    copyAsset (item) {
      this.text = item
      navigator.clipboard.writeText(item)
      this.snackbar = true
    },
    async saveAlias () {
      this.loadingDialog = true
      await this.$axios.get('https://pay4good.com/asset/setAssetName', {
        params: {
          address: this.asset.address,
          hash: this.asset.hash,
          name: this.asset.name,
          crc: Date.now()
        }
      })
      this.editDialog = false
      this.loadingDialog = false
    }
  }
}
</script>
