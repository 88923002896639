import { mapState } from 'vuex'
import { Contract, utils, VoidSigner, Wallet } from 'ethers'
import { DateHelper, DateInterval } from '@valo/cache'
import CryptoJS from 'crypto-js'
import { script } from 'bitcoinjs-lib'
import { event, EVENT_CHANNEL, MSGS } from '@/store/bitcoin/Bitcoin'
// import bitcoinjs from 'bitcoinjs-lib'
// Hardware Ledger
import AppBtc from '@ledgerhq/hw-app-btc'
import AppEth from '@ledgerhq/hw-app-eth'
import TransportWebUSB from '@ledgerhq/hw-transport-webusb'
import TransportWebBle from '@ledgerhq/hw-transport-web-ble'

export default {
  data () {
    return {
      prices: {},
      currencies: {},
      currentFee: 0,
      tokens: ['t4g', 't4gcad', 't4gclp', 't4gbrl'],
      addressFormats: [
        {
          name: 'Ethereum',
          type: 'eth',
          symbol: 'ETH',
          length: 42,
          formatName: 'Hex',
          begins: '0x',
          prefix: 2,
          format: '',
          path: "44'/60'/0'/0/0",
          id: 0
        },
        {
          name: 'Bitcoin',
          type: 'btc',
          symbol: 'BTC',
          length: 34,
          formatName: 'Legacy',
          begins: '1',
          prefix: 1,
          format: 'legacy',
          path: "44'/0'/0'/0/0",
          id: 1
        },
        {
          name: 'Bitcoin',
          type: 'btc',
          symbol: 'BTC',
          length: 34,
          formatName: 'Legacy Segwit',
          begins: '3',
          prefix: 1,
          format: 'p2sh',
          path: "49'/0'/0'/0/0",
          id: 2
        },
        {
          name: 'Bitcoin',
          type: 'btc',
          symbol: 'BTC',
          length: 42,
          formatName: 'Native Segwit',
          begins: 'bc1q',
          prefix: 4,
          format: 'bech32',
          path: "84'/0'/0'/0/0",
          id: 3
        },
        {
          name: 'Bitcoin',
          type: 'btc',
          symbol: 'BTC',
          length: 62,
          formatName: 'Taproot',
          begins: 'bc1p',
          prefix: 4,
          format: 'bech32m',
          path: "86'/0'/0'/0/0",
          id: 4
        },
        {
          name: 'Apta4Good',
          type: 'a4g',
          symbol: 'A4G',
          length: 101,
          formatName: 'Encoded',
          begins: 'ID4<',
          prefix: 4,
          format: '',
          path: "44'/161803'/0'/0/0",
          id: 5
        }
      ],
      storeApp: [
        {
          name: 'Ethereum',
          description: '',
          type: 'eth',
          symbol: 'ETH',
          length: 42,
          formatName: 'Hex',
          begins: '0x',
          prefix: 2,
          format: '',
          path: "44'/60'/0'/0/0",
          id: 0
        },
        {
          name: 'Bitcoin',
          type: 'btc',
          symbol: 'BTC',
          length: 34,
          formatName: 'Legacy',
          begins: '1',
          prefix: 1,
          format: 'legacy',
          path: "44'/0'/0'/0/0",
          id: 1
        },
        {
          name: 'Bitcoin',
          type: 'btc',
          symbol: 'BTC',
          length: 34,
          formatName: 'Legacy Segwit',
          begins: '3',
          prefix: 1,
          format: 'p2sh',
          path: "49'/0'/0'/0/0",
          id: 2
        },
        {
          name: 'Bitcoin',
          type: 'btc',
          symbol: 'BTC',
          length: 42,
          formatName: 'Native Segwit',
          begins: 'bc1q',
          prefix: 4,
          format: 'bech32',
          path: "84'/0'/0'/0/0",
          id: 3
        },
        {
          name: 'Bitcoin',
          type: 'btc',
          symbol: 'BTC',
          length: 62,
          formatName: 'Taproot',
          begins: 'bc1p',
          prefix: 4,
          format: 'bech32m',
          path: "86'/0'/0'/0/0",
          id: 4
        },
        {
          name: 'Apta4Good',
          type: 'a4g',
          symbol: 'A4G',
          length: 101,
          formatName: 'Encoded',
          begins: 'ID4<',
          prefix: 4,
          format: '',
          path: "44'/161803'/0'/0/0",
          id: 5
        }
      ],
      ready: false
    }
  },
  created () {
    if (!this.connected && !this.$store.state.box) {
      return this.$router.push({ name: 'Welcome' })
    }
  },
  async beforeMount () {
    await this.getCurrencyPrices()
    await this.loadCurrency()
    this.ready = true
  },
  watch: {
    connected: {
      deep: true,
      handler: async function (value) {
        if (value) {
          await this.getCurrencyPrices()
          this.loadCurrency()
        }
      }
    },
    balance: {
      deep: true,
      handler: async function (value) {
        await this.getCurrencyPrices()
        this.loadCurrency()
        this.$forceUpdate()
      }
    },
    'balanceToken.USD': {
      deep: true,
      handler: async function (value) {
        await this.updateBalance()
      }
    },
    'balanceToken.CAD': {
      deep: true,
      handler: async function (value) {
        await this.updateBalance()
      }
    },
    'balanceToken.BRL': {
      deep: true,
      handler: async function (value) {
        await this.updateBalance()
      }
    },
    'balanceToken.CLP': {
      deep: true,
      handler: async function (value) {
        await this.updateBalance()
      }
    },
    'balanceToken.ROCT': {
      deep: true,
      handler: async function (value) {
        await this.updateBalance()
      }
    }
  },
  computed: {
    ...mapState('trust', {
      connected: state => state.connected,
      balance: state => state.balance,
      balanceEthereum: state => state.balanceEthereum,
      balanceEthereumToken: state => state.balanceEthereumToken,
      balanceToken: state => state.balanceToken,
      wallet: state => state.wallet,
      walletEthereum: state => state.walletEthereum,
      walletEthereumToken: state => state.walletEthereumToken,
      walletToken: state => state.walletToken,
      walletAssets: state => state.walletAssets,
      fee: state => state.fee,
      address: state => state.address
    }),
    ...mapState('bitcoin', {
      balanceBitcoin: state => state.balance,
      walletBitcoin: state => state.wallet,
      addressBitcoin: state => state.address
    })
  },
  methods: {
    encodeAddress (address, key = 'trust') {
      const encode = 'ID4<' + CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(address)) + '<' + address.replace('0x', '').split('').reverse().join('')
      return encode.replaceAll('=', '>')
    },
    decodeAddress (addressEnc, key = 'trust') {
      const parts = addressEnc.split('<')
      return '0x' + parts[2].split('').reverse().join('')
    },
    async updateBalance () {
      await this.getCurrencyPrices()
      this.loadCurrency()
      this.$forceUpdate()
    },
    async loadCurrency () {
      // Apta4Good
      this.currencies.a4g = {
        address: this.encodeAddress(this.address),
        type: 'a4g',
        icon: 'a4g.svg',
        symbol: 'A4G',
        country: null,
        name: 'Apta4Good',
        balance: this.getBalance('a4g'),
        convert: this.prices.apta.usd,
        convertType: 'USD',
        minimal: 0.0005,
        hidden: false,
        unit: 0.000001,
        unitName: 'Trust',
        numFormat: '0,0.[000000]',
        fixed: 6
      }

      if (this.$store.state.rocarbon || process.env.NODE_ENV !== 'production') {
        this.currencies.roct = {
          address: this.encodeAddress(this.address),
          type: 'roct',
          icon: 'roct.png',
          symbol: 'ROCT',
          country: null,
          name: 'ROCARBON Token',
          balance: this.getBalance('roct'),
          convert: 1,
          convertType: 'Kg CO₂e',
          minimal: 1,
          hidden: true,
          unit: 1,
          unitName: 'ROCT',
          numFormat: '0,0',
          fixed: 0
        }
      }

      // Token4Good
      this.currencies.t4g = {
        address: this.encodeAddress(this.address),
        type: 't4g',
        icon: 't4g.svg',
        symbol: 'T4G-USD',
        country: 'US',
        name: 'Token4Good USD',
        balance: this.getBalance('t4g'),
        convert: 1,
        convertType: 'USD',
        minimal: 1,
        hidden: false,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 2
      }

      // Good USDT
      this.currencies.gusdt = {
        address: this.encodeAddress(this.address),
        type: 'gusdt',
        icon: 'tether.svg',
        symbol: 'gUSDT',
        country: null,
        good: true,
        name: 'Good USDT',
        balance: this.getBalance('gusdt'),
        convert: 1,
        convertType: 'USD',
        minimal: 1,
        hidden: false,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 2
      }

      // Good USDC
      this.currencies.gusdc = {
        address: this.encodeAddress(this.address),
        type: 'gusdc',
        icon: 'usdc.svg',
        symbol: 'gUSDC',
        country: null,
        good: true,
        name: 'Good USDC',
        balance: this.getBalance('gusdc'),
        convert: 1,
        convertType: 'USD',
        minimal: 1,
        hidden: false,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 2
      }

      // Good gBUSD
      this.currencies.gbusd = {
        address: this.encodeAddress(this.address),
        type: 'gbusd',
        icon: 'busd.png',
        symbol: 'gBUSD',
        country: null,
        good: true,
        name: 'Good BUSD',
        balance: this.getBalance('gbusd'),
        convert: 1,
        convertType: 'USD',
        minimal: 1,
        hidden: false,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 2
      }

      // Bitcoin
      this.currencies.btc = {
        address: this.addressBitcoin,
        type: 'btc',
        icon: 'btc.svg',
        symbol: 'BTC',
        country: null,
        name: 'Bitcoin',
        balance: this.getBalance('btc'),
        convert: this.prices.bitcoin.usd,
        convertType: 'USD',
        minimal: 0.000018,
        hidden: true,
        unit: 0.000001,
        unitName: 'Satoshi',
        numFormat: '0,0.[000000]',
        fixed: 6
      }

      // Ethereum
      this.currencies.eth = {
        address: this.address,
        type: 'eth',
        icon: 'eth.svg',
        symbol: 'ETH',
        country: null,
        name: 'Ethereum',
        balance: this.getBalance('eth'),
        convert: this.prices.ethereum.usd,
        convertType: 'USD',
        minimal: 0.0002,
        hidden: false,
        unit: 0.000001,
        unitName: 'Wei',
        numFormat: '0,0.[000000]',
        fixed: 6
      }

      // USDT
      this.currencies.usdt = {
        address: this.address,
        type: 'usdt',
        icon: 'tether.svg',
        symbol: 'USDT',
        country: null,
        name: 'USD Tether',
        balance: this.getBalance('usdt'),
        convert: 1,
        convertType: 'USD',
        minimal: 1,
        hidden: false,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 6,
        contract: '0xdAC17F958D2ee523a2206206994597C13D831ec7'
      }

      // USDC
      this.currencies.usdc = {
        address: this.address,
        type: 'usdc',
        icon: 'usdc.svg',
        symbol: 'USDC',
        country: null,
        name: 'USD Coin',
        balance: this.getBalance('usdc'),
        convert: 1,
        convertType: 'USD',
        minimal: 1,
        hidden: false,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 6,
        contract: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
      }

      // BUSD
      this.currencies.busd = {
        address: this.address,
        type: 'busd',
        icon: 'busd.png',
        symbol: 'BUSD',
        country: null,
        name: 'Binance USD',
        balance: this.getBalance('busd'),
        convert: 1,
        convertType: 'USD',
        minimal: 1,
        hidden: false,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 6,
        contract: '0x4Fabb145d64652a948d72533023f6E7A623C7C53'
      }

      // Token4Good CAD
      this.currencies.t4gcad = {
        address: this.encodeAddress(this.address),
        type: 't4gcad',
        icon: 't4gcad.svg',
        symbol: 'T4G-CAD',
        country: 'CA',
        name: 'Token4Good CAD',
        balance: this.getBalance('t4gcad'),
        convert: this.prices.cad.usd,
        convertType: 'CAD',
        minimal: 1,
        hidden: false,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 2
      }

      // Token4Good BRL
      this.currencies.t4gbrl = {
        address: this.encodeAddress(this.address),
        type: 't4gbrl',
        icon: 't4gbrl.svg',
        symbol: 'T4G-BRL',
        country: 'BR',
        name: 'Token4Good BRL',
        balance: this.getBalance('t4gbrl'),
        convert: this.prices.brl.usd,
        convertType: 'BRL',
        minimal: 1,
        hidden: true,
        unit: 0.01,
        unitName: 'Cent',
        numFormat: '0,0.[00]',
        fixed: 2
      }

      // Token4Good CLP
      this.currencies.t4gclp = {
        address: this.encodeAddress(this.address),
        type: 't4gclp',
        icon: 't4gclp.svg',
        symbol: 'T4G-CLP',
        country: 'CL',
        name: 'Token4Good CLP',
        balance: this.getBalance('t4gclp'),
        convert: this.prices.clp.usd,
        convertType: 'CLP',
        minimal: 1000,
        hidden: true,
        unit: 1,
        unitName: 'Peso',
        numFormat: '0,0',
        fixed: 0
      }
    },
    getSymbol (token_) {
      try {
        switch (token_) {
          case 'APTA':
          case 'A4G':
          case 'a4g':
            return this.currencies.a4g.symbol
          case 'T4G':
          case 'T4G-USD':
          case 't4g':
            return this.currencies.t4g.symbol
          case 'T4G-CAD':
          case 't4gcad':
            return this.currencies.t4gcad.symbol
          case 'T4G-CLP':
          case 't4gclp':
            return this.currencies.t4gclp.symbol
          case 'T4G-BRL':
          case 't4gbrl':
            return this.currencies.t4gbrl.symbol
          case 'ETH':
          case 'eth':
            return this.currencies.eth.symbol
          case 'gUSDT':
          case 'gusdt':
            return this.currencies.gusdt.symbol
          case 'gUSDC':
          case 'gusdc':
            return this.currencies.gusdc.symbol
          case 'gBUSD':
          case 'gbusd':
            return this.currencies.gbusd.symbol
          case 'USDT':
          case 'usdt':
            return this.currencies.usdt.symbol
          case 'USDC':
          case 'usdc':
            return this.currencies.usdc.symbol
          case 'BUSD':
          case 'busd':
            return this.currencies.busd.symbol
          case 'BTC':
          case 'btc':
            return this.currencies.btc.symbol
          case 'ROCT':
          case 'roct':
            return this.currencies.roct.symbol
        }
      } catch (e) {
        return ''
      }
    },
    getType (token_) {
      try {
        switch (token_) {
          case 'APTA':
          case 'A4G':
          case 'a4g':
            return this.currencies.a4g.type
          case 'T4G':
          case 'T4G-USD':
            return this.currencies.t4g.type
          case 'T4G-CAD':
          case 't4gcad':
            return this.currencies.t4gcad.type
          case 'T4G-CLP':
          case 't4gclp':
            return this.currencies.t4gclp.type
          case 'T4G-BRL':
          case 't4gbrl':
            return this.currencies.t4gbrl.type
          case 'ETH':
          case 'eth':
            return this.currencies.eth.type
          case 'USDT':
          case 'usdt':
            return this.currencies.usdt.type
          case 'USDC':
          case 'usdc':
            return this.currencies.usdc.type
          case 'BUSD':
          case 'busd':
            return this.currencies.busd.type
          case 'gUSDT':
          case 'gusdt':
            return this.currencies.gusdt.type
          case 'gUSDC':
          case 'gusdc':
            return this.currencies.gusdc.type
          case 'gBUSD':
          case 'gbusd':
            return this.currencies.busd.type
          case 'BTC':
          case 'btc':
            return this.currencies.btc.type
          case 'ROCT':
          case 'roct':
            return this.currencies.roct.type
        }
      } catch (e) {
        return ''
      }
    },
    getUnit (token_) {
      try {
        switch (token_) {
          case 'APTA':
          case 'A4G':
          case 'a4g':
            return this.currencies.a4g.unit
          case 'T4G':
          case 'T4G-USD':
          case 't4g':
            return this.currencies.t4g.unit
          case 'T4G-CAD':
          case 't4gcad':
            return this.currencies.t4gcad.unit
          case 'T4G-CLP':
          case 't4gclp':
            return this.currencies.t4gclp.unit
          case 'T4G-BRL':
          case 't4gbrl':
            return this.currencies.t4gbrl.unit
          case 'ETH':
          case 'eth':
            return this.currencies.eth.unit
          case 'USDT':
          case 'usdt':
            return this.currencies.usdt.unit
          case 'USDC':
          case 'usdc':
            return this.currencies.usdc.unit
          case 'BUSD':
          case 'busd':
            return this.currencies.busd.unit
          case 'gUSDT':
          case 'gusdt':
            return this.currencies.gusdt.unit
          case 'gUSDC':
          case 'gusdc':
            return this.currencies.gusdc.unit
          case 'gBUSD':
          case 'gbusd':
            return this.currencies.gbusd.unit
          case 'BTC':
          case 'btc':
            return this.currencies.btc.unit
          case 'ROCT':
          case 'roct':
            return this.currencies.roct.unit
        }
      } catch (e) {
        return ''
      }
    },
    getBalance (token_) {
      try {
        switch (token_) {
          case 'APTA':
          case 'A4G':
          case 'a4g':
            return this.balance
          case 'T4G':
          case 'T4G-USD':
          case 't4g':
            return this.balanceToken.USD
          case 'T4G-CAD':
          case 't4gcad':
            return this.balanceToken.CAD
          case 'T4G-CLP':
          case 't4gclp':
            return this.balanceToken.CLP
          case 'T4G-BRL':
          case 't4gbrl':
            return this.balanceToken.BRL
          case 'gUSDT':
          case 'gusdt':
            return this.balanceToken.USDT
          case 'gUSDC':
          case 'gusdc':
            return this.balanceToken.USDC
          case 'gBUSD':
          case 'gbusd':
            return this.balanceToken.BUSD
          case 'ETH':
          case 'eth':
            return this.balanceEthereum
          case 'USDT':
          case 'usdt':
            return this.balanceEthereumToken.USDT
          case 'USDC':
          case 'usdc':
            return this.balanceEthereumToken.USDC
          case 'BUSD':
          case 'busd':
            return this.balanceEthereumToken.BUSD
          case 'BTC':
          case 'btc':
            return this.balanceBitcoin
          case 'ROCT':
          case 'roct':
            return this.balanceToken.ROCT
        }
      } catch (e) {
        return ''
      }
    },
    async getBalanceByContract (address, contract, decimals) {
      try {
        const token = 'bc5550a8abf04d62ba373d98dea10539'
        const result = await this.$axios.post('https://api.blockcypher.com/v1/eth/main/contracts/' + contract + '/balanceOf?token=' + token, {
          private: CryptoJS.SHA256(address).toString(),
          params: [address]
        })
        return utils.formatUnits(result.data.results[0][0], decimals)
      } catch (e) {
        return 0
      }
    },
    getConvert (token_) {
      try {
        switch (token_) {
          case 'APTA':
          case 'A4G':
          case 'a4g':
            return this.currencies.a4g.convert
          case 'T4G':
          case 'T4G-USD':
          case 't4g':
            return this.currencies.t4g.convert
          case 'T4G-CAD':
          case 't4gcad':
            return this.currencies.t4gcad.convert
          case 'T4G-CLP':
          case 't4gclp':
            return this.currencies.t4gclp.convert
          case 'T4G-BRL':
          case 't4gbrl':
            return this.currencies.t4gbrl.convert
          case 'ETH':
          case 'eth':
            return this.currencies.eth.convert
          case 'USDT':
          case 'usdt':
            return this.currencies.usdt.convert
          case 'gUSDT':
          case 'gusdt':
            return this.currencies.gusdt.convert
          case 'USDC':
          case 'usdc':
            return this.currencies.usdc.convert
          case 'gUSDC':
          case 'gusdc':
            return this.currencies.gusdc.convert
          case 'BUSD':
          case 'busd':
            return this.currencies.busd.convert
          case 'gBUSD':
          case 'gbusd':
            return this.currencies.gbusd.convert
          case 'BTC':
          case 'btc':
            return this.currencies.btc.convert
          case 'ROCT':
          case 'roct':
            return this.currencies.roct.convert
        }
      } catch (e) {
        return ''
      }
    },
    async getBalanceAddress (address_, token_) {
      try {
        const abi = [
          // Read-Only Functions
          'function balanceOf(address owner) view returns (uint256)',
          'function decimals() view returns (uint8)',
          'function symbol() view returns (string)',

          // Authenticated Functions
          'function transfer(address to, uint amount) returns (boolean)',

          // Events
          'event Transfer(address indexed from, address indexed to, uint amount)'
        ]
        const assetWallet = new Wallet(this.walletAssets.privateKey, this.wallet.provider)
        let contract
        let assetWalletToken
        let result
        switch (token_) {
          case 'APTA':
          case 'A4G':
          case 'a4g':
            return await this.wallet.getBalance(this.alwaysDecoded(address_))
          case 'ETH':
          case 'eth':
            return await this.walletEthereum.getBalance(this.alwaysDecoded(address_))
          case 'USDT':
          case 'usdt':
            return await utils.formatUnits(await this.walletEthereumToken.USDT.balanceOf(this.alwaysDecoded(address_)), 6)
          case 'USDC':
          case 'usdc':
            return await utils.formatUnits(await this.walletEthereumToken.USDC.balanceOf(this.alwaysDecoded(address_)), 6)
          case 'BUSD':
          case 'busd':
            return await utils.formatUnits(await this.walletEthereumToken.BUSD.balanceOf(this.alwaysDecoded(address_)))
          case 'gUSDT':
          case 'gusdt':
            return await utils.formatUnits(await this.walletToken.USDT.balanceOf(this.alwaysDecoded(address_)))
          case 'gUSDC':
          case 'gusdc':
            return await utils.formatUnits(await this.walletToken.USDC.balanceOf(this.alwaysDecoded(address_)))
          case 'gBUSD':
          case 'gbusd':
            return await utils.formatUnits(await this.walletToken.BUSD.balanceOf(this.alwaysDecoded(address_)))
          case 'BTC':
          case 'btc':
            result = await this.$axios.get('https://api.blockcypher.com/v1/btc/main/addrs/' + this.alwaysDecoded(address_) + '/balance', {})
            return result.data.final_balance / 10 ** 8
          case 'T4G':
          case 'T4G-USD':
          case 't4g':
            contract = process.env.VUE_APP_CONTRACT
            assetWalletToken = new Contract(contract, abi, assetWallet)
            return utils.formatUnits(await assetWalletToken.balanceOf(this.alwaysDecoded(address_)))
          case 'T4G-CAD':
          case 't4gcad':
            contract = process.env.VUE_APP_CONTRACT_CAD
            assetWalletToken = new Contract(contract, abi, assetWallet)
            return utils.formatUnits(await assetWalletToken.balanceOf(this.alwaysDecoded(address_)))
          case 'T4G-CLP':
          case 't4gclp':
            contract = process.env.VUE_APP_CONTRACT_CLP
            assetWalletToken = new Contract(contract, abi, assetWallet)
            return utils.formatUnits(await assetWalletToken.balanceOf(this.alwaysDecoded(address_)))
          case 'T4G-BRL':
          case 't4gbrl':
            contract = process.env.VUE_APP_CONTRACT_BRL
            assetWalletToken = new Contract(contract, abi, assetWallet)
            return utils.formatUnits(await assetWalletToken.balanceOf(this.alwaysDecoded(address_)))
          case 'ROCT':
          case 'roct':
            contract = process.env.VUE_APP_CONTRACT_ROCT
            assetWalletToken = new Contract(contract, abi, assetWallet)
            return utils.formatUnits(await assetWalletToken.balanceOf(this.alwaysDecoded(address_)))
        }
      } catch (e) {
        return e
      }
    },
    getBalanceFixed (type) {
      try {
        return Math.trunc(parseFloat(this.getBalance(type)) * Math.pow(10, this.currencies[type].fixed)) / Math.pow(10, this.currencies[type].fixed)
      } catch (e) {
        return ''
      }
    },
    async getCurrentFee (token_) {
      let fee
      let gasPrice
      let gasAmount
      let tx = null
      switch (token_) {
        case 'APTA':
        case 'A4G':
        case 'a4g':
          try {
            gasPrice = await this.wallet.provider.getGasPrice()
            // console.log('gasPrice : ' + gasPrice)
            tx = {
              from: this.alwaysDecoded(this.currencies[this.origin].address),
              to: this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.alwaysDecoded(this.currencies[this.origin].address),
              value: utils.parseEther(this.amount)
            }
            /*
            console.log('from : ' + tx.from)
            console.log('to : ' + tx.to)
            console.log('value : ' + tx.value)
            console.log('gasAmount : ' + gasAmount)
            console.log('fee raw : ' + fee)
            console.log('fee parseUnits : ' + fee)
            console.log('fee formatEther : ' + fee)
             */
            gasAmount = await this.wallet.estimateGas(tx)
            fee = gasPrice * gasAmount * 1.1
            fee = utils.parseUnits(fee.toString(), 0)
            fee = utils.formatEther(fee)
            return fee
          } catch (e) {
            // console.log(token_ + ' : ' + e)
            fee = 0.015
            return fee
          }
        case 'ETH':
        case 'eth':
          try {
            gasPrice = await this.walletEthereum.provider.getGasPrice()
            tx = {
              from: this.alwaysDecoded(this.currencies[this.origin].address),
              to: this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.alwaysDecoded(this.currencies[this.origin].address),
              value: utils.parseEther(this.amount)
            }
            gasAmount = await this.walletEthereum.estimateGas(tx)
            fee = gasPrice * gasAmount * 1.1
            fee = utils.parseUnits(fee.toString(), 0)
            return fee
          } catch (e) {
            // console.log(token_ + ' : ' + e)
            fee = 0.005
            return fee
          }
        case 'BTC':
        case 'btc':
          try {
            tx = {
              inputs: [{ addresses: [this.alwaysDecoded(this.walletBitcoin.address)] }],
              outputs: [{
                addresses: [this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.alwaysDecoded(this.destination)],
                value: this.amount * 10 ** 8
              }]
            }
            fee = 1.1 * 2 * ((new Blob([JSON.stringify(tx.inputs)]).size) * 180 + (new Blob([JSON.stringify(tx.outputs)]).size) * 34 + 10) / 10 ** 8
            return fee
          } catch (e) {
            // console.log(token_ + ' : ' + e)
            fee = 0.00025
            return fee
          }
        case 'T4G':
        case 'T4G-USD':
        case 't4g':
        case 'T4G-CAD':
        case 't4gcad':
        case 'T4G-BRL':
        case 't4gbrl':
        case 'T4G-CLP':
        case 't4gclp':
        case 'ROCT':
        case 'roct':
        case 'gusdt':
        case 'gusdc':
        case 'gbusd':
        case 'gUSDT':
        case 'gUSDC':
        case 'gBUSD':
          try {
            gasPrice = await this.wallet.provider.getGasPrice()
            gasAmount = await this.walletToken.USD.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.alwaysDecoded(this.currencies[this.origin].address), utils.parseEther(this.amount.toString()))
            fee = gasPrice * gasAmount * 1.1
            fee = utils.parseUnits(fee.toString(), 0)
            fee = utils.formatEther(fee)
            return fee
          } catch (e) {
            // console.log(token_ + ' : ' + e)
            fee = 0.024
            return fee
          }
        case 'usdt':
        case 'usdc':
        case 'busd':
        case 'USDT':
        case 'USDC':
        case 'BUSD':
          try {
            gasPrice = await this.walletEthereum.provider.getGasPrice()
            gasAmount = await this.walletEthereumToken.USDT.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.alwaysDecoded(this.currencies[this.origin].address), utils.parseEther(this.amount.toString()))
            fee = gasPrice * gasAmount * 1.1
            fee = utils.parseUnits(fee.toString(), 0)
            fee = utils.formatEther(fee)
            return fee
          } catch (e) {
            // console.log(token_ + ' : ' + e)
            fee = 0.024
            return fee
          }
      }
    },
    async estimateFee (token_) {
      let fee
      let gasPrice
      let gasAmount
      let tx = null
      switch (token_) {
        case 'APTA':
        case 'A4G':
        case 'a4g':
          try {
            gasPrice = await this.wallet.provider.getGasPrice()
            // console.log('gasPrice : ' + gasPrice)
            tx = {
              from: this.alwaysDecoded(this.wallet.address),
              to: this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.alwaysDecoded(this.wallet.address),
              value: utils.parseEther(this.amount.toString())
            }
            /*
            console.log('from : ' + tx.from)
            console.log('to : ' + tx.to)
            console.log('value : ' + tx.value)
            console.log('gasAmount : ' + gasAmount)
            console.log('fee raw : ' + fee)
            console.log('fee parseUnits : ' + fee)
            console.log('fee formatEther : ' + fee)
             */
            gasAmount = await this.wallet.estimateGas(tx)
            fee = gasPrice * gasAmount * 1.1
            fee = utils.parseUnits(fee.toString(), 0)
            fee = utils.formatEther(fee)
          } catch (e) {
            fee = 0.015
            console.log(token_ + ' : ' + e)
          }
          break
        case 'ETH':
        case 'eth':
          try {
            gasPrice = await this.walletEthereum.provider.getGasPrice()
            tx = {
              from: this.alwaysDecoded(this.walletEthereum.address),
              to: this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.alwaysDecoded(this.walletEthereum.address),
              value: utils.parseEther(this.amount.toString())
            }
            gasAmount = await this.walletEthereum.estimateGas(tx)
            fee = gasPrice * gasAmount * 1.1
            fee = utils.parseUnits(fee.toString(), 0)
            fee = utils.formatEther(fee)
          } catch (e) {
            fee = 0.005
            console.log(token_ + ' : ' + e)
          }
          break
        case 'BTC':
        case 'btc':
          try {
            tx = {
              inputs: [{ addresses: [this.alwaysDecoded(this.walletBitcoin.address)] }],
              outputs: [{
                addresses: [this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.alwaysDecoded(this.walletBitcoin.address)],
                value: this.amount * 10 ** 8 * 1.1
              }]
            }
            fee = 2 * ((new Blob([JSON.stringify(tx.inputs)]).size) * 180 + (new Blob([JSON.stringify(tx.outputs)]).size) * 34 + 10) / 10 ** 8
          } catch (e) {
            fee = 0.00025
            console.log(token_ + ' : ' + e)
          }
          break
        case 'T4G':
        case 'T4G-USD':
        case 't4g':
        case 'T4G-CAD':
        case 't4gcad':
        case 'T4G-BRL':
        case 't4gbrl':
        case 'T4G-CLP':
        case 't4gclp':
        case 'ROCT':
        case 'roct':
        case 'gusdt':
        case 'gusdc':
        case 'gbusd':
        case 'gUSDT':
        case 'gUSDC':
        case 'gBUSD':
          try {
            const value_ = utils.parseEther(this.amount.toString())
            const to_ = this.alwaysDecoded(this.wallet.address)
            gasPrice = await this.wallet.provider.getGasPrice()
            gasAmount = await this.walletToken.USD.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : to_, value_)
            fee = gasPrice * gasAmount * 1.1
            fee = utils.parseUnits(fee.toString(), 0)
            fee = utils.formatEther(fee)
          } catch (e) {
            fee = 0.024
            console.log(token_ + ' : ' + e)
          }
          break
        case 'usdt':
        case 'usdc':
        case 'busd':
        case 'USDT':
        case 'USDC':
        case 'BUSD':
          try {
            const value_ = utils.parseEther(this.amount.toString())
            const to_ = this.alwaysDecoded(this.wallet.address)
            gasPrice = await this.walletEthereum.provider.getGasPrice()
            gasAmount = await this.walletEthereumToken.USD.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : to_, value_)
            fee = gasPrice * gasAmount * 1.1
            fee = utils.parseUnits(fee.toString(), 0)
            fee = utils.formatEther(fee)
          } catch (e) {
            fee = 0.024
            console.log(token_ + ' : ' + e)
          }
          break
      }
      this.currentFee = fee
    },
    /* async estimateFeeOld (type) {
      let fee = 0
      let gasPrice = 0
      let tx = null
      switch (type) {
        case 'APTA':
        case 'A4G':
        case 'a4g':
          gasPrice = await this.wallet.provider.getGasPrice()
          tx = {
            from: this.decodeAddress(this.currencies[this.origin].address),
            to: this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.decodeAddress(this.destination),
            value: utils.parseEther(this.amount.toString())
          }
          fee = await this.wallet.estimateGas(tx)
          fee = fee * utils.formatUnits(gasPrice, 18)
          break
        case 'T4G':
        case 'T4G-USD':
        case 't4g':
          gasPrice = await this.wallet.provider.getGasPrice()
          fee = await this.walletToken.USD.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.decodeAddress(this.destination), utils.parseEther(this.amount.toString()))
          fee = fee * utils.formatUnits(gasPrice, 18)
          break
        case 'T4G-CAD':
        case 't4gcad':
          gasPrice = await this.wallet.provider.getGasPrice()
          fee = await this.walletToken.CAD.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.decodeAddress(this.destination), utils.parseEther(this.amount.toString()))
          fee = fee * utils.formatUnits(gasPrice, 18)
          break
        case 'T4G-BRL':
        case 't4gbrl':
          gasPrice = await this.wallet.provider.getGasPrice()
          fee = await this.walletToken.BRL.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.decodeAddress(this.destination), utils.parseEther(this.amount.toString()))
          fee = fee * utils.formatUnits(gasPrice, 18)
          break
        case 'T4G-CLP':
        case 't4gclp':
          gasPrice = await this.wallet.provider.getGasPrice()
          fee = await this.walletToken.CLP.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.decodeAddress(this.destination), utils.parseEther(this.amount.toString()))
          fee = fee * utils.formatUnits(gasPrice, 18)
          break
        case 'ETH':
        case 'eth':
          gasPrice = await this.walletEthereum.provider.getGasPrice()
          tx = {
            from: this.currencies[this.origin].address,
            to: this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.destination,
            value: utils.parseEther(this.amount.toString())
          }
          fee = await this.walletEthereum.estimateGas(tx)
          fee = fee * utils.formatUnits(gasPrice, 18)
          break
        case 'BTC':
        case 'btc':
          tx = {
            inputs: [{ addresses: [this.walletBitcoin.address] }],
            outputs: [{ addresses: [this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.destination], value: this.amount * 10 ** 8 }]
          }
          fee = 2 * ((new Blob([JSON.stringify(tx.inputs)]).size) * 180 + (new Blob([JSON.stringify(tx.outputs)]).size) * 34 + 10) / 10 ** 8
          break
        case 'ROCT':
        case 'roct':
          gasPrice = await this.wallet.provider.getGasPrice()
          fee = await this.walletToken.USD.estimateGas.transfer(this.$route.name === 'Swap' ? process.env.VUE_APP_PAYMENT_ADDRESS : this.decodeAddress(this.destination), utils.parseEther(this.amount.toString()))
          fee = fee * utils.formatUnits(gasPrice, 18)
          break
      }
      this.currentFee = fee
    }, */
    async getCurrencyPrices () {
      const result = await this.$axios.get('https://pay4good.com/api/getCurrencyPrices', {
        params: {
          crc: Date.now() / 100000
        }
      })
      this.prices = result.data
    },
    getTotal () {
      let total = 0
      for (const type in this.currencies) {
        total += this.currencies[type].balance * this.currencies[type].convert
      }
      return total
    },
    async cacheSet (key, item) {
      // console.log('save cache')
      // console.log(item)
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(item, null), this.wallet.privateKey)
      await this.$cache.put(key, encrypted.toString(), DateHelper.dateAdd(Date(), DateInterval.minute, 30))
    },
    async cacheGet (key) {
      try {
        const data = await this.$cache.get(key)
        const decrypt = CryptoJS.AES.decrypt(data, this.wallet.privateKey)
        const item = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8))
        return item
      } catch (e) {
        throw new Error('Not Found')
      }
    },
    getAddressFormatName (address_) {
      if (address_.substring(0, 2) === this.addressFormats[0].prefix) {
        return this.addressFormats[0].formatName
      }
      switch (address_.substring(0, 1)) {
        case this.addressFormats[1].prefix:
          return this.addressFormats[1].formatName
        case this.addressFormats[2].prefix:
          return this.addressFormats[2].formatName
      }
      switch (address_.substring(0, 4)) {
        case this.addressFormats[3].prefix:
          return this.addressFormats[3].formatName
        case this.addressFormats[4].prefix:
          return this.addressFormats[4].formatName
        case this.addressFormats[5].prefix:
          return this.addressFormats[5].formatName
      }
    },
    getAddressFormat (address_) {
      if (address_.substring(0, 2) === this.addressFormats[0].prefix) {
        return this.addressFormats[0].format
      }
      switch (address_.substring(0, 1)) {
        case this.addressFormats[1].prefix:
          return this.addressFormats[1].format
        case this.addressFormats[2].prefix:
          return this.addressFormats[2].format
      }
      switch (address_.substring(0, 4)) {
        case this.addressFormats[3].prefix:
          return this.addressFormats[3].format
        case this.addressFormats[4].prefix:
          return this.addressFormats[4].format
        case this.addressFormats[5].prefix:
          return this.addressFormats[5].format
      }
    },
    getAddressPath (address_) {
      if (address_.substring(0, 2) === this.addressFormats[0].prefix) {
        return this.addressFormats[0].path
      }
      switch (address_.substring(0, 1)) {
        case this.addressFormats[1].prefix:
          return this.addressFormats[1].path
        case this.addressFormats[2].prefix:
          return this.addressFormats[2].path
      }
      switch (address_.substring(0, 4)) {
        case this.addressFormats[3].prefix:
          return this.addressFormats[3].path
        case this.addressFormats[4].prefix:
          return this.addressFormats[4].path
        case this.addressFormats[5].prefix:
          return this.addressFormats[5].path
      }
    },
    isEncoded (address_) {
      // Check if address is encoded
      return address_.substring(0, 4) === 'ID4<'
    },
    alwaysDecoded (address_) {
      // return decoded address
      if (this.isEncoded(address_)) {
        return this.decodeAddress(address_)
      } else {
        return address_
      }
    },
    alwaysEncoded (address_) {
      // return encoded address
      if (this.isEncoded(address_)) {
        return address_
      } else {
        return this.encodeAddress(address_)
      }
    },
    cleanAmount (amount_, token_) {
      // return amount in the transfer format
      switch (token_) {
        case 'APTA':
        case 'A4G':
        case 'a4g':
          return utils.parseEther(amount_)
        case 'T4G':
        case 'T4G-USD':
        case 't4g':
          return utils.parseEther(amount_)
        case 'ROCT':
        case 'roct':
          return utils.parseEther(amount_)
        case 'T4G-CAD':
        case 't4gcad':
          return utils.parseEther(amount_)
        case 'T4G-CLP':
        case 't4gclp':
          return utils.parseEther(amount_)
        case 'T4G-BRL':
        case 't4gbrl':
          return utils.parseEther(amount_)
        case 'ETH':
        case 'eth':
          return utils.parseEther(amount_)
        case 'gUSDT':
        case 'gUSDC':
        case 'gBUSD':
        case 'gusdt':
        case 'gusdc':
        case 'gbusd':
          return utils.parseEther(amount_)
        case 'usdt':
        case 'usdc':
        case 'USDT':
        case 'USDC':
          return utils.parseUnits(amount_, 6)
        case 'busd':
        case 'BUSD':
          return utils.parseEther(amount_)
        case 'BTC':
        case 'btc':
          return amount_ * 10 ** 8
      }
    },
    frontAddress (address_, token_) {
      // return amount in the transfer format
      switch (token_) {
        case 'APTA':
        case 'A4G':
        case 'a4g':
          return this.alwaysEncoded(address_)
        case 'T4G':
        case 'T4G-USD':
        case 't4g':
          return this.alwaysEncoded(address_)
        case 'ROCT':
        case 'roct':
          return this.alwaysEncoded(address_)
        case 'T4G-CAD':
        case 't4gcad':
          return this.alwaysEncoded(address_)
        case 'T4G-CLP':
        case 't4gclp':
          return this.alwaysEncoded(address_)
        case 'T4G-BRL':
        case 't4gbrl':
          return this.alwaysEncoded(address_)
        case 'ETH':
        case 'eth':
          return this.alwaysDecoded(address_)
        case 'gUSDT':
        case 'gUSDC':
        case 'gBUSD':
        case 'gusdt':
        case 'gusdc':
        case 'gbusd':
          return this.alwaysDecoded(address_)
        case 'usdt':
        case 'usdc':
        case 'busd':
        case 'USDT':
        case 'USDC':
        case 'BUSD':
          return this.alwaysDecoded(address_)
        case 'BTC':
        case 'btc':
          return this.alwaysDecoded(address_)
      }
    },
    shortAddress (address_, token_) {
      if (address_ !== undefined && token_ !== undefined) {
        const front = this.frontAddress(address_, token_)
        return front.substring(0, 10) + '...' + front.substring(front.length - 10)
      } else {
        return false
      }
    },
    async connectHardwareWallet (channel_) {
      // trying to connect to your Ledger device with USB or Bluetooth protocol
      switch (channel_) {
        case 0:
          return await TransportWebUSB.create()

        case 1:
          return await TransportWebBle.create()
      }
    },
    async verifyAddress (tx_, channel_, token_, address_) {
      let transport_ = ''
      let appWallet = ''
      try {
        // trying to connect to your Ledger device with USB or Bluetooth protocol
        transport_ = await this.connectHardwareWallet(channel_)
        let address
        let bitcoinAddress
        switch (token_) {
          case 'ETH':
          case 'eth':
          case 'usdt':
          case 'usdc':
          case 'busd':
          case 'USDT':
          case 'USDC':
          case 'BUSD':
            appWallet = new AppEth(transport_)
            address = (await appWallet.getAddress(
              this.getAddressPath(address_), false
            )).address
            return address === address_

          case 'APTA':
          case 'A4G':
          case 'a4g':
          case 'T4G':
          case 'T4G-USD':
          case 't4g':
          case 'T4G-CAD':
          case 't4gcad':
          case 'T4G-CLP':
          case 't4gclp':
          case 'T4G-BRL':
          case 't4gbrl':
          case 'ROCT':
          case 'roct':
          case 'gBTC':
          case 'gbtc':
          case 'gETH':
          case 'geth':
          case 'gUSDT':
          case 'gUSDC':
          case 'gBUSD':
          case 'gusdt':
          case 'gusdc':
          case 'gbusd':
            appWallet = new AppEth(transport_)
            address = (await appWallet.getAddress(
              this.getAddressPath(address_), false
            )).address
            return address === address_

          case 'BTC':
          case 'btc':
            appWallet = new AppBtc(transport_)
            bitcoinAddress = (await appWallet.getWalletPublicKey(
              this.getAddressPath(address_),
              { verify: false, format: this.getAddressFormat(address_) }
            )).bitcoinAddress
            return bitcoinAddress === address_
        }
      } catch (error) {
        // Catch any error thrown and displays it on the screen
        return error.message
      }
    },
    async signLedger (tx_, channel_, token_, address_) {
      let transport_ = ''
      let appWallet = ''
      try {
        // trying to connect to your Ledger device with USB or Bluetooth protocol
        transport_ = await this.connectHardwareWallet(channel_)
        let address
        let bitcoinAddress
        switch (token_) {
          case 'ETH':
          case 'eth':
          case 'USDT':
          case 'USDC':
          case 'BUSD':
          case 'usdt':
          case 'usdc':
          case 'busd':
            appWallet = new AppEth(transport_)
            address = (await appWallet.getAddress(
              this.getAddressPath(address_), false
            )).address
            return address

          case 'APTA':
          case 'A4G':
          case 'a4g':
          case 'T4G':
          case 'T4G-USD':
          case 't4g':
          case 'T4G-CAD':
          case 't4gcad':
          case 'T4G-CLP':
          case 't4gclp':
          case 'T4G-BRL':
          case 't4gbrl':
          case 'ROCT':
          case 'roct':
          case 'gBTC':
          case 'gbtc':
          case 'gETH':
          case 'geth':
          case 'gUSDT':
          case 'gUSDC':
          case 'gBUSD':
          case 'gusdt':
          case 'gusdc':
          case 'gbusd':
            appWallet = new AppEth(transport_)
            address = (await appWallet.getAddress(
              this.getAddressPath(address_), false
            )).address
            return address

          case 'BTC':
          case 'btc':
            appWallet = new AppBtc(transport_)
            bitcoinAddress = (await appWallet.getWalletPublicKey(
              this.getAddressPath(address_),
              { verify: false, format: this.getAddressFormat(address_) }
            )).bitcoinAddress
            return bitcoinAddress
        }
      } catch (error) {
        // Catch any error thrown and displays it on the screen
        return error.message
      }
    },
    async send (address_, token_, amount_, type_, wallet_ = null) {
      console.log('send', address_, token_, amount_, type_, wallet_)
      let transactionResult
      let tx
      let transaction
      let unsignedTx
      let signedTx
      let voidSigner
      // preparing tx
      switch (type_) {
        case 'send':
          tx = {
            from: this.alwaysDecoded(this.currencies[token_.toLowerCase()].address),
            to: this.alwaysDecoded(address_),
            value: this.cleanAmount(amount_, token_)
          }
          break
        case 'withdraw':
          tx = {
            from: this.alwaysDecoded(address_),
            to: this.alwaysDecoded(this.currencies[token_.toLowerCase()].address),
            value: this.cleanAmount(amount_, token_)
          }
          break
      }
      switch (token_) {
        case 'APTA':
        case 'A4G':
        case 'a4g':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await voidSigner.populateTransaction(tx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'T4G':
        case 'T4G-USD':
        case 't4g':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await this.walletToken.USD.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'ROCT':
        case 'roct':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await this.walletToken.ROCT.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'T4G-CAD':
        case 't4gcad':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await this.walletToken.CAD.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'T4G-CLP':
        case 't4gclp':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await this.walletToken.CLP.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'T4G-BRL':
        case 't4gbrl':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await this.walletToken.BRL.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'gUSDT':
        case 'gusdt':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await this.walletToken.USDT.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'gUSDC':
        case 'gusdc':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await this.walletToken.USDC.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'gBUSD':
        case 'gbusd':
          voidSigner = new VoidSigner(tx.from, this.wallet.provider)
          unsignedTx = await this.walletToken.BUSD.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.wallet.provider.sendTransaction(signedTx)
          return await this.wallet.provider.waitForTransaction(transaction.hash)
        case 'ETH':
        case 'eth':
          voidSigner = new VoidSigner(tx.from, this.walletEthereum.provider)
          unsignedTx = await voidSigner.populateTransaction(tx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.walletEthereum.provider.sendTransaction(signedTx)
          return await this.walletEthereum.provider.waitForTransaction(transaction.hash)
        case 'USDT':
        case 'usdt':
          voidSigner = new VoidSigner(tx.from, this.walletEthereum.provider)
          unsignedTx = await this.walletEthereumToken.USDT.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.walletEthereum.provider.sendTransaction(signedTx)
          return await this.walletEthereum.provider.waitForTransaction(transaction.hash)
        case 'USDC':
        case 'usdc':
          voidSigner = new VoidSigner(tx.from, this.walletEthereum.provider)
          unsignedTx = await this.walletEthereumToken.USDC.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.walletEthereum.provider.sendTransaction(signedTx)
          return await this.walletEthereum.provider.waitForTransaction(transaction.hash)
        case 'BUSD':
        case 'busd':
          voidSigner = new VoidSigner(tx.from, this.walletEthereum.provider)
          unsignedTx = await this.walletEthereumToken.BUSD.populateTransaction.transfer(tx.to, tx.value)
          unsignedTx = await voidSigner.populateTransaction(unsignedTx)
          signedTx = await wallet_.signTransaction(unsignedTx)
          transaction = await this.walletEthereum.provider.sendTransaction(signedTx)
          return await this.walletEthereum.provider.waitForTransaction(transaction.hash)
        case 'BTC':
        case 'btc':
          tx = {
            inputs: [{ addresses: [tx.from] }],
            outputs: [{
              addresses: [tx.to],
              value: tx.value
            }]
          }
          this.$axios.post('https://api.blockcypher.com/v1/btc/main/txs/new', tx)
            .then((tmp) => {
              const tmptx = tmp.data
              // console.log(tmptx)
              tmptx.pubkeys = []
              tmptx.signatures = tmptx.tosign.map((tosign, n) => {
                tmptx.pubkeys.push(this.walletBitcoin.publicKey.toString('hex'))
                return script.signature.encode(
                  this.walletBitcoin.sign(Buffer.from(tosign, 'hex')), 0x01).toString('hex').slice(0, -2)
              })
              this.$axios.post('https://api.blockcypher.com/v1/btc/main/txs/send', tmptx)
                .then((done) => {
                  // console.log(done.data)
                  transaction.hash = done.data.tx.hash
                  transactionResult.hash = done.data.tx.hash
                  transactionResult.blockHash = done.data.tx.hash
                  transactionResult.confirmations = 1
                  event.$emit(EVENT_CHANNEL, MSGS.ACCOUNT_CHANGED)
                  this.loading = false
                })
                .catch((error) => {
                  console.log(error.data)
                  this.loading = false
                })
            })
          return transactionResult
      }
    }
  }
}
