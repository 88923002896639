<template>
  <div class="Assets">
    <v-container fluid class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <v-progress-circular color="white" indeterminate width="10" size="150" v-if="loading">Sync</v-progress-circular>
          <v-container v-if="finish" fluid style="width: 820px">
            <v-row>
              <v-col
                v-for="(asset, index) in assetsPreview"
                v-bind:key="index"
                class="d-flex child-flex"
                cols="3"
                align-self="center"
                align="center"
              >
                <v-img
                  :src="currentPreview === index ? asset.qr : asset.preview"
                  :lazy-src="asset.preview"
                  aspect-ratio="1"
                  :class="{ grey: true, 'lighten-2': true, 'rounded-lg': true, 'card-preview': currentPreview === index }"
                  :width="currentPreview === index ? 180 : 150"
                  :height="currentPreview === index ? 180 : 150"
                  :max-width="currentPreview === index ? 180 : 150"
                  :max-height="currentPreview === index ? 180 : 150"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="white lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_Assets.scss'
import currency from '@/mixins/currency'
import QRCodeStyling from 'qr-code-styling'
import CryptoJS from 'crypto-js'

export default {
  name: 'Assets',
  mixins: [currency],
  data () {
    return {
      assetTypes: {
        1: {
          name: 'Box4Good',
          icon: 'mdi-checkbox-intermediate'
        },
        2: {
          name: 'NFC Asset',
          icon: 'mdi-nfc-variant'
        },
        3: {
          name: 'Digital Asset',
          icon: 'mdi-cassette'
        },
        4: {
          name: 'Digital Assets',
          icon: 'mdi-view-grid'
        },
        8: {
          name: 'Wallet App',
          icon: 'mdi-apps'
        },
        10: {
          name: 'Tips Asset',
          icon: 'mdi-piggy-bank-outline'
        }
      },
      finish: false,
      main: false,
      loading: false,
      snackbar: false,
      text: 'Paste Success',
      currentPreview: 0,
      assets: [],
      assetsPreview: [],
      previewInterval: null
    }
  },
  async mounted () {
    this.loading = true
    this.assets = await this.getAssets(this.walletAssets, this.address)
    const rand = this.assets.sort(() => 0.5 - Math.random())
    this.assetsPreview = rand.slice(0, rand.length > 12 ? 12 : rand.length)
    this.loading = false
    this.finish = true
    this.previewInterval = setInterval(() => {
      this.currentPreview = (this.currentPreview + 1) % this.assetsPreview.length
    },
    5000)
  },
  beforeDestroy () {
    clearInterval(this.previewInterval)
  },
  methods: {
    async showAsset (asset) {
      this.loadingDialog = true
      this.asset = asset
      const uri = this.$store.state.box !== false && !localStorage.dev ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL) : 'https://asset4good.com/' + asset.pathUri
      const metadataRaw = await this.$axios.get(uri)
      const metadata = metadataRaw.data
      const imageUrl = this.$store.state.box !== false && !localStorage.dev ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL) : metadata.image_url
      const preview = await this.$axios.get(imageUrl)
      const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
      this.asset.preview = window.URL.createObjectURL(blob)
      this.asset.originalOwner = metadata.owner
      this.asset.creator = metadata.creator
      this.asset.royalty = metadata.royalty
      this.asset.description = metadata.description
      this.assetDialog = true
      this.loadingDialog = false
    },
    async generateQR (code) {
      const qrcode = new QRCodeStyling({
        width: 180,
        height: 180,
        margin: 0.5,
        data: code,
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'M'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: '#3D81c2'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0,
          crossOrigin: 'anonymous',
          margin: 0
        }
      })
      await qrcode._canvasDrawingPromise
      return qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    async getAssetFiles (asset) {
      const uri = this.$store.state.box !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL) : 'https://asset4good.com/' + asset.pathUri
      const metadataRaw = await this.$axios.get(uri)
      const metadata = metadataRaw.data
      const imageUrl = this.$store.state.box !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL) : metadata.image_url
      const preview = await this.$axios.get(imageUrl)
      const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
      const result = {
        preview: window.URL.createObjectURL(blob),
        files: metadata.attributes.filter(item => item.type.includes('image'))
      }
      return result
    },
    async getAssets (wallet, address) {
      const storeAssets = (await wallet.getAssetsByOwner(address))
      const assets = []
      for (const asset of storeAssets) {
        const id = (await wallet.getIndexOfByHash(address, asset.assetHash))
        const assetBalance = await wallet.balanceOf(address, id)
        if (assetBalance === 0) {
          continue
        }
        if (asset.kind.toString() < 3) {
          continue
        }
        const uri = this.$store.state.box !== false ? ('https://asset4good.com/' + asset.pathUri).replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL) : 'https://asset4good.com/' + asset.pathUri
        const metadataRaw = await this.$axios.get(uri)
        const metadata = metadataRaw.data
        const imageUrl = this.$store.state.box !== false ? metadata.image_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL) : metadata.image_url
        const preview = await this.$axios.get(imageUrl)
        const blob = new File([new Uint8Array(JSON.parse(preview.data)).buffer], 'preview.png')
        const files = await this.getAssetFiles(asset)
        const item = files.files.pop()
        const command = {
          command: 'setPresentation',
          assetHash: asset.assetHash,
          assetType: item ? item.type : 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx',
          assetUrl: item ? (this.$store.state.box !== false ? item.file_url.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL) : item.file_url) : null
        }
        const wordArray = CryptoJS.enc.Utf8.parse(JSON.stringify(command))
        const commandEnc = CryptoJS.enc.Base64.stringify(wordArray)
        assets.push({
          id: id,
          hash: asset.assetHash,
          kind: asset.kind.toString(),
          pathUri: asset.pathUri.toString(),
          assetBalance: assetBalance,
          quantity: asset.quantity.toString(),
          privateKey: asset.assetPK,
          preview: window.URL.createObjectURL(blob),
          qr: await this.generateQR('command:' + this.$store.state.box + ':' + commandEnc),
          owner: asset.owner,
          name: name ? name.data : '-'
        })
      }
      return assets
    }
  }
}
</script>
