export class Connector {
  signer
  customPath

  constructor (signer) {
    this.signer = signer
  }

  setPath (path) {
    this.customPath = path
  }

  async getAddress () {
  }

  async signTransaction (unsignedTx) {
    console.log(unsignedTx)
  }
}
