<template>
  <div class="WelcomeBox">
    <v-progress-linear v-if="assetLoading" height="4" color="black" fixed top indeterminate style="z-index: 1000; width: 100%">
    </v-progress-linear>
    <v-dialog fullscreen v-model="player" content-class="WelcomeBox">
      <v-card class="asset-box">
        <v-fade-transition>
          <div class="asset-view" align="center">
            <img :src="assetViewUrl" height="100%" alt="" style="max-width: 80vw"/>
          </div>
        </v-fade-transition>
      </v-card>
    </v-dialog>
    <v-container fluid class="fill-height">
      <v-row>
        <v-col align-self="center" align="right" class="pt-10 mt-10">
          <span v-if="false" class="overline font-weight-thin" style="font-size: 128px !important; color: #10395D;">{{ time }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center" cols="4">
          <div v-if="!owner" class="overline" align="center">Your Box4Good has no owner, assign it in your ID4Good</div>
          <v-img v-if="!loading && !readyBox" :src="qr" contain width="350px" height="350px" class="qr"/>
          <div v-if="!owner" class="caption pt-2">This process may take a few minutes to be reflected in your wallet.</div>
          <span v-if="readyBox || loading" class="overline font-weight-thin ml-16 text-no-wrap" style="font-size: 96px !important; color: #10395D;">{{ welcome.toUpperCase() }}</span>
        </v-col>
        <v-col align="center" align-self="center">
          <span v-if="!loading && !readyBox" class="overline font-weight-thin pl-16 ml-16 text-no-wrap" style="font-size: 96px !important; color: #10395D;">{{ welcome.toUpperCase() }}</span>
          <v-progress-circular color="black" indeterminate width="10" size="150" v-if="loading">Sync</v-progress-circular>
          <Assets v-if="readyBox && !loading && !player"/>
        </v-col>
      </v-row>
      <v-row class="mt-16 pt-16">
        <v-col align-self="start" align="left">
          <div class="ml-5">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="260px"/>
          </div>
        </v-col>
        <v-col align-self="end" align="right">
          <div class="mr-5">
            <v-img :src="require('@/assets/logobew.svg')" contain width="200px"/>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      :timeout="5000"
      absolute
      centered
      rounded="pill"
      top
      v-model="sync"
      width="50%"
    >
      <div align="center">
      Sync Content
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_WelcomeBox.scss'
import { generateQR, getChallenge, waitChallenge, getIdentity } from 'id4good.auth'
import moment from 'moment'
import Assets from '@/components/Assets/Assets'
import CryptoJS from 'crypto-js'
import currency from '@/mixins/currency'

export default {
  name: 'Welcome',
  components: { Assets },
  data () {
    return {
      welcome: 'WELCOME',
      qr: null,
      link: null,
      loading: false,
      owner: true,
      assetLoading: false,
      box: false,
      sync: false,
      clock: null,
      player: false,
      playerInterval: null,
      assetViewUrl: null,
      assetViewType: null,
      currentIndex: 0,
      display: { update: 0 },
      displayInterval: null,
      time: null,
      readyBox: false
    }
  },
  mixins: [currency],
  created () {
    const style = document.createElement('style')
    style.innerHTML = 'body::-webkit-scrollbar {display: none;}'
    document.head.appendChild(style)
    this.box = this.$store.state.box !== false
    this.getTime()
    this.clock = setInterval(() => this.getRandomWelcome(), 30000)
  },
  beforeDestroy () {
    clearInterval(this.clock)
    clearInterval(this.displayInterval)
    clearInterval(this.playerInterval)
  },
  async mounted () {
    console.log(this.$store.state.box)
    const result = await this.$axios.get('https://pay4good.com/asset/ownerAsset', { params: { hash: this.$store.state.box, crc: Date.now() } })
    this.owner = result.data.assign
    console.log(this.owner)
    if (this.owner) {
      console.log('with owner')
      const privateKey = localStorage.getItem('key')
      if (privateKey !== null) {
        await this.$store.dispatch('trust/connect', { privateKey: privateKey })
        this.readyBox = true
        this.loading = false
        this.displayInterval = setInterval(() => this.checkDisplay(), 1000 * 10)
        setTimeout(() => { window.location.reload() }, 3 * 60 * 60 * 1000)
      } else {
        await this.load()
      }
    } else {
      this.qr = await generateQR('asset:0:' + this.$store.state.box)
      setTimeout(() => { window.location.reload() }, 60000)
    }
  },
  methods: {
    processAssets () {
      console.log(this.display.items)
      console.log(this.currentIndex)
      const item = this.display.items[this.currentIndex]
      const content = this.$store.state.box !== false && !localStorage.dev ? item.content.replace('https://asset4good.com/asset/', process.env.VUE_APP_IPFS_GW_LOCAL) : item.content
      this.renderAsset(item.hash, content, item.type)
      this.currentIndex = (this.currentIndex + 1) % this.display.items.length
    },
    async renderAsset (hash, url, type) {
      console.log('render')
      this.assetLoading = true
      const asset = await this.walletAssets.getAssetByHash(this.address, hash)
      const data = await this.$axios.get(url)
      const parts = data.data
      const result = []
      parts.forEach((item) => {
        const decrypted = CryptoJS.AES.decrypt(item, asset.assetPK).toString(CryptoJS.enc.Utf8)
        result.push(new Uint8Array(JSON.parse(decrypted)).buffer)
      })
      const blob = new File(result, 'show', {
        type: type
      })
      this.assetViewUrl = window.URL.createObjectURL(blob)
      this.assetViewType = type
      setTimeout(() => {
        this.assetLoading = false
        this.player = true
        console.log('end render')
      }, 500)
    },
    getTime () {
      const now = moment()
      this.time = now.format('HH:mm')
    },
    getRandomWelcome () {
      const welcome = ['Welcome', 'Bienvenido', 'Bienvenue', 'Benvenuto', 'Välkommen', 'Hoş geldin', 'ようこそ', '欢迎']
      this.welcome = welcome[parseInt(Math.random() * welcome.length) - 1]
    },
    checkDisplay () {
      this.$axios.get('https://pay4good.com/asset/getDisplay', {
        params: {
          hash: this.$store.state.box,
          crc: Date.now()
        }
      }).then(result => {
        if (this.display.update !== result.data.update) {
          this.display = result.data
          this.sync = true
          clearInterval(this.playerInterval)
          this.player = false
          this.currentIndex = 0
          if (this.display.items === false) {
            return
          }
          this.display.interval = this.display.interval === 0 ? 60 : this.display.interval
          this.processAssets()
          this.playerInterval = setInterval(() => { this.processAssets() }, this.display.interval * 1000)
        }
      })
    },
    clearAllIntervals () {
      for (let i = 0; i <= 1000; i++) {
        clearInterval(i)
      }
      for (let i = 0; i <= 1000; i++) {
        clearTimeout(i)
      }
    },
    async load () {
      const challenge = await getChallenge('wallet4good.com')
      console.log(challenge)
      this.qr = await generateQR(challenge.code)
      if (this.$device.mobile) {
        if (this.$device.ios) {
          this.link = 'https://id4good.com/' + challenge.code
        }
        if (this.$device.android) {
          this.link = 'id4good://wallet4good/' + challenge.code + ';scheme=id4good;package=com.bloqs4good.id4good;end'
        }
      }
      console.log('charge expire')
      const expire = setTimeout(() => {
        this.load()
      }, 5 * 60000)
      waitChallenge(challenge.hash, 0).then(async (result) => {
        console.log('exit')
        clearTimeout(expire)
        this.loading = true

        const identity = await getIdentity(challenge.hash)
        this.$store.state.sign = true
        this.clearAllIntervals()
        let data = {}
        if (identity.data.data !== undefined) {
          try {
            data = JSON.parse(identity.data.data)
          } catch (e) {
            data = identity.data.data
          }
          const privateKey = data.privateKey
          await this.$store.dispatch('trust/connect', { privateKey: privateKey })
          localStorage.setItem('key', privateKey)
          this.readyBox = true
          this.loading = false
          this.displayInterval = setInterval(() => this.checkDisplay(), 1000 * 10)
          setTimeout(() => { window.location.reload() }, 3 * 60 * 60 * 1000)
        } else {
          console.log('You need to update your ID4Good')
        }
      }).catch((error) => {
        console.log('out wait')
        console.log(error)
        console.log(this.$store.state.sign)
        if (!this.$store.state.sign) {
          window.location.reload()
        }
      })
    }
  }
}
</script>
