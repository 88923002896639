/* eslint-disable */
export default {
  initialized: function (state, value) {
    state.initialized = value
  },
  connected: function (state, value) {
    state.connected = value
  },
  error: function (state, value) {
    state.error = value
  },
  wallet: function (state, value) {
    state.wallet = value
  },
  balance: function (state, value) {
    state.balance = value
  },
  fee: function (state, value) {
    state.fee = value
  },
  address: function (state, value) {
    state.address = value
  },
  network: function (state, value) {
    state.network = value
  }
}
