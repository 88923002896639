import { Connector } from '@/classes/Connector'
import ledgerService from '@ledgerhq/hw-app-eth/lib-es/services/ledger'
import { BigNumber, utils } from 'ethers'
import CryptoJS from 'crypto-js'

export class Ledger extends Connector {
  signer
  customPath
  path = {
    eth: "44'/60'/0'/0/0",
    btc: "84'/0'/0'/0/0"
  }

  // eslint-disable-next-line no-useless-constructor
  constructor (signer) {
    super(signer)
    this.signer = signer
  }

  async getAddress () {
    if (this.signer.constructor.name === 'Eth') {
      const account = await this.signer.getAddress(this.customPath ? this.customPath : this.path.eth, false)
      return utils.getAddress(account.address)
    }
    if (this.signer.constructor.name === 'Btc') {
      const account = await this.signer.getWalletPublicKey(this.customPath ? this.customPath : this.path.btc, { verify: false, format: 'bech32' })
      return account.bitcoinAddress
    }
  }

  setPath (path) {
    this.customPath = path
  }

  splitPath (path) {
    const result = []
    const components = path.split('/')
    components.forEach((element) => {
      let number = parseInt(element, 10)
      if (isNaN(number)) {
        return // FIXME shouldn't it throws instead?
      }
      if (element.length > 1 && element[element.length - 1] === "'") {
        number += 0x80000000
      }
      result.push(number)
    })
    return result
  }

  async signMessage (message) {
    const words = CryptoJS.enc.Hex.parse(message)
    const hex = CryptoJS.enc.Hex.stringify(words)
    const sig = await this.signer.signPersonalMessage(this.customPath ? this.customPath : this.path.eth, hex)
    return {
      v: BigNumber.from('0x' + sig.v).toNumber(),
      r: ('0x' + sig.r),
      s: ('0x' + sig.s)
    }
  }

  async signTransaction (unsignedTx) {
    if (this.signer.constructor.name === 'Eth') {
      const tx = await utils.resolveProperties(unsignedTx)
      const baseTx = {
        chainId: (tx.chainId || undefined),
        data: (tx.data || undefined),
        gasLimit: (tx.gasLimit || undefined),
        gasPrice: (tx.gasPrice || undefined),
        nonce: (tx.nonce ? BigNumber.from(tx.nonce).toNumber() : undefined),
        to: (tx.to || undefined),
        value: (tx.value || undefined)
      }
      const serializeTx = utils.serializeTransaction(baseTx).substring(2)
      const resolve = await ledgerService.resolveTransaction(serializeTx)
      const sig = await this.signer.signTransaction(this.customPath ? this.customPath : this.path.eth, serializeTx, resolve)
      return utils.serializeTransaction(baseTx, {
        v: BigNumber.from('0x' + sig.v).toNumber(),
        r: ('0x' + sig.r),
        s: ('0x' + sig.s)
      })
    }
    if (this.signer.constructor.name === 'Btc') {
    }
  }
}
