<template>
  <div class="AddAsset">
    <v-container fluid class="pb-6">
      <v-row>
        <v-col align-self="start" align="center">
          <router-link to="/main">
            <v-img :src="$store.state.customLogo ? require('@/assets/' + $store.state.customLogo) : require('@/assets/wallet4good_reverse.svg')" contain width="500px" max-width="80%"/>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <v-scale-transition>
      <v-card v-if="main && ready" color="transparent" dark class="rounded-xl shadow center-box">
        <v-card-text align="center">
          <v-container fluid>
            <v-row>
              <v-col align-self="center" align="center">
                <v-progress-circular class="mt-10" v-if="loading" size="150" color="white" width="10" indeterminate>Sync
                </v-progress-circular>
                <v-container v-if="!loading" fluid class="fill-height" style="max-width: 700px">
                  <v-row v-if="nfc">
                    <v-col align-self="center" align="center">
                      <v-progress-circular indeterminate size="100" width="10" color="white">
                        <v-icon size="48">mdi-cellphone-nfc</v-icon>
                      </v-progress-circular>
                      <div class="ma-2">Read NFC</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-btn @click="camera = !camera" outlined tile fab style="height: 100px; width: 100px"><v-icon size="48">mdi-qrcode-scan</v-icon></v-btn>
                      <div class="ma-2">Scan QR</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col align-self="center" align="center">
                      <v-btn class="mt-10" to="/assets" dark block>Back</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-scale-transition>
    <Bar/>
    <Camera v-if="camera" v-on:close="closeCamera" />
    <v-snackbar
      v-model="snackbar"
      timeout="3000"
      rounded="pill"
    >
      <div align="center">
        {{ text }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import './_AddAsset.scss'
import Bar from '@/components/Bar/Bar'
import currency from '@/mixins/currency'
import Camera from '@/components/Camera/Camera'

export default {
  name: 'AddAsset',
  components: { Camera, Bar },
  mixins: [currency],
  data () {
    return {
      main: false,
      loading: false,
      snackbar: false,
      nfc: false,
      text: 'Copy Success',
      camera: false
    }
  },
  mounted () {
    if ('NDEFReader' in window) {
      console.log('nfc')
      this.nfc = true
      this.readNFC()
    }
    setTimeout(() => {
      this.show()
    }, 300)
  },
  methods: {
    show () {
      this.main = true
    },
    closeCamera (data) {
      this.camera = false
      this.loading = true
      this.addAsset(data)
    },
    async readNFC () {
      const ndef = new window.NDEFReader()
      try {
        await ndef.scan()
        ndef.onreading = event => {
          // const decoder = new TextDecoder()
          for (const record of event.message.records) {
            console.log(event.message)
            console.log(record)
            console.log('Record type:  ' + record.recordType)
            console.log('MIME type:    ' + record.mediaType)
            // console.log('data:         ' + decoder.decode(record.data))
            this.text = 'Read NFC Success'
            this.snackbar = true
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async addAsset (data) {
      console.log(data)
      // eslint-disable-next-line no-unused-vars
      const [type, expire, hash, site] = data.split(':')
      console.log(hash)
      try {
        await this.$axios.get('https://pay4good.com/asset/assignAsset', {
          params: {
            hash: hash,
            address: await this.wallet.getAddress(),
            crc: Date.now()
          }
        })
        this.text = 'Asset added successfully'
        this.snackbar = true
        this.loading = false
      } catch (e) {
        this.text = 'Asset added failed'
        this.snackbar = true
        this.loading = false
      }
    }
  }
}
</script>
